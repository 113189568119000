import React, { useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import PageNotFound from "../pages/others/PageNotFound";
import SuperAdminLogin from "../pages/others/login/SuperAdminLogin";
import SuperDashboard from "./dashboard/SuperDashboard";

function SuperAdmin() {
    const navigate = useNavigate();

    useEffect(() => {
        const isSuperAdmin = window.localStorage.getItem("beauty_super_key");

        if (!isSuperAdmin) {
            // Redirect to login if not authenticated and not already on the login page
            navigate("login");
        }
        // No redirect happens if the user is already on a valid route
    }, [navigate]);

    return (
        <>
            <Routes>
                <Route index element={<PageNotFound />} />
                <Route path="/login" element={<SuperAdminLogin />} />
                <Route path="/*" element={<SuperDashboard />} />
                {/* Page Not Found */}
                <Route path="*" element={<PageNotFound />} />
            </Routes>
        </>
    );
}

export default SuperAdmin;
