import React, { useState, useEffect, useRef } from "react";
import ModalVideoComponent from "../../../../../../components/common/ModalVideoComponent";
import { Avatar } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import {
	addCourseInCartAction,
	addCourseInLocalCartAction,
	getCourseInCartAction,
} from "../../../../../../redux/action/cartActions/cartActions";
import { faXTwitter } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SocialShare from "../../../../../../components/common/socialShare/SocialShare";
import { faShare } from "@fortawesome/free-solid-svg-icons";

export default function PinnedCourseDetail(props) {
	const { pageItem } = props;
	const [isOpen, setIsOpen] = useState(false);
	const [screenWidth, setScreenWidth] = useState(window.innerWidth);
	const imageRef = useRef();
	const [openShareUrl, setOpenShareUrl] = useState(false);

	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();

	const loggedInUser = localStorage.getItem("loggedIn_user") ?? "{}";
	const parsedLoggedInData = JSON.parse(loggedInUser);

	const localStorageCart = localStorage.getItem("beauty_cart_items") ?? "[]";
	const localStorageCartItems = JSON.parse(localStorageCart);

	// useEffect hook to update the screen width when the window is resized
	useEffect(() => {
		const handleResize = () => {
			setScreenWidth(window.innerWidth);
		};
		window.addEventListener("resize", handleResize);
		// Cleanup the event listener when the component is unmounted
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	const { data: courseCart, isLoading: isCartLoading } = useSelector(
		(state) => state.cart.courseCart
	);

	useEffect(() => {
        function adjustImageHeight() {
            const image = imageRef.current;
            if (image) {
                const width = image.offsetWidth;
                const height = (width / 451) * 316;
                image.style.height = `${height}px`;
            }
        }
        adjustImageHeight();
        window.addEventListener("resize", adjustImageHeight); 
        return () => {
            window.removeEventListener("resize", adjustImageHeight);
        };
    }, [pageItem.id])

	const handleAddToCart = (course) => {
		let body = {
			course: [String(course.id)],
		};

		if (parsedLoggedInData.id) {
			dispatch(
				addCourseInCartAction(body, {
					onLoading: (loading) => {
						enqueueSnackbar("Loading...", { variant: "info" });
					},
					onError: (error) => {
						let errorMessage = String(error?.message);
						enqueueSnackbar(errorMessage, { variant: "error" });
					},
					onSuccess: (success) => {
						enqueueSnackbar(success.message[0], { variant: "success" });
						dispatch(getCourseInCartAction());
					},
				})
			);
		} else {
			dispatch(addCourseInLocalCartAction(course));
		}
		const updatedCartItems = [...localStorageCartItems, course];
		localStorage.setItem("beauty_cart_items", JSON.stringify(updatedCartItems));
	};

	const isCourseAddedToCart = (courseId) => {
		if (courseCart.length) {
			return courseCart.some((item) => String(item.id) === String(courseId));
		} else {
			return false;
		}
	};

	const handleCopyUrl = (type) => {
		if (type) {
			const urlToCopy = window.location.href;
			navigator.clipboard
				.writeText(urlToCopy)
				.then(() => {
					enqueueSnackbar("URL copied to clipboard.", { variant: "success" });
				})
				.catch((error) => {
					enqueueSnackbar("Failed to copy URL to clipboard.", {
						variant: "error",
					});
				});
		}
	};

	const handleShareUrl =()=>{
		setOpenShareUrl(true)
	}

	let shareBody = {
		url : window.location.href,
		open : openShareUrl,
		setOpen : setOpenShareUrl
	}

	return (
		<>
			{openShareUrl ? <SocialShare {...shareBody}/> : null}
			<div
				id="js-pin-content"
				style={
					screenWidth < 991
						? { height: "fit-content", right: "0%" }
						: { height: "100%", right: "7%", paddingTop: "80px" }
				}
				className="courses-single-info js-pin-content">
				<div
					style={{ position: "sticky", top: "100px" }}
					className="bg-white shadow-2 rounded-8 border-light py-10 px-10">
					<div className="relative">
						<Avatar
							className="w-1/1"
							src={pageItem.course_thumbnail_image}
							alt="course"
							variant="square"
							ref={imageRef}
						/>
						<div className="absolute-full-center d-flex justify-center items-center">
							<div
								onClick={() => setIsOpen(true)}
								className="d-flex justify-center items-center size-60 rounded-full bg-white js-gallery cursor"
								data-gallery="gallery1">
								<div className="icon-play text-18"></div>
							</div>
						</div>
					</div>

					<div className="courses-single-info__content scroll-bar-1 pt-30 pb-20 px-20">
						<div className="d-flex justify-between items-center mb-30">
							{!pageItem.is_course_free ? (
								<>
									<div className="text-24 lh-1 text-dark-1 fw-500">
										${pageItem.sale_price ?? 0}
									</div>
									<div className="lh-1 line-through">
										${pageItem.course_price ?? 0}
									</div>
								</>
							) : (
								<>
									<div className="text-24 lh-1 text-dark-1 fw-500">Free</div>
									<div></div>
								</>
							)}
						</div>

						{props?.type === "customer" && (
							<>
								{isCourseAddedToCart(pageItem.id) ? (
									<button
										style={{ padding: "0px 54px" }}
										className="button -md h-60 -purple-1 text-white col-12 py-54"
										disabled>
										Already Added
									</button>
								) : pageItem?.course_purchased ? (
									<button
										style={{ padding: "0px 54px" }}
										className="button -md h-60 -purple-1 text-white col-12 py-54"
										disabled>
										Already Purchased
									</button>
								) : (
									<button
										style={{ padding: "0px 54px" }}
										className="button -md h-60 -purple-1 text-white col-12 py-54"
										onClick={() => handleAddToCart(pageItem)}
										disabled={isCartLoading}>
										{isCartLoading ? "Loading..." : "Add To Cart"}
									</button>
								)}<br/>
								<button
									style={{ padding: "0px 54px" }}
									className="button -md h-60 -green-5 text-white col-12 py-54"
									onClick={()=>handleShareUrl()}
									>
										Share &nbsp; <FontAwesomeIcon icon={faShare}/>
								</button>
							</>
						)}

						<div className="mt-25">
							<div className="d-flex justify-between py-8 ">
								<div className="d-flex items-center text-dark-1">
									<div className="icon-video-file"></div>
									<div className="ml-10">Lessons</div>
								</div>
								<div>{pageItem?.lesson_count ?? 0}</div>
							</div>

							{/* <div className="d-flex justify-between py-8 border-top-light">
                <div className="d-flex items-center text-dark-1">
                  <div className="icon-puzzle"></div>
                  <div className="ml-10">Quizzes</div>
                </div>
                <div>3</div>
              </div> */}

							<div className="d-flex justify-between py-8 border-top-light">
								<div className="d-flex items-center text-dark-1">
									<div className="icon-clock-2"></div>
									<div className="ml-10">Duration</div>
								</div>
								<div>{pageItem?.course_duration ?? 0} Hours</div>
							</div>

							<div className="d-flex justify-between py-8 border-top-light">
								<div className="d-flex items-center text-dark-1">
									<div className="icon-bar-chart-2"></div>
									<div className="ml-10">Skill level</div>
								</div>
								<div>{pageItem.level}</div>
							</div>

							<div className="d-flex justify-between py-8 border-top-light">
								<div className="d-flex items-center text-dark-1">
									<div className="icon-translate"></div>
									<div className="ml-10">Language</div>
								</div>
								<div>{pageItem?.audio_language_obj?.name ?? "--"}</div>
							</div>

							{/* <div className="d-flex justify-between py-8 border-top-light">
                <div className="d-flex items-center text-dark-1">
                  <div className="icon-badge"></div>
                  <div className="ml-10">Certificate</div>
                </div>
                <div>Yes</div>
              </div> */}

							{/* <div className="d-flex justify-between py-8 border-top-light">
                <div className="d-flex items-center text-dark-1">
                  <div className="icon-infinity"></div>
                  <div className="ml-10">Full lifetime access</div>
                </div>
                <div>Yes</div>
              </div> */}
						</div>

						<div className="d-flex justify-center pt-15">
							<button
								onClick={() => handleCopyUrl("fb")}
								className="d-flex justify-center items-center size-40 rounded-full border-none bg-transparent"
								style={{ cursor: "pointer" }}>
								<i className="fa fa-facebook"></i>
							</button>

							<button
								onClick={() => handleCopyUrl("twitter")}
								className="d-flex justify-center items-center size-40 rounded-full border-none bg-transparent"
								style={{ cursor: "pointer" }}>
								{/* <i className="fa fa-twitter"></i> */}
								<FontAwesomeIcon icon={faXTwitter} />
							</button>

							<button
								onClick={() => handleCopyUrl("instagram")}
								className="d-flex justify-center items-center size-40 rounded-full border-none bg-transparent"
								style={{ cursor: "pointer" }}>
								<i className="fa fa-instagram"></i>
							</button>

							<button
								onClick={() => handleCopyUrl("linkedIn")}
								className="d-flex justify-center items-center size-40 rounded-full border-none bg-transparent"
								style={{ cursor: "pointer" }}>
								<i className="fa fa-linkedin"></i>
							</button>
						</div>
					</div>
				</div>
			</div>
			<ModalVideoComponent
				videoId={pageItem.id}
				isOpen={isOpen}
				setIsOpen={setIsOpen}
				url={pageItem?.course_thumbnail_video ?? ""}
			/>
		</>
	);
}
