import React from "react";
import {COMPANY_NAME} from "../../config/config";
import MetaComponent from "../../components/common/MetaComponent";
import Preloader from "../../components/common/Preloader";
import SuperHeader from "../headers/SuperHeader";
import SuperSidebar from "./sidebar/SuperSidebar";
import {Route, Routes} from "react-router-dom";
import PageNotFound from "../../pages/others/PageNotFound";
import MySuperDashboard from "./pages/MySuperDashboard";
import SuperCategories from "./pages/categories/SuperCategories";
import SuperSubCategories from "./pages/subCategories/SuperSubCategories";
import SuperTestimonials from "./pages/testimonials/SuperTestimonials";
import SuperInstructors from "./pages/instructors/SuperInstructors";
import SuperPopularCourses from "./pages/popularCourses/SuperPopularCourses";
import SuperStudents from "./pages/students/SuperStudents";
import SuperPayments from "./pages/payments/SuperPayments";
import SuperNewsLetter from "./pages/newsletter/SuperNewsLetter";
import SuperCourseLanguage from "./pages/audio_language/SuperCourseLanguage";
import SuperContactUs from "./pages/contactUs/SuperContactUs";

const metadata = {
    title:
        `Super Dashboard - ${COMPANY_NAME}`,
    description:
        `Elevate your e-learning content with ${COMPANY_NAME}, the most impressive LMS template for online courses, education and LMS platforms.`,
};

export default function SuperDashboard(props) {

    return (
        <div className="barba-container" data-barba="container">
            <MetaComponent meta={metadata}/>
            <main className="main-content">
                <Preloader/>
                <SuperHeader/>
                <div className="content-wrapper js-content-wrapper overflow-hidden">
                    <div
                        id="dashboardOpenClose"
                        className="dashboard -home-9 js-dashboard-home-9"
                    >
                        <div className="dashboard__sidebar scroll-bar-1">
                            <SuperSidebar/>
                        </div>
                        <Routes>
                            <Route index path="/dashboard" element={<MySuperDashboard/>}/>
                            <Route path="/categories" element={<SuperCategories/>}/>
                            <Route path="/sub-categories" element={<SuperSubCategories/>}/>
                            <Route path="/instructors" element={<SuperInstructors/>}/>
                            <Route path="/testimonials" element={<SuperTestimonials/>}/>
                            <Route path="/popular-courses" element={<SuperPopularCourses/>}/>
                            <Route path="/students" element={<SuperStudents/>}/>
                            <Route path="/payments" element={<SuperPayments/>}/>
                            <Route path="/newsletter" element={<SuperNewsLetter/>}/>
                            <Route path="/course-language" element={<SuperCourseLanguage/>}/>
                            <Route path="/contact-us-list" element={<SuperContactUs/>}/>

                            {/*Page Not Found*/}
                            <Route path="*" element={<PageNotFound/>}/>
                        </Routes>
                    </div>
                </div>
            </main>
        </div>
    );
}
