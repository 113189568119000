import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserDashboardActions } from "../../../../../../redux/action/myDashboardActions/userDashboardActions";
import { Avatar, Box, LinearProgress } from "@mui/material";
import { Link } from "react-router-dom";
import { Empty, Spin } from "antd";
import { getCourseCertificate } from "../../../../../../redux/action/myDashboardActions/coursesActions";
import { LoggedInFooter } from "../../../../../../components/layout/footers/LoggedInFooter";

export default function UserDashboard() {
	const dispatch = useDispatch();

	const { data: dashboardDetails, isLoading } = useSelector(
		(state) => state.myDashboard.userDashboard
	);

	const loggedInUser = localStorage.getItem("loggedIn_user") ?? "{}";
	const parsedLoggedInData = JSON.parse(loggedInUser);

	useEffect(() => {
		if (!localStorage.getItem("beauty_key")) {
			window.location.replace("login");
		}
	}, []);

	useEffect(() => {
		dispatch(getUserDashboardActions());
	}, [dispatch]);

	const states = [
		{
			id: 1,
			title: "Course Purchased",
			value: dashboardDetails?.total_courses
				? dashboardDetails.total_courses
				: 0,
			iconClass: "icon-coupon",
			showProfileSection: false,
		},
		{
			id: 2,
			title: "Courses Completed",
			value: dashboardDetails?.complete_courses
				? dashboardDetails.complete_courses
				: 0,
			iconClass: "icon-play-button",
			showProfileSection: false,
		},
		{
			id: 3,
			title: "In Progress Courses",
			value: dashboardDetails?.incomplete_courses?.length
				? dashboardDetails.incomplete_courses.length
				: 0,
			iconClass: "icon-graduate-cap",
			showProfileSection: false,
		},
		{
			id: 4,
			title: "",
			value: "0",
			iconClass: "",
			showProfileSection: true,
		},
	];

	const handleDownloadCertificate = (course) => {
		dispatch(getCourseCertificate(course?.id));
	};

	return (
		<div className="dashboard__main">
			<div className="dashboard__content bg-light-4">
				<div className="row pb-50 mb-10 mt-50">
					<div className="col-auto">
						<h1 className="text-24 lh-1 fw-700">My Dashboard</h1>
						<div className="mt-10">Welcome to the Dashboard Section.</div>
					</div>
				</div>

				{/* Short Description Start */}
				<div className="row y-gap-30">
					{states.map((elm, i) => (
						<div key={i} className="col-xl-3 col-md-6">
							{isLoading && (
								<Box sx={{ width: "95%", margin: "0 auto" }}>
									<LinearProgress />
								</Box>
							)}
							<>
								{!elm?.showProfileSection ? (
									<div
										className="d-flex justify-between items-center py-35 px-15 rounded-16 bg-white -dark-bg-dark-1 shadow-4"
										style={{ marginTop: "-4px" }}>
										<div>
											<div className="lh-1 fw-500">{elm.title}</div>
											<div className="text-20 lh-1 fw-700 text-dark-1 mt-20">
												{elm.value}
											</div>
										</div>
										<i className={`text-40 ${elm.iconClass} text-purple-1`}></i>
									</div>
								) : (
									<div
										className="d-flex items-center flex-column text-center py-20 px-15 rounded-16 bg-white -dark-bg-dark-1 shadow-4"
										style={{ marginTop: "-4px" }}>
										<Avatar
											src={parsedLoggedInData?.profile_image ?? ""}
											alt="image"
											sx={{ width: 56, height: 56 }}
										/>
										<div className="lh-1 fw-500 text-dark-1 mt-20">
											{parsedLoggedInData?.first_name +
												" " +
												parsedLoggedInData?.last_name}
										</div>
										<div className="text-14 lh-1 mt-5">
											{parsedLoggedInData?.email ?? ""}
										</div>
									</div>
								)}
							</>
						</div>
					))}
				</div>
				{/* Short Description Ends */}

				{/* Second Row Starts */}
				<div className="row y-gap-30 pt-10">
					<div className="col-12 col-md-6 col-lg-6 col-xl-4">
						<div className="rounded-16 bg-white -dark-bg-dark-1 shadow-4 h-100">
							<div className="d-flex justify-between items-center py-10 py-md-20 px-10 px-md-30 border-bottom-light">
								<h2 className="text-17 lh-1 fw-500">Completed Courses</h2>
								<Link
									className="text-14 text-purple-1 underline"
									to={`course-dashboard`}
									state={{ tab: "COMPLETED" }}>
									View All
								</Link>
							</div>
							<div className="py-10 py-md-30 px-10 px-md-30">
								<Spin spinning={isLoading}>
									<div className="y-gap-30">
										{dashboardDetails?.complete_course_list?.length ? (
											dashboardDetails.complete_course_list
												.slice(0, 3)
												.map((elm, i) => (
													<div
														key={i}
														className={`d-flex ${i > 0 && "border-top-light"}`}>
														<div className="shrink-0">
															<Avatar
																src={elm?.course_thumbnail_image ?? ""}
																variant="rounded"
																alt="image"
																sx={{ width: 75, height: 60 }}
															/>
														</div>
														<div className="ml-15" style={{ width: "100%" }}>
															<div className="d-flex justify-between items-center x-gap-20 y-gap-10 flex-wrap pt-10">
																<div className="d-flex items-center">
																	<h4 className="text-15 lh-11 fw-500">
																		<Link
																			className="text-purple-1 underline"
																			to={`course-dashboard/${elm?.slug_name}`}>
																			{elm.title}
																		</Link>
																	</h4>
																</div>
																<div className="d-flex items-center">
																	<Link
																		onClick={() =>
																			handleDownloadCertificate(elm)
																		}
																		className="text-14 text-purple-1">
																		Certificate &nbsp;
																		<i
																			className={`text-10 icon-document text-purple-1`}></i>
																	</Link>
																</div>
															</div>
															<div className="d-flex justify-between items-center x-gap-20 y-gap-10 flex-wrap pt-10">
																<div className="d-flex items-center">
																	<i className="icon-person-2 text-15 mr-10"></i>
																	<div className="text-13 lh-1">
																		{elm?.seller_obj?.user_first_name +
																			" " +
																			elm?.seller_obj?.user_last_name}
																	</div>
																</div>
																<div className="d-flex items-center">
																	<i className="icon-video-file text-15 mr-10"></i>
																	<div className="text-13 lh-1">
																		{elm?.lesson_count ?? 0} lesson
																	</div>
																</div>
																<div className="d-flex items-center">
																	<i className="icon-time-management text-15 mr-10"></i>
																	<div className="text-13 lh-1">
																		{elm?.course_duration ?? 0} m
																	</div>
																</div>
															</div>
														</div>
													</div>
												))
										) : (
											<Empty description={"Course not added yet."} />
										)}
									</div>
								</Spin>
							</div>
						</div>
					</div>

					<div className="col-12 col-md-6 col-lg-6 col-xl-4">
						<div className="rounded-16 bg-white -dark-bg-dark-1 shadow-4 h-100">
							<div className="d-flex justify-between items-center py-10 py-md-20 px-10 px-md-30 border-bottom-light">
								<h2 className="text-17 lh-1 fw-500">In Progress Courses</h2>
								<Link
									className="text-14 text-purple-1 underline"
									to={"course-dashboard"}
									state={{ tab: "INCOMPLETED" }}>
									View All
								</Link>
							</div>
							<div className="py-30 px-30">
								<Spin spinning={isLoading}>
									<div className="y-gap-30">
										{dashboardDetails?.incomplete_courses?.length ? (
											dashboardDetails.incomplete_courses
												.slice(0, 3)
												.map((elm, i) => (
													<div
														key={i}
														className={`d-flex ${i > 0 && "border-top-light"}`}>
														<div className="shrink-0">
															<Avatar
																src={elm?.course_thumbnail_image ?? ""}
																variant="rounded"
																alt="image"
																sx={{ width: 75, height: 60 }}
															/>
														</div>
														<div className="ml-15" style={{ width: "100%" }}>
															<h4 className="text-15 lh-16 fw-500">
																<Link
																	className="text-purple-1 underline"
																	to={`course-dashboard/${elm?.slug_name}`}>
																	{elm.title}
																</Link>
															</h4>
															<div className="d-flex items-center x-gap-20 y-gap-10 flex-wrap pt-10">
																<div className="d-flex items-center">
																	<i className="icon-person-2 text-15 mr-10"></i>
																	<div className="text-13 lh-1">
																		{elm?.seller_obj?.user_first_name +
																			" " +
																			elm?.seller_obj?.user_last_name}
																	</div>
																</div>
																<div className="d-flex items-center">
																	<i className="icon-video-file text-15 mr-10"></i>
																	<div className="text-13 lh-1">
																		{elm?.lesson_count ?? 0} lesson
																	</div>
																</div>
																<div className="d-flex items-center">
																	<i className="icon-time-management text-15 mr-10"></i>
																	<div className="text-13 lh-1">
																		{elm?.course_duration ?? 0} m
																	</div>
																</div>
															</div>
														</div>
													</div>
												))
										) : (
											<Empty description={"Course not added yet."} />
										)}
									</div>
								</Spin>
							</div>
						</div>
					</div>
				</div>
				{/* Second Row Ends */}
			</div>

			<LoggedInFooter/>
		</div>
	);
}
