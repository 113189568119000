import { ADD_SUPER_POPULAR_COURSE, GET_SUPER_POPULAR_COURSES, GET_SUPER_UN_POPULAR_COURSES, REMOVE_SUPER_POPULAR_COURSE, UPDATE_SUPER_POPULAR_COURSE } from "../../action/commonActions/types";
import { ERROR, LOADING, SUCCESS } from "../../../constants/generalConstant";

const initialState = {
	courses: {
		data: [],
		isLoading: false,
		isSuccess: false,
		isError: false,
        meta_data:{
            current: 1
        }
	},
	popularCourses: {
		data: [],
		isLoading: false,
		isSuccess: false,
		isError: false,
        meta_data:{
            current: 1
        }
	},
    unPopularCourses: {
		data: [],
		isLoading: false,
		isSuccess: false,
		isError: false,
        meta_data:{
            current: 1
        }
	},
};

const superCoursesReducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_SUPER_POPULAR_COURSES: {
			switch (action.status) {
				case LOADING: {
					return {
						...state,
						popularCourses: {
							...state.popularCourses,
							isLoading: true,
						},
					};
				}
				case SUCCESS: {
					return {
						...state,
						popularCourses: {
							...state.popularCourses,
							data: action?.payload?.data ?? [],
							isSuccess: true,
							isLoading: false,
						},
					};
				}
				case ERROR: {
					return {
						...state,
						popularCourses: {
							...state.popularCourses,
							isError: true,
							isLoading: false,
							isSuccess: false,
						},
					};
				}

				default:
					return state;
			}
		}
        case GET_SUPER_UN_POPULAR_COURSES: {
			switch (action.status) {
				case LOADING: {
					return {
						...state,
						unPopularCourses: {
							...state.unPopularCourses,
							isLoading: true,
						},
					};
				}
				case SUCCESS: {
					return {
						...state,
						unPopularCourses: {
							...state.unPopularCourses,
							data: action?.payload?.data ?? [],
							isSuccess: true,
							isLoading: false,
                            meta_data : action?.payload?.meta_data ?? {}
						},
					};
				}
				case ERROR: {
					return {
						...state,
						unPopularCourses: {
							...state.unPopularCourses,
							isError: true,
							isLoading: false,
							isSuccess: false,
						},
					};
				}

				default:
					return state;
			}
		}
        case REMOVE_SUPER_POPULAR_COURSE: {
			switch (action.status) {
				case LOADING: {
					return {
						...state,
                        popularCourses: {
							...state.popularCourses,
							isLoading: true,
						},
						unPopularCourses: {
							...state.unPopularCourses,
							isLoading: true,
						},
					};
				}
				case ERROR: {
					return {
						...state,
                        popularCourses: {
							...state.popularCourses,
							isLoading: false,
						},
						unPopularCourses: {
							...state.unPopularCourses,
							isLoading: false,
						},
					};
				}

				default:
					return state;
			}
		}
        case ADD_SUPER_POPULAR_COURSE: {
			switch (action.status) {
				case LOADING: {
					return {
						...state,
                        popularCourses: {
							...state.popularCourses,
							isLoading: true,
						},
						unPopularCourses: {
							...state.unPopularCourses,
							isLoading: true,
						},
					};
				}
				case ERROR: {
					return {
						...state,
                        popularCourses: {
							...state.popularCourses,
							isLoading: false,
						},
						unPopularCourses: {
							...state.unPopularCourses,
							isLoading: false,
						},
					};
				}

				default:
					return state;
			}
		}
		case UPDATE_SUPER_POPULAR_COURSE: {
			switch (action.status) {
				case LOADING: {
					return {
						...state,
                        popularCourses: {
							...state.popularCourses,
							isLoading: true,
						}
					};
				}
				case ERROR: {
					return {
						...state,
                        popularCourses: {
							...state.popularCourses,
							isLoading: false,
						}
					};
				}

				default:
					return state;
			}
		}
		default:
			return state;
	}
};
export default superCoursesReducer;
