import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LinearProgress } from "@mui/material";
import EditUserProfile from "./EditUserProfile";
// import EditUserSocialProfile from "./EditUserSocialProfile";
import { getCustomerDetailsAction } from "../../../../../../redux/action/customerActions/customerActions";
import EditUserPassword from "./EditUserPassword";
import { LoggedInFooter } from "../../../../../../components/layout/footers/LoggedInFooter";

const buttons = [
	"Edit Profile",
	"Password",
	// "Social Profiles",
	// "Notifications",
	// "Close Account",
];

export default function UserSettings() {
	const [activeTab, setActiveTab] = useState(1);

	const dispatch = useDispatch();

	const { isLoading } = useSelector((state) => state.customers.singleCustomer);

	useEffect(() => {
		let loggedInUser = localStorage.getItem("loggedIn_user");
		if (loggedInUser) {
			// loggedInUser = JSON.parse(loggedInUser);
			dispatch(getCustomerDetailsAction());
		} else {
			console.log("User is not logged in");
		}
	}, [dispatch]);

	return (
		<div className="dashboard__main">
			<div className="dashboard__content bg-light-4">
				<div className="row pb-50 mb-10 mt-50">
					<div className="col-auto">
						<h1 className="text-24 lh-1 fw-700">My Settings</h1>
						<div className="mt-10">Welcome to your profile settings!.</div>
					</div>
				</div>

				<div className="row y-gap-30">
					<div className="col-12">
						{isLoading && <LinearProgress />}
						<div className="rounded-16 bg-white -dark-bg-dark-1 shadow-4 h-100">
							<div className="tabs -active-purple-2 js-tabs pt-0">
								<div className="tabs__controls d-flex  x-gap-30 y-gap-20 flex-wrap items-center pt-10 px-30 border-bottom-light js-tabs-controls">
									{buttons.map((elm, i) => (
										<button
											key={i}
											onClick={() => setActiveTab(i + 1)}
											className={`tabs__button text-light-1 js-tabs-button ${
												activeTab === i + 1 ? "is-active" : ""
											} `}
											type="button">
											{elm}
										</button>
									))}
								</div>

								<div className="tabs__content py-30 px-30 js-tabs-content">
									<EditUserProfile activeTab={activeTab} />
									<EditUserPassword activeTab={activeTab} />
									{/* <EditUserSocialProfile activeTab={activeTab} /> */}
									{/* <Notification activeTab={activeTab} />
                      <CloseAccount activeTab={activeTab} /> */}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<LoggedInFooter />
		</div>
	);
}
