import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import Preloader from "../../../components/common/Preloader";
import MetaComponent from "../../../components/common/MetaComponent";
import { COMPANY_NAME } from "../../../config/config";
// import AuthImageMove from "../../../components/others/AuthImageMove";
import HeaderAuth from "../../../components/layout/headers/HeaderAuth";
import ResetPasswordForm from "../../../components/others/ResetPasswordForm";
import Footer from "../../../components/layout/footers/Footer";

const metadata = {
	title: `Reset Password - ${COMPANY_NAME}`,
	description: `Elevate your e-learning content with ${COMPANY_NAME}, the most impressive LMS template for online courses, education and LMS platforms.`,
};

export default function ResetPasswordPage() {
	const headerRef = useRef(null);
	const [headerHeight, setHeaderHeight] = useState(0);

	const updateHeaderHeight = () => {
		if (headerRef.current) {
			setHeaderHeight(headerRef.current.clientHeight);
		}
	};

	useLayoutEffect(() => {
		// Set the initial height
		updateHeaderHeight();
	}, []);

	useEffect(() => {
		// Update the height on window resize
		window.addEventListener("resize", updateHeaderHeight);
		// Cleanup the event listener on component unmount
		return () => window.removeEventListener("resize", updateHeaderHeight);
	}, []);

	return (
		<div className="main-content  ">
			<MetaComponent meta={metadata} />
			<Preloader />

			<HeaderAuth headerRef={headerRef} />
			<div className="content-wrapper">
				<section className="form-page">
					{/* <AuthImageMove/> */}
					<div
						className="centered-form"
						style={{
							width: "100%",
							marginTop: `${headerHeight}px`,
							display: "flex",
							justifyContent: "center",
							overflow: "auto",
						}}>
						<ResetPasswordForm />
					</div>
				</section>
				<Footer/>
			</div>
		</div>
	);
}
