import { Link, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { useDispatch } from "react-redux";
import { userResetPasswordAction } from './../../redux/action/userActions/userActions';

export default function ResetPasswordForm() {
	const { enqueueSnackbar } = useSnackbar();
	const dispatch = useDispatch();
	const { otp, encrytedEmail } = useParams();
	const [loading, setLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [email, setEmail] = useState("");

	// Function to check if a string is Base64 encoded
	const isBase64 = (str) => {
		try {
			return btoa(atob(str)) === str;
		} catch (err) {
			return false;
		}
	};

	useEffect(() => {
		if (isBase64(encrytedEmail)) {
			const decodedString = window.atob(encrytedEmail);
			setEmail(decodedString);
			setErrorMessage("");
		} else {
			setEmail("");
			setErrorMessage("Url is not Valid");
		}
	}, [encrytedEmail]);

	const matchPasswordAndConfirmPassword = (password, confirmPassword) => {
		return password === confirmPassword;
	};

	const handleSubmit = (event) => {
		event.preventDefault();

		// Accessing form values
		const password = event.target.elements.password.value;
		const confirmPassword = event.target.elements.confirmPassword.value;

		const passwordLengthChecked = password.trim().length < 3;
		if (passwordLengthChecked) {
			enqueueSnackbar("New Password should be greater than 3 letters!", {
				variant: "warning",
			});
			return;
		}

		const isPasswordMatched = matchPasswordAndConfirmPassword(
			password,
			confirmPassword
		);
		if (isPasswordMatched && email) {
			let body = {
                email,
                otp,
                password,
                confirm_password : confirmPassword
            };
			dispatch(userResetPasswordAction(body, {
                onLoading: (loading) => {
                    setLoading(true)
                    enqueueSnackbar('Loading...', {variant: 'info'});
                },
                onError: (error) => {
                    setLoading(false)
                    let errorMessage = String(error?.message ?? 'Something went wrong');
                    enqueueSnackbar(errorMessage, {variant: 'error'});
                },
                onSuccess: (success) => {
                    setLoading(false)
					success?.status_code === 200
							? enqueueSnackbar(success.message[0], { variant: "success" })
							: enqueueSnackbar(success.message[0], { variant: "error" });

                    event.target.reset();
					setTimeout(() => {
                        window.location.replace('/login')
                    }, 1000)
                }
            }))
		} else {
			enqueueSnackbar("Password not matched!", { variant: "warning" });
		}
	};
	return (
		<div className="form-page__content lg:py-50">
			<div className="container">
				<div className="row justify-center items-center">
					<div 
					// className="col-xl-6 col-lg-8"
					className="col-xl-8 col-lg-10"
					>
						<div className="px-50 py-50 md:px-25 md:py-25 bg-white shadow-1 rounded-16">
							<h3 className="text-30 lh-13 text-center">Reset Password</h3>
							<form
								className="contact-form respondForm__form row y-gap-20 pt-30"
								onSubmit={handleSubmit}>
								<div className="col-12">
									<label className="text-16 lh-1 fw-500 text-dark-1 mb-10">
										Password
									</label>
									<input
										required
										type="password"
										name="password"
										autoComplete="current-password"
										placeholder="Enter Password"
									/>
								</div>
								<div className="col-12">
									<label className="text-16 lh-1 fw-500 text-dark-1 mb-10">
										Confirm Password
									</label>
									<input
										required
										type="password"
										name="confirmPassword"
										autoComplete="current-password"
										placeholder="Confirm Password"
									/>
								</div>
								<div className="text-red-1">{errorMessage}</div>
								<div className="col-12">
									<button
										type="submit"
										name="submit"
										id="submit"
										className={`button -md ${
											loading ? "-blue-3" : "-green-1"
										} text-dark-1 fw-500 w-1/1`}
										disabled={loading}>
										{loading ? "Loading..." : "Submit"}
									</button>
								</div>
							</form>
							<div className="lh-12 text-dark-1 fw-500 text-center mt-20">
                                <Link to="/login" className="text-purple-1">
                                    Try Login Again
                                </Link>
                            </div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
