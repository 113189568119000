import React from "react";
import MetaComponent from "../../components/common/MetaComponent";
import Preloader from "../../components/common/Preloader";
import Header from "../../components/layout/headers/Header";
import Footer from "../../components/layout/footers/Footer";
import { COMPANY_NAME } from "../../config/config";
import progressGif from "./../../gif/progress_gif.gif";

const metadata = {
	title: `${COMPANY_NAME}`,
	description: `Elevate your e-learning content with ${COMPANY_NAME}, the most impressive LMS template for online courses, education and LMS platforms.`,
};
export default function LoadingPage(props) {
	return (
		<div className="main-content  ">
			<MetaComponent meta={metadata} />

			<Preloader />

			<Header />
			<div className="content-wrapper js-content-wrapper overflow-hidden">
				<section className="no-page layout-pt-lg layout-pb-lg bg-beige-1">
					<div className="container">
						<div className="row y-gap-50 justify-between items-center">
							<div className="col-lg-6">
								<div className="no-page__img">
									<img src={progressGif} alt="loading gif" />
								</div>
							</div>

							<div className="col-xl-5 col-lg-6">
								<div className="no-page__content">
									<h2 className="text-35 lh-12 mt-5">
										{props?.heading ?? "Please wait for a moment!"}
									</h2>
									<div className="mt-10">
										{props?.subHeading ??
											"We are currently processing your request. Thank you for your patience."}
									</div>
									<button
										onClick={() => window.location.replace("/")}
										className="button -md -purple-1 text-white mt-20">
										Go Back To Homepage
									</button>
								</div>
							</div>
						</div>
					</div>
				</section>
				<Footer />
			</div>
		</div>
	);
}
