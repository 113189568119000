import { Avatar, Rating } from "@mui/material";
import moment from "moment";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { addCourseRatingAction } from "../../../../../../redux/action/courseActions/courseRatingsActions";
import { useSnackbar } from "notistack";
import {
	getCustomerCourseDetailsAction,
	getEnrolledCourseDetailsAction,
} from "../../../../../../redux/action/courseActions/customerCoursesActions";
import { getCourseDetailsAction } from "../../../../../../redux/action/myDashboardActions/coursesActions";

export default function StudentReview(props) {
	const {
		all_star_percentage,
		reviews_obj,
		total_rating,
		total_reviews_count,
	} = props?.item;

	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();
	const loggedInUser = localStorage.getItem("loggedIn_user") ?? "{}";
	const parsedLoggedInData = JSON.parse(loggedInUser);

	const [loading, setLoading] = useState(false);
	const [ratingValue, setRatingValue] = useState(5);
	const [instructorReplies, setInstructorReplies] = useState({});

	const getRatingStars = (count) => {
		return (
			<Rating size="small" readOnly precision={0.5} defaultValue={count} />
		);
	};

	const handleSubmitReview = (event) => {
		event.preventDefault();

		if (!parsedLoggedInData.id) {
			enqueueSnackbar("Please login first to submit review!", {
				variant: "info",
			});
			return;
		}
		const formData = new FormData(event.target);
		const title = formData.get("title");
		const comment = formData.get("comment");

		let body = {
			course: props?.course?.id ?? 0,
			title: title,
			description: comment,
			rating: ratingValue,
		};
		dispatch(
			addCourseRatingAction(body, {
				onLoading: (loading) => {
					setLoading(true);
					enqueueSnackbar("Loading...", { variant: "info" });
				},
				onError: (error) => {
					setLoading(false);
					let errorMessage = String(error?.message);
					enqueueSnackbar(errorMessage, { variant: "error" });
				},
				onSuccess: (success) => {
					setLoading(false);
					enqueueSnackbar(success.message[0], { variant: "success" });
					event.target.reset();
					if (props?.course?.slug_name) {
						if (props?.isStudentEnrolled) {
							dispatch(getEnrolledCourseDetailsAction(props.course.slug_name));
						} else {
							dispatch(getCustomerCourseDetailsAction(props.course.slug_name));
						}
					}
				},
			})
		);
	};

	const handleSubmitInstructorReview = (rating) => {
		let body = {
			// description: instructorReply,
			description: instructorReplies[rating.id],
			rating_id: rating.id,
			reply: true,
		};
		dispatch(
			addCourseRatingAction(body, {
				onLoading: (loading) => {
					setLoading(true);
					enqueueSnackbar("Loading...", { variant: "info" });
				},
				onError: (error) => {
					setLoading(false);
					let errorMessage = String(error?.message);
					enqueueSnackbar(errorMessage, { variant: "error" });
				},
				onSuccess: (success) => {
					setLoading(false);
					enqueueSnackbar(success.message[0], { variant: "success" });
					setInstructorReplies({});
					if (props?.course?.slug_name) {
						dispatch(getCourseDetailsAction(props.course.slug_name));
					}
				},
			})
		);
	};

	return (
		<div id="reviews" className="pt-60 lg:pt-40">
			<div className="blogPost -comments">
				<div className="blogPost__content">
					<h2 className="text-20 fw-500">Student feedback</h2>
					<div className="row x-gap-10 y-gap-10 pt-30">
						<div className="col-md-4">
							<div className="d-flex items-center justify-center flex-column py-50 text-center bg-light-6 rounded-8">
								<div className="text-60 lh-11 text-dark-1 fw-500">
									{total_rating}
								</div>
								<div className="d-flex x-gap-5 mt-10">
									{getRatingStars(total_rating)}
								</div>
								<div className="mt-10">Course Rating</div>
							</div>
						</div>

						<div className="col-md-8">
							<div className="py-20 px-30 bg-light-6 rounded-8">
								<div className="row y-gap-15">
									<div className="col-12">
										<div className="d-flex items-center">
											<div className="progress-bar w-1/1 mr-15">
												<div className="progress-bar__bg bg-light-12"></div>
												<div
													className={`progress-bar__bar bg-purple-1 w-1/${
														all_star_percentage[1] / 100
													}`}></div>
											</div>
											<div className="d-flex x-gap-5 pr-15">
												{getRatingStars(1)}{" "}
											</div>
											<div className="text-dark-1">{all_star_percentage[1]}%</div>
										</div>
									</div>

									<div className="col-12">
										<div className="d-flex items-center">
											<div className="progress-bar w-1/1 mr-15">
												<div className="progress-bar__bg bg-light-12"></div>
												<div
													className={`progress-bar__bar bg-purple-1 w-1/${
														all_star_percentage[2] / 100
													}`}></div>
											</div>
											<div className="d-flex x-gap-5 pr-15">
												{getRatingStars(2)}{" "}
											</div>
											<div className="text-dark-1">{all_star_percentage[2]}%</div>
										</div>
									</div>

									<div className="col-12">
										<div className="d-flex items-center">
											<div className="progress-bar w-1/1 mr-15">
												<div className="progress-bar__bg bg-light-12"></div>
												<div
													className={`progress-bar__bar bg-purple-1 w-1/${
														all_star_percentage[3] / 100
													}`}></div>
											</div>
											<div className="d-flex x-gap-5 pr-15">
												{getRatingStars(3)}{" "}
											</div>
											<div className="text-dark-1">{all_star_percentage[3]}%</div>
										</div>
									</div>

									<div className="col-12">
										<div className="d-flex items-center">
											<div className="progress-bar w-1/1 mr-15">
												<div className="progress-bar__bg bg-light-12"></div>
												<div
													className={`progress-bar__bar bg-purple-1 w-1/${
														all_star_percentage[4] / 100
													}`}></div>
											</div>
											<div className="d-flex x-gap-5 pr-15">
												{getRatingStars(4)}{" "}
											</div>
											<div className="text-dark-1">{all_star_percentage[4]}%</div>
										</div>
									</div>

									<div className="col-12">
										<div className="d-flex items-center">
											<div className="progress-bar w-1/1 mr-15">
												<div className="progress-bar__bg bg-light-12"></div>
												<div
													className={`progress-bar__bar bg-purple-1 w-1/${
														all_star_percentage[5] / 100
													}`}></div>
											</div>
											<div className="d-flex x-gap-5 pr-15">
												{getRatingStars(5)}{" "}
											</div>
											<div className="text-dark-1">{all_star_percentage[5]}%</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					{props?.type === "customer" && (
						<div className="respondForm pt-60">
							<h3 className="text-20 fw-500">Write a Review</h3>

							<div className="mt-30">
								<h4 className="text-16 fw-500">What is it like to Course?</h4>
								<div className="d-flex x-gap-10 pt-10">
									<Rating
										value={ratingValue}
										onChange={(event, newValue) => {
											setRatingValue(newValue);
										}}
									/>
								</div>
							</div>

							<form
								className="contact-form respondForm__form row y-gap-30 pt-30"
								onSubmit={handleSubmitReview}>
								<div className="col-12">
									<label className="text-16 lh-1 fw-500 text-dark-1 mb-10">
										Review Title
									</label>
									<input
										required
										type="text"
										name="title"
										placeholder="Great Courses"
									/>
								</div>
								<div className="col-12">
									<label className="text-16 lh-1 fw-500 text-dark-1 mb-10">
										Review Content
									</label>
									<textarea
										required
										name="comment"
										placeholder="Message"
										rows="8"></textarea>
								</div>
								<div className="col-12">
									<button
										type="submit"
										name="submit"
										id="submit"
										disabled={loading}
										className="button -md -purple-1 text-white">
										{loading ? "Loading..." : "Submit Review"}
									</button>
								</div>
							</form>
						</div>
					)}

					<h2 className="text-20 fw-500 mt-60 lg:mt-40">
						Reviews&nbsp;
						<span className="text-13 text-light-1 fw-400">
							({total_reviews_count})
						</span>
					</h2>

					<ul className="comments__list mt-30">
						{reviews_obj.length
							? reviews_obj.map((elm, i) => (
									<li key={i} className="comments__item">
										<div className="comments__item-inner md:direction-column">
											<div className="comments__img mr-20">
												<Avatar
													variant="circular"
													sx={{ width: 50, height: 50 }}
													alt="student_profile"
													src={elm.user_profile_image}
												/>
											</div>

											<div className="comments__body md:mt-15">
												<div className="comments__header">
													<h4 className="text-17 fw-500 lh-15">
														{elm.user_first_name + " " + elm.user_last_name}{" "}
														<span className="text-13 text-light-1 fw-400">
															({moment(elm.created_at).format("MMM Do YYYY")})
														</span>
													</h4>
													<div className="d-flex">
														{getRatingStars(elm?.rating ?? 0)}
													</div>
												</div>

												<h5 className="text-15 fw-500 mt-15">{elm.title}</h5>
												<div className="comments__text mt-10">
													<p>{elm.description}</p>
												</div>
												<ul className="comments__list__instructor_reply mt-20 pl-20">
													{elm?.instructor_review?.length
														? elm.instructor_review.map((instReply, index) => (
																<li
																	key={i + "-" + index}
																	className="comments__item">
																	<div className="comments__item-inner md:direction-column">
																		<div className="comments__img mr-20">
																			<Avatar
																				variant="circular"
																				sx={{ width: 50, height: 50 }}
																				alt="student_profile"
																				src={
																					instReply?.user_profile_image ?? ""
																				}
																			/>
																		</div>

																		<div className="comments__body">
																			<div className="comments__header">
																				<h4 className="text-17 fw-500 lh-15">
																					{instReply.user_first_name +
																						" " +
																						instReply.user_last_name}{" "}
																					<span className="text-13 text-light-1 fw-400">
																						(
																						{moment(
																							instReply.created_at
																						).format("MMM Do YYYY")}
																						)
																					</span>
																				</h4>
																			</div>
																			<div className="comments__text">
																				<p>{instReply.description}</p>
																			</div>
																		</div>
																	</div>
																</li>
														  ))
														: null}
												</ul>

												{/* Response Section */}
												{props?.type === "seller" && (
													<div
														className="response-section mt-15"
														style={{
															display: "flex",
															alignItems: "center",
															paddingLeft: "20px",
														}}>
														<textarea
															id={`response-textarea-${elm.id}`}
															className="response-textarea"
															placeholder="Write your response here..."
															style={{
																flex: "1",
																marginRight: "10px",
																border: "2px solid #e5f0fd",
															}}
															// value={instructorReply}
															// onChange={(event) =>
															// 	setInstructorReply(event.target.value)
															// }
															value={instructorReplies[elm?.id] ?? ""}
															onChange={(event) =>
																setInstructorReplies({
																	...instructorReplies,
																	[elm.id]: event.target.value,
																})
															}
														/>
														<button
															className="button text-13 -sm -light-7 -dark-button-dark-2 text-purple-1"
															onClick={() => handleSubmitInstructorReview(elm)}>
															Respond
														</button>
													</div>
												)}
												{/* Response Section */}
											</div>
										</div>
									</li>
							  ))
							: "Reviews not available yet"}
					</ul>
				</div>
			</div>
		</div>
	);
}
