import React from "react";
import globalExposureImage from '../../../images/career_page/whyTeachOnHere/Global_Exposure.svg'
import hassleFreeImage from '../../../images/career_page/whyTeachOnHere/Hassle_Free_Monetization.svg'
import buildYourBrandImage from '../../../images/career_page/whyTeachOnHere/Build_Your_Brand.svg'
import unlimitedPotentialImage from '../../../images/career_page/whyTeachOnHere/Unlimited_Potential.svg'

const learningPathFive = [
    {
        id: 1,
        title: "Global Exposure",
        image: globalExposureImage,
        description: "Reach a wider audience and expand your clientele with our free uploading feature.",
    },
    {
        id: 2,
        title: "Hassle-Free Monetization",
        image: hassleFreeImage,
        description: "Focus on creating quality content while we handle the technicalities of selling.",
    },
    {
        id: 3,
        title: "Build Your Brand",
        image: buildYourBrandImage,
        description: "Establish yourself as an industry leader and build credibility through your uploaded content.",
    },
    {
        id: 4,
        title: "Unlimited Potential",
        image: unlimitedPotentialImage,
        description: "There’s no limit to the number of free classes or tutorials you can upload. The sky's the limit!",
    },
];

export default function LearningJourneyColumns() {
    return (
        <section className="layout-pt-lg layout-pb-lg">
            <div className="container">
                <div className="row justify-center text-center">
                    <div className="col-auto">
                        <div className="sectionTitle ">
                            <h2 className="sectionTitle__title lh-14">
                            Why Teach on Pro Beauty Port?
                            </h2>

                            <p className="sectionTitle__text ">
                            Benefits of Uploading Content for Free:
                            </p>
                        </div>
                    </div>
                </div>

                <div className="row y-gap-30 justify-between pt-60 lg:pt-50">
                    {learningPathFive.map((elm, i) => (
                        <div key={i} className="col-lg-3 col-md-6">
                            <div className="coursesCard -type-2 text-center pt-50 pb-40 px-30 bg-white rounded-8">
                                <div className="coursesCard__image">
                                    <img src={elm.image} alt="learning-journey"/>
                                </div>
                                <div className="coursesCard__content mt-30">
                                    <h5 className="coursesCard__title text-18 lh-1 fw-500">
                                        {elm.title}
                                    </h5>
                                    <p className="coursesCard__text text-14 mt-10">
                                        {elm.description}
                                    </p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}
