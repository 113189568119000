import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { COMPANY_NAME } from "../../../config/config";
import MetaComponent from "../../../components/common/MetaComponent";
import Preloader from "../../../components/common/Preloader";
import Header from "../../../components/layout/headers/Header";
import Footer from "../../../components/layout/footers/Footer";
import { useDispatch, useSelector } from "react-redux";
import { getTrainerDetailsAction } from "../../../redux/action/trainerActions/trainerActions";
import { Avatar, LinearProgress, Pagination, Rating } from "@mui/material";
import { Empty, Spin } from "antd";
import { getCustomerCourseListAction } from "../../../redux/action/courseActions/customerCoursesActions";
import CourseCard from "../../../components/others/courseCards/CourseCard";

const metadata = {
	title: `Educator | ${COMPANY_NAME}`,
	description: `Elevate your e-learning content with ${COMPANY_NAME}, the most impressive LMS template for online courses, education and LMS platforms.`,
};
export default function InstructorDetails() {
	let params = useParams();
	const dispatch = useDispatch();
	let trainer_slug = params.instructorId || "";

	useEffect(() => {
		dispatch(getTrainerDetailsAction(trainer_slug));
	}, [dispatch, trainer_slug]);

	const [activeTab, setActiveTab] = useState(1);

	const { data: instructorDetails, isLoading } = useSelector(
		(state) => state.trainers.singleTrainer
	);

	useEffect(() => {
		if (instructorDetails.seller_id) {
			let body = {
				page: 1,
				seller: instructorDetails.seller_id,
			};
			dispatch(getCustomerCourseListAction(body));
		}
	}, [dispatch, instructorDetails]);

	const {
		data: instructorCourses,
		isLoading: isCoursesLoading,
		meta_data,
	} = useSelector((state) => state.courses.customerCourses);

	const handlePageChange = (value) => {
		let body = {
			page: value,
			seller: instructorDetails.seller_id,
		};
		dispatch(getCustomerCourseListAction(body));
	};

	const getRatingStars = (count) => {
		return (
			<Rating size="small" readOnly precision={0.5} value={count} />
		);
	};

	return (
		<div className="main-content">
			<MetaComponent meta={metadata} />
			<Preloader />
			<Header />
            <Spin spinning={isLoading}>
			<div className="content-wrapper  js-content-wrapper overflow-hidden">
				<div style={{ marginTop: "90px", padding: "16px 0" }}>
					{isLoading && <LinearProgress />}
					<section className="page-header -type-3">
						<div className="page-header__bg bg-purple-1"></div>
						<div className="container">
							<div className="row justify-center">
								<div className="col-xl-8 col-lg-9 col-md-11">
									<div className="page-header__content">
										<div className="page-header__img">
											<Avatar
												style={{
													width: "120px",
													height: "120px",
													borderRadius: "50%",
													overflow: "hidden",
													objectFit: "cover",
												}}
												src={
													instructorDetails.image || instructorDetails.imageSrc
												}
												alt="educator"
											/>
										</div>

										<div className="page-header__info pt-20">
											<h1 className="text-30 lh-14 fw-700 text-white">
												{instructorDetails.name}
											</h1>
											<div className="text-white">{instructorDetails.role}</div>
											<div className="d-flex x-gap-20 pt-15" style={{flexWrap:'wrap'}}>
												<div className="d-flex items-center justify-center text-white">
													{getRatingStars(instructorDetails?.rating ?? 0)}
													&nbsp;({instructorDetails?.rating ?? 0})&nbsp;
													<div className="text-13 lh-1">Educator Rating</div>
												</div>

												<div className="d-flex items-center text-white">
													<div className="icon-video-file mr-10"></div>
													<div className="text-13 lh-1">
														{instructorDetails.reviews || 0} Reviews
													</div>
												</div>

												<div className="d-flex items-center text-white">
													<div className="icon-person-3 mr-10"></div>
													<div className="text-13 lh-1">
														{instructorDetails.students ||
															instructorDetails.studentCount ||
															0}{" "}
														Students
													</div>
												</div>

												<div className="d-flex items-center text-white">
													<div className="icon-play mr-10"></div>
													<div className="text-13 lh-1">
														{instructorDetails.courses ||
															instructorDetails.courseCount ||
															0}{" "}
														Course
													</div>
												</div>
											</div>
										</div>

										<div className="d-flex items-center mt-30">
											{/*<button className="button -md -green-1 text-dark-1">*/}
											{/*    Send Message*/}
											{/*</button>*/}

											<div className="d-flex items-center x-gap-15 text-white">
												{instructorDetails.socialProfile?.map((itm, index) => itm.url ?(
													<a key={index} href={itm.url} target="_blank" rel="noreferrer">
														{itm.replaceIcon? itm.replaceIcon :<i className={`fa ${itm.icon}`}></i>}
													</a>
												): null)}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>

					<div className="layout-pt-md layout-pb-lg">
						<div className="container">
							<div className="row justify-center">
								<div className="col-xl-10 col-lg-12 col-md-12">
									<div className="tabs -active-purple-2 js-tabs">
										<div className="tabs__controls d-flex js-tabs-controls">
											<button
												onClick={() => setActiveTab(1)}
												className={`tabs__button js-tabs-button ${
													activeTab === 1 ? "is-active" : ""
												}`}
												data-tab-target=".-tab-item-1"
												type="button">
												Overview
											</button>
											<button
												onClick={() => setActiveTab(2)}
												className={`tabs__button js-tabs-button ml-30 ${
													activeTab === 2 ? "is-active" : ""
												} `}
												data-tab-target=".-tab-item-2"
												type="button">
												Courses
											</button>
										</div>

										<div className="tabs__content pt-60 lg:pt-40 js-tabs-content">
											<div
												className={`tabs__pane -tab-item-1  ${
													activeTab === 1 ? "is-active" : ""
												} `}>
												<h4 className="text-20">About me</h4>
												<p className="text-light-1 mt-30">
													{instructorDetails?.description ?? (
														<Empty description="Not available yet..." />
													)}
												</p>
											</div>

											<div
												className={`tabs__pane -tab-item-1  ${
													activeTab === 2 ? "is-active" : ""
												} `}>
												<div className="row">
													<Spin spinning={isCoursesLoading}>
														<div
															className="m-auto row y-gap-30 container pl-0 pr-0"
															data-aos="fade-right"
															data-aos-offset="80"
															data-aos-duration={800}>
															{instructorCourses?.length ? (
																instructorCourses.map((elm, index) => (
																	<CourseCard
																		key={index}
																		data={elm}
																		index={index}
																		data-aos="fade-right"
																		data-aos-duration={(index + 1) * 300}
																	/>
																))
															) : (
																<Empty
																	description={
																		"Educator Courses are not available yet."
																	}
																/>
															)}
														</div>
													</Spin>
													{/* Pagination Starts here */}
													<div className="row justify-center pt-90 lg:pt-50">
														<div className="col-auto">
															<div className="pagination -buttons d-flex justify-content-center">
																<Pagination
																	count={meta_data?.total_pages ?? 0}
																	page={meta_data?.current ?? 1}
																	onChange={(event, value) =>
																		handlePageChange(value)
																	}
																	size="large"
																	sx={{
																		ul: {
																			"& .Mui-selected": {
																				background: "#6440FB",
																				color: "white",
																			},
																		},
																	}}
																	disabled={isLoading}
																/>
															</div>
														</div>
													</div>
													{/* Pagination ends here  */}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<Footer />
			</div>
            </Spin>
		</div>
	);
}
