import {
	Avatar,
	Box,
	Button,
	DialogTitle,
	Divider,
	Drawer,
	IconButton,
	LinearProgress,
	Rating,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	getCourseDetailsAction,
	getSellerCourseListAction,
	updateCourseAction,
} from "../../../../../../redux/action/myDashboardActions/coursesActions";
import { Close } from "@mui/icons-material";
import MetaComponent from "../../../../../../components/common/MetaComponent";
import { COMPANY_NAME, IMAGE_FILE_URL } from "../../../../../../config/config";
import moment from "moment";
import PinnedCourseDetail from "./PinnedCourseDetail";
import CourseOverview from "./CourseOverview";
import CourseContent from "./CourseContent";
import StudentReview from "./StudentReview";
import { useSnackbar } from "notistack";

const metadata = {
	title: `Course Detail | ${COMPANY_NAME}`,
	description: `Elevate your e-learning content with ${COMPANY_NAME}, the most impressive LMS template for online courses, education and LMS platforms.`,
};
const menuItems = [
	{ id: 1, href: "#overview", text: "Overview", isActive: true },
	{ id: 2, href: "#course-content", text: "Course Content", isActive: false },
	{ id: 4, href: "#reviews", text: "Reviews", isActive: false },
];

const CourseDetail = (props) => {
	const { open, setOpen, course } = props;
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);
	const { enqueueSnackbar } = useSnackbar();

	const { data, isLoading } = useSelector(
		(state) => state.myDashboard.sellerCourseDetails
	);

	const { meta_data } = useSelector((state) => state.myDashboard.sellerCourses);

	const getData = useCallback(
		(slug) => {
			dispatch(getCourseDetailsAction(slug));
		},
		[dispatch]
	);

	useEffect(() => {
		getData(course.slug_name);
	}, [course, getData]);

	const handleClose = () => {
		setOpen(false);
	};

	const getRatingStars = (count) => {
		return (
			<Rating size="small" readOnly precision={0.5} defaultValue={count} />
		);
	};

	const showCourseDetail = () => (
		<div className="main-content">
			<MetaComponent meta={metadata} />
			<div className="content-wrapper  js-content-wrapper">
				<section className="layout-pt layout-pb-lg">
					<div id="js-pin-container" className="js-pin-container relative">
						<section className="page-header -type-5 bg-light-6">
							<div className="page-header__bg">
								<div
									className="bg-image js-lazy"
									data-bg={`${IMAGE_FILE_URL}/img/event-single/bg.png`}></div>
							</div>

							<div className="container">
								<div className="page-header__content pt-60 pb-60">
									<div className="row y-gap-30">
										<div className="col-xl-7 col-lg-8">
											<div className="d-flex x-gap-15 y-gap-10 pb-20">
												<div>
													{data.is_best_seller_badge && (
														<div className="badge px-15 py-8 text-11 bg-green-1 text-dark-1 fw-400">
															BEST SELLER
														</div>
													)}
												</div>
												<div>
													{data.is_new_badge && (
														<div className="badge px-15 py-8 text-11 bg-orange-1 text-white fw-400">
															NEW
														</div>
													)}
												</div>
												<div>
													{data.is_popular_badge && (
														<div className="badge px-15 py-8 text-11 bg-purple-1 text-white fw-400">
															POPULAR
														</div>
													)}
												</div>
											</div>
											{/* title starts */}
											<div>
												<h1 className="text-30 lh-14 pr-60 lg:pr-0">
													{data.title}
												</h1>
											</div>
											{/* title ends */}
											{/* short description starts */}
											<p className="col-xl-9 mt-20">
												{data.short_description
													? data.short_description
													: "Short description is not available for this course."}
											</p>
											{/* short description ends */}

											{/* Additional Info starts */}
											<div className="d-flex x-gap-30 y-gap-10 items-center flex-wrap pt-20">
												<div className="d-flex items-center">
													<div className="text-14 lh-1 text-yellow-1 mr-10">
														{data.rating ?? 0}
													</div>
													<div className="d-flex x-gap-5 items-center">
														{getRatingStars(5)}
													</div>
													<div className="text-14 lh-1 text-light-1 ml-10">
														({data.ratingCount ?? 0})
													</div>
												</div>

												<div className="d-flex items-center text-light-1">
													<div className="icon icon-person-3 text-13"></div>
													<div className="text-14 ml-8">
														{data.enrolled_user_count ?? 0} enrolled on this
														course
													</div>
												</div>

												<div className="d-flex items-center text-light-1">
													<div className="icon icon-wall-clock text-13"></div>
													<div className="text-14 ml-8">
														Last updated on{" "}
														{moment(data.updated_at).format("MMM Do YYYY")}
													</div>
												</div>
											</div>
											{/* Additional info ends */}
											{/* Author Details Start  */}
											<div className="d-flex items-center pt-20">
												<Avatar
													src={data?.seller_obj?.user_profile_image ?? ""}
												/>
												<div className="text-14 lh-1 ml-10">
													{data?.seller_obj?.user_first_name +
														" " +
														data?.seller_obj?.user_last_name}
												</div>
											</div>
											{/* Author Details ends */}
										</div>
									</div>
								</div>
							</div>
						</section>
						<PinnedCourseDetail pageItem={data} />

						{/* Course Detail Area Starts  */}
						<section className="layout-pt-md layout-pb-md">
							<div className="container">
								<div className="row">
									<div className="col-lg-8">
										<div className="page-nav-menu -line">
											<div className="d-flex x-gap-30">
												{menuItems.map((item, ind) => (
													<div key={ind}>
														<a
															href={item.href}
															className={`pb-12 page-nav-menu__link ${
																item.isActive ? "is-active" : ""
															}`}>
															{item.text}
														</a>
													</div>
												))}
											</div>
										</div>
										<CourseOverview data={data} />
										<CourseContent data={data} />
										{/* Students feedback starts */}
										<StudentReview
											item={data?.ratings_obj}
											course={data}
											type={"seller"}
										/>
										{/* Students feedback ends */}
									</div>
								</div>
							</div>
						</section>
						{/* Course Detail Area Ends */}
					</div>
				</section>
			</div>
		</div>
	);

	const handleCourseLive = (status) => {
		let body = {
			course_status: status,
		};
		setLoading(true);
		dispatch(
			updateCourseAction(body, data.id, {
				onLoading: (loading) => {
					setLoading(true);
					enqueueSnackbar("Loading. Please wait...", { variant: "info" });
				},
				onError: (error) => {
					setLoading(false);
					let errorMessage = String(error?.message ?? "Something went wrong");
					enqueueSnackbar(errorMessage, { variant: "error" });
				},
				onSuccess: (success) => {
					setLoading(false);
					enqueueSnackbar("Course Updated Successfully...", {
						variant: "success",
					});
					getData(course.slug_name);
					let list_body = {
						page: meta_data?.current ?? 1,
					};
					dispatch(getSellerCourseListAction(list_body));
				},
			})
		);
	};

	return (
		<Drawer
			anchor="right"
			open={open}
			onClose={() => handleClose()}
			sx={{
				"& .MuiDrawer-paper": {
					width: "90%",
					maxWidth: "90vw",
				},
			}}
			SlideProps={{
				translate: "yes",
				timeout: 1000,
			}}>
			<Box>
				<DialogTitle
					sx={{ m: 0, p: 2, display: "flex", justifyContent: "space-between" }}
					id="customized-dialog-title">
					Course Detail
					{props?.type === "trainer" &&
					(data.course_status === "DRAFT" ||
						data.course_status === "UN_PUBLISHED") ? (
						<span className="error-message mr-35">
							Fill all the details to publish course
						</span>
					) : null}
					{data.is_available_for_published &&
					(data.course_status === "DRAFT" ||
						data.course_status === "UN_PUBLISHED") ? (
						<Button
							component="label"
							className="button h-40 px-25 -dark-1 -dark-button-white text-white mr-35"
							onClick={() => handleCourseLive("PUBLISHED")}>
							{loading ? "Loading..." : "Publish it"}
						</Button>
					) : (
						data.course_status === "PUBLISHED" && (
							<Button
								component="label"
								className="button h-40 px-25 -dark-1 -dark-button-white text-white mr-35"
								onClick={() => handleCourseLive("UN_PUBLISHED")}>
								{loading ? "Loading..." : "Un-Publish"}
							</Button>
						)
					)}
				</DialogTitle>

				<IconButton
					aria-label="close"
					onClick={() => handleClose()}
					sx={{
						position: "absolute",
						right: 8,
						top: 15,
						color: (theme) => theme.palette.grey[500],
					}}>
					<Close />
				</IconButton>
				{isLoading && <LinearProgress />}
				<Divider />
				{!isLoading && showCourseDetail()}
			</Box>
		</Drawer>
	);
};

export default CourseDetail;
