import {ERROR, LOADING, SUCCESS} from "../../../constants/generalConstant";
import Axios from "axios";
import {BASE_URL} from "../../../config/config";
import { onResponseError } from "../../../function/Interceptor";
import { GET_SUPER_STUDENTS } from "../commonActions/types";

export function getSuperStudentsData(body={},actionResponse = {}) {
    const {onSuccess, onError, onLoading} = actionResponse;
    return async (dispatch) => {
        dispatch({type: GET_SUPER_STUDENTS, status: LOADING});
        onLoading?.(true)
        try {
            let params = {
                pagination: true,
                fields: '',
                page: body?.page !== undefined ? body.page : 1,
            };

            let Config = {
                headers: {},
                params,
            }
            if (localStorage.getItem("beauty_super_key")) {
                Config.headers.Authorization = "Bearer " + localStorage.getItem("beauty_super_key");
            }
            let response = await Axios.get(`${BASE_URL}users/getStudentsList`, Config);
            const modifiedData = {
                meta_data: {
                    count: response?.data[0]?.count || 0,
                    current: response?.data[0]?.links?.current || 0,
                    hasNext: Boolean(response?.data[0]?.links?.next),
                    total_pages: response?.data[0]?.links?.total_pages || 0,
                    hasPrevious: Boolean(response?.data[0]?.links?.previous),
                },
                data: response?.data[0]?.results || [],
            };
            onSuccess?.(modifiedData)
            dispatch({
                type: GET_SUPER_STUDENTS,
                status: SUCCESS,
                payload: modifiedData,
            });
        } catch (error) {
            onError?.(error?.response?.data)
            onResponseError(error?.response?.data,"super_admin")
            dispatch({
                type: GET_SUPER_STUDENTS,
                status: ERROR,
                payload: error,
            });
        }
    };
}