import React from "react";
import { Link } from "react-router-dom";
import logo1 from "../../../images/logos/logo_full.svg";

export default function SuperHeaderAuth(props) {
	const {headerRef} = props;

	return (
		<header
			className="header -base js-header"
			ref={headerRef}
			style={{ borderBottom: "1px solid #D4D4D4", background: "white" }}>
			<div className="header__container py-10">
				<div className="row justify-between items-center">
					<div className="col-auto">
						<div className="header-left">
							<div className="header__logo ">
								<Link data-barba to="/">
									<img
										src={logo1}
										alt="logo"
									/>
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</header>
	);
}
