import { ERROR, LOADING, SUCCESS } from "../../../constants/generalConstant";
import { GET_COURSE_IN_CART, ADD_COURSE_IN_LOCAL_CART, DELETE_COURSE_IN_LOCAL_CART, GET_COURSE_FROM_LOCAL_CART } from "../../action/commonActions/types";

const initialState = {
	data: [],
	isLoading: false,
	isSuccess: false,
	isError: false,
};

const courseCart = (state = initialState, action) => {
	switch (action.type) {
		case GET_COURSE_IN_CART: {
			switch (action.status) {
				case LOADING: {
					return {
						...state,
						isLoading: true,
						isSuccess: false,
					};
				}
				case SUCCESS: {
					return {
						...state,
						data: action?.payload ?? [],
						isSuccess: true,
						isLoading: false,
					};
				}
				case ERROR: {
					return {
						...state,
						isError: true,
						isLoading: false,
						isSuccess: false,
					};
				}

				default:
					return state;
			}
		}
		case ADD_COURSE_IN_LOCAL_CART: {
			return {
				...state,
				data: action?.payload ? [
					...state.data,
					action.payload
				] : [],
				isSuccess: true,
				isLoading: false,
			};
		}
		case DELETE_COURSE_IN_LOCAL_CART: {
			return {
				...state,
				data: state.data.filter(item => String(item.id) !== String(action.payload)),
				isSuccess: true,
				isLoading: false,
			};
		}
		case GET_COURSE_FROM_LOCAL_CART: {
			return {
				...state,
				data: action?.payload?.length ? action.payload:[],
				isSuccess: true,
				isLoading: false,
			};
		}
		default:
			return state;
	}
};
export default courseCart;
