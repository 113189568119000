import Axios from "axios";
import { ERROR, LOADING, SUCCESS } from "../../../constants/generalConstant";
import { ADD_COURSE_IN_CART,ADD_COURSE_IN_LOCAL_CART, DELETE_COURSE_IN_CART, DELETE_COURSE_IN_LOCAL_CART, GET_COURSE_FROM_LOCAL_CART, GET_COURSE_IN_CART } from "../commonActions/types";
import { BASE_URL } from "../../../config/config";
import { onResponseError } from "../../../function/Interceptor";

export function addCourseInCartAction(body, actionResponse = {}) {
    const {onSuccess, onError, onLoading} = actionResponse;
    return async (dispatch) => {
        dispatch({type: ADD_COURSE_IN_CART, status: LOADING});
        onLoading?.(true)
        try {
            let config = {
                headers: {} // Initialize headers object
            };
            if (localStorage.getItem("beauty_key")) {
                config.headers.Authorization = "Bearer " + localStorage.getItem("beauty_key");
            }
            let response = await Axios.post(`${BASE_URL}courses/addCourseCart`, body,config);
            onSuccess?.(response?.data)
            dispatch({
                type: ADD_COURSE_IN_CART,
                status: SUCCESS,
                payload: response?.data,
            });
        } catch (error) {
            onError?.(error?.response?.data)
            onResponseError(error?.response?.data)
            dispatch({
                type: ADD_COURSE_IN_CART,
                status: ERROR,
                payload: error?.response?.data,
            });
        }
    };
}

export function addCourseInLocalCartAction(body) {
    return async (dispatch) => {
            dispatch({
                type: ADD_COURSE_IN_LOCAL_CART,
                status: SUCCESS,
                payload: body,
            });
    };
}

export function deleteCourseInCartAction(courseIds, actionResponse = {}) {
    const { onSuccess, onError, onLoading } = actionResponse;
    return async (dispatch) => {
        dispatch({ type: DELETE_COURSE_IN_CART, status: LOADING });
        onLoading?.(true);
        try {
            let config = {
                headers: {} // Initialize headers object
            };

            if (localStorage.getItem("beauty_key")) {
                config.headers.Authorization = "Bearer " + localStorage.getItem("beauty_key");
            }
            let response = await Axios.delete(`${BASE_URL}courses/deleteCourseCart?course=${courseIds}`, config);
            onSuccess?.(response?.data);
            dispatch({
                type: DELETE_COURSE_IN_CART,
                status: SUCCESS,
                payload: response?.data,
            });
        } catch (error) {
            onError?.(error?.response?.data);
            onResponseError(error?.response?.data)
            dispatch({
                type: DELETE_COURSE_IN_CART,
                status: ERROR,
                payload: error?.response?.data,
            });
        }
    };
}

export function deleteCourseInLocalCartAction(courseId) {
    return async (dispatch) => {
            dispatch({
                type: DELETE_COURSE_IN_LOCAL_CART,
                status: SUCCESS,
                payload: courseId,
            });
    };
}

export function getCourseInCartAction(actionResponse = {}) {
    const {onSuccess, onError, onLoading} = actionResponse;
    return async (dispatch) => {
        dispatch({type: GET_COURSE_IN_CART, status: LOADING});
        onLoading?.(true)
        try {
            let params = {
                pagination: true,
                fields: ''
            };

            let Config = {
                params,
            }
            if (localStorage.getItem("beauty_key")) {
                Config.headers = {Authorization: "Bearer " + localStorage.getItem("beauty_key")}
            }

            let response = await Axios.get(`${BASE_URL}courses/courseCartList`, Config);
            onSuccess?.(response?.data?.data)
            dispatch({
                type: GET_COURSE_IN_CART,
                status: SUCCESS,
                payload: response?.data?.data,
            });
        } catch (error) {
            onError?.(error)
            onResponseError(error?.response?.data)
            dispatch({
                type: GET_COURSE_IN_CART,
                status: ERROR,
                payload: error,
            });
        }
    };
}


export function getCourseFromLocalCartAction(courses) {
    return async (dispatch) => {
            dispatch({
                type: GET_COURSE_FROM_LOCAL_CART,
                status: SUCCESS,
                payload: courses,
            });
    };
}