import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Navigation, Pagination } from "swiper/modules";
import { Empty, Spin } from "antd";
import { getTopCategoriesActions } from "../../../redux/action/courseActions/topCategoriesActions";

const TopCategories = () => {
	const [showSlider, setShowSlider] = useState(false);
	const imageRefs = useRef([]);
	const dispatch = useDispatch();
	useEffect(() => {
		setShowSlider(true);
	}, []);

	const {
		data: topCategories,
		isLoading,
		isSuccess,
	} = useSelector((state) => state.courses.topCategories);

	useEffect(() => {
		!isSuccess && dispatch(getTopCategoriesActions());
	}, [dispatch, isSuccess]);

	useEffect(() => {
		function adjustImageHeight() {
			imageRefs?.current?.forEach((ref) => {
				const image = ref;
				if (image) {
					const width = image.offsetWidth === 0 ? 300 : image.offsetWidth;
					const height = (width / 300) * 200;
					image.style.height = `${height >= 200 ? 200 : height}px`;
				}
			});
		}

		if (topCategories.length > 0) {
            adjustImageHeight();
            const resizeListener = () => adjustImageHeight();
            window.addEventListener("resize", resizeListener);
            return () => {
                window.removeEventListener("resize", resizeListener);
            };
        }
	}, [topCategories]);

	return (
		<>
			<section className="layout-pt-md layout-pb-md">
				<div className="container">
					<div className="row justify-center text-center">
						<div className="col-auto">
							<div className="sectionTitle ">
								<h2 className="sectionTitle__title lh-14">Your Professional Hub</h2>

								<p className="sectionTitle__text ">
									Discover the benefits of continuous education.
								</p>
							</div>
						</div>
					</div>

					<div className="overflow-hidden pt-50 js-section-slider">
						{showSlider && (
							<Swiper
								modules={[Navigation, Pagination]}
								pagination={{
									el: ".swiper-paginationx",
									clickable: true,
								}}
								navigation={{
									nextEl: ".arrow-right-one",
									prevEl: ".arrow-left-one",
								}}
								spaceBetween={30}
								slidesPerView={1}
								breakpoints={{
									450: {
										slidesPerView: 2,
									},
									// when window width is >= 576px
									576: {
										slidesPerView: 3,
									},
									// when window width is >= 768px
									768: {
										slidesPerView: 4,
									},
									1200: {
										// when window width is >= 992px
										slidesPerView: 4,
									},
								}}
								loop={true}>
								<Spin spinning={isLoading}>
									{topCategories.length ? (
										topCategories.map((item, i) => (
											<SwiperSlide key={i}>
												<Link
													to={{
														pathname: `/courses/category/${item.id}`,
													}}
													data-aos="fade-left"
													data-aos-duration={(i + 1) * 350}
													className="featureCard -type-1 -featureCard-hover linkCustomTwo">
													<div className="featureCard__content">
														<div
															className="top_categories_images"
															style={{ width: "100%", display: "flex" }}>
															<img
																src={item.category_icon}
																alt="Top Categories"
																style={{
																	borderTopLeftRadius: "20px",
																	borderTopRightRadius: "20px",
																	flex: 1,
																	width: "100% !important",
																	objectFit: "cover",
																}}
																ref={(el) => (imageRefs.current[i] = el)}
															/>
														</div>
														<div className="h-150 pt-20 pb-30">
															<div className="featureCard__title">
																{item.name.split(" ")[0]} <br />
																{item.name.split(" ")[1] &&
																	item.name.split(" ")[1]}
															</div>
															<div className="featureCard__text">
																{item.available_course_count} Courses
															</div>
														</div>
													</div>
												</Link>
											</SwiperSlide>
										))
									) : (
										<Empty description={"Categories not added yet..."} />
									)}
								</Spin>
							</Swiper>
						)}

						<div className="d-flex justify-center x-gap-15 items-center pt-60 lg:pt-40">
							<div className="col-auto">
								<button className="d-flex items-center text-24 arrow-left-hover js-prev">
									<i className="icon icon-arrow-left arrow-left-one"></i>
								</button>
							</div>
							<div className="col-auto">
								<div className="swiper-paginationx"></div>
							</div>
							<div className="col-auto">
								<button className="d-flex items-center text-24 arrow-right-hover js-next">
									<i className="icon icon-arrow-right arrow-right-one"></i>
								</button>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default TopCategories;
