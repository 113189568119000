import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import Preloader from "../../../components/common/Preloader";
import MetaComponent from "../../../components/common/MetaComponent";
import { COMPANY_NAME } from "../../../config/config";
import HeaderAuth from "../../../components/layout/headers/HeaderAuth";
import { Link, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useDispatch } from "react-redux";
import { Result, Spin } from "antd";
import { userVerifyEmailAction } from "../../../redux/action/userActions/userActions";
import succesImage from '../../../svg/successful_purchase.svg'
import { useMediaQuery } from "@mui/material";

const metadata = {
	title: `Verify Email - ${COMPANY_NAME}`,
	description: `Elevate your e-learning content with ${COMPANY_NAME}, the most impressive LMS template for online courses, education and LMS platforms.`,
};

export default function VerifyEmailPage() {
	const { enqueueSnackbar } = useSnackbar();
	const dispatch = useDispatch();
	const { otp, encrytedEmail } = useParams();
	const [loading, setLoading] = useState(true);
	const [errorMessage, setErrorMessage] = useState("");
	const [isVerified, setIsVerified] = useState(false);
	const headerRef = useRef(null);
	const [headerHeight, setHeaderHeight] = useState(0);

	const isMobile = useMediaQuery("(max-width:600px)");

	const updateHeaderHeight = () => {
		if (headerRef.current) {
			setHeaderHeight(headerRef.current.clientHeight);
		}
	};

	useLayoutEffect(() => {
		// Set the initial height
		updateHeaderHeight();
	}, []);

	useEffect(() => {
		// Update the height on window resize
		window.addEventListener("resize", updateHeaderHeight);
		// Cleanup the event listener on component unmount
		return () => window.removeEventListener("resize", updateHeaderHeight);
	}, []);

	// Function to check if a string is Base64 encoded
	const isBase64 = (str) => {
		try {
			return btoa(atob(str)) === str;
		} catch (err) {
			return false;
		}
	};

	useEffect(() => {
		setLoading(true);
		setIsVerified(false);
		if (isBase64(encrytedEmail)) {
			const decodedEmailString = window.atob(encrytedEmail);
			setErrorMessage("");
			let body = {
				email: decodedEmailString,
				otp,
				email_verifed: true,
			};
			dispatch(
				userVerifyEmailAction(body, {
					onLoading: (loading) => {
						setLoading(true);
						setIsVerified(false);
						enqueueSnackbar("Loading...", { variant: "info" });
					},
					onError: (error) => {
						setLoading(false);
						setIsVerified(false);
						let errorMessage = String(error?.message ?? "Something went wrong");
						enqueueSnackbar(errorMessage, { variant: "error" });
					},
					onSuccess: (success) => {
						setLoading(false);
						success?.status_code === 200
							? setIsVerified(true)
							: setIsVerified(false);
						success?.status_code === 200
							? enqueueSnackbar(success.message[0], { variant: "success" })
							: enqueueSnackbar(success.message[0], { variant: "error" });
					},
				})
			);
		} else {
			setLoading(false);
			setIsVerified(false);
			setErrorMessage("Url is not Valid");
		}
	}, [dispatch, encrytedEmail, enqueueSnackbar, otp]);

	return (
		<div className="main-content  ">
			<MetaComponent meta={metadata} />
			<Preloader />

			<HeaderAuth headerRef={headerRef} />
			<div className="content-wrapper">
				<section className="form-page">
					<div
						className="centered-form"
						style={{
							width: "100%",
							marginTop: `${headerHeight}px`,
							display: "flex",
							justifyContent: "center",
							overflow: "auto",
						}}>
						<div className="form-page__content lg:py-50">
							<div className="container">
								<div className="row justify-center items-center">
									<Spin spinning={loading}>
										<div className="col-xl-12 col-lg-12">
											<div className="px-50 py-50 md:px-25 md:py-25 bg-white shadow-1 rounded-16">
												{!isVerified ? (
													<Result
														status="500"
														title="Email Verification Failed!"
														subTitle="Sorry, email is not verified."
													/>
												) : (
													<Result
														// status="403"
														icon={<img src={succesImage} alt="Success" style={{
															maxWidth: isMobile ? "80%" : '40%',
															maxHeight: isMobile ? "80%" : '40%',
															width: 'auto',
															height: 'auto',
														  }}/>}
														title="Email Verified Successfully!"
														subTitle="Your email is verified successfully. Please login to continue your journey."
													/>
												)}

												<div className="lh-12 text-dark-1 fw-500 text-center mt-20">
													<Link to="/login" className="text-purple-1">
														Try Login Now
													</Link>
												</div>
												<br />
												<div className="text-red-1 text-center ">
													{errorMessage}
												</div>
											</div>
										</div>
									</Spin>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		</div>
	);
}
