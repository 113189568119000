import React, { useEffect, useState } from "react";
import {
	Avatar,
	Button,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Tooltip,
	Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Add } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import {
	deleteSuperTestimonialsAction,
	getSuperTestimonials,
} from "../../../../redux/action/superAdminActions/superTestimonialsActions";
import AddSuperTestimonials from "./AddSuperTestimonials";
import { Popconfirm } from "antd";
import { useSnackbar } from "notistack";

export default function SuperTestimonials() {
	const dispatch = useDispatch();
	const [openAddForm, setOpenAddForm] = useState(false);
	const [openEditForm, setOpenEditForm] = useState(false);
	const [selectedTestimonials, setSelectedTestimonials] = useState({});
	const { enqueueSnackbar } = useSnackbar();

	const {
		data: Testimonials,
		isLoading,
		isSuccess,
	} = useSelector((state) => state.superAdmin.superTestimonials);

	useEffect(() => {
		if (!localStorage.getItem("beauty_super_key")) {
			window.location.replace("login");
		}
	}, []);

	useEffect(() => {
		!isSuccess && dispatch(getSuperTestimonials());
	}, [dispatch, isSuccess]);

	const handleDelete = (categoryId) => {
		dispatch(
			deleteSuperTestimonialsAction(categoryId, {
				onSuccess: (success) => {
					enqueueSnackbar("Testimonial Deleted Successfully...", {
						variant: "success",
					});
					dispatch(getSuperTestimonials());
				},
			})
		);
	};

	const columns = [
		{
			field: "index",
			width: 65,
			sortable: false,
			renderHeader: () => <Typography variant="h6">{"S.no "}</Typography>,
			renderCell: (params) => {
				return (
					<Typography>
						{params.row.index + 1}
						{"."}
					</Typography>
				);
			},
		},
		{
			field: "profile",
			width: 200,
			sortable: false,
			flex: 0.5,
			renderHeader: () => <Typography variant="h6">{"Profile "}</Typography>,
			renderCell: (params) => {
				return (
					<ListItem style={{ paddingLeft: "0px" }}>
						<ListItemAvatar>
							<Avatar
								alt={String(params.row.index + 1)}
								src={params.row.user_profile_image}
								sx={{ height: 60, width: 60, marginRight: "10px" }}
							/>
						</ListItemAvatar>
						<ListItemText
							primary={<b>{params?.row?.user_name ?? "--"}</b>}
							secondary={params.row.user_designation}
						/>
					</ListItem>
				);
			},
		},
		{
			field: "detail",
			sortable: false,
			flex: 1,
			renderHeader: () => (
				<Typography variant="h6">{"Title & Content"}</Typography>
			),
			renderCell: (params) => {
				return (
					<ListItemText
						primary={params.row.title}
						secondary={
							<Tooltip title={params.row.content}>
								<span>
									{params.row.content.length > 30
										? params.row.content.substring(0, 60) + "..."
										: params.row.content}
								</span>
							</Tooltip>
						}
					/>
				);
			},
		},
		{
			field: "actions",
			renderHeader: () => <Typography variant="h6">{"Actions"}</Typography>,
			sortable: false,
			renderCell: (params) => {
				return (
					<div className="d-flex justify-content-end">
						<div
							className="icon-edit"
							style={{ cursor: "pointer" }}
							onClick={() => {
								setSelectedTestimonials(params.row);
								setOpenEditForm(true);
							}}></div>
						&emsp;
						<Popconfirm
							title="Are you sure to delete this testimonial?"
							okText="Yes"
							cancelText="No"
							onConfirm={() => handleDelete(params.row.id)}>
							<div className="icon-bin" style={{ cursor: "pointer" }}></div>
						</Popconfirm>
					</div>
				);
			},
		},
	];

	const propsForAddForm = {
		open: openAddForm,
		setOpen: setOpenAddForm,
		type: "ADD",
	};

	const propsForEditForm = {
		open: openEditForm,
		setOpen: setOpenEditForm,
		type: "EDIT",
		selectedTestimonial: selectedTestimonials,
	};

	return (
		<div className="dashboard__main">
			{openAddForm && <AddSuperTestimonials {...propsForAddForm} />}
			{openEditForm && <AddSuperTestimonials {...propsForEditForm} />}
			<div className="dashboard__content bg-light-4">
				<div className="row pb-30">
					<div className="col-auto">
						<h1 className="text-20 lh-12 fw-500">Testimonials</h1>
						<div className="mt-1">Welcome to the Testimonials Section.</div>
					</div>
					<div className="col-auto ms-auto">
						<Button
							className="button py-10 px-10 -dark-1 text-white -dark-button-white col-12"
							startIcon={<Add />}
							onClick={() => setOpenAddForm(true)}>
							Add Testimonials
						</Button>
					</div>
				</div>

				{/*Table Starts here */}
				<div
					style={{
						width: "100%",
						overflowY: "scroll",
						height: "calc(100vh - 260px)",
					}}>
					<DataGrid
						rows={Testimonials}
						columns={columns}
						rowHeight={80}
						// initialState={{
						//     pagination: {
						//         paginationModel: {page: 0, pageSize: 5},
						//     },
						// }}
						// pageSizeOptions={[5, 10]}
						// checkboxSelection
						showCellVerticalBorder
						disableColumnMenu
						disableRowSelectionOnClick={true}
						disableColumnSelector
						disableFocus
						loading={isLoading}
					/>
				</div>
			</div>
		</div>
	);
}
