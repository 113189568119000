import Axios from "axios";
import { ERROR, LOADING, SUCCESS } from "../../../constants/generalConstant";
import { BASE_URL } from "../../../config/config";
import { GET_SELLER_DASHBOARD } from "../commonActions/types";
import { onResponseError } from "../../../function/Interceptor";

export function getSellerDashboardActions(actionResponse = {}) {
    const {onSuccess, onError, onLoading} = actionResponse;
    return async (dispatch) => {
        dispatch({type: GET_SELLER_DASHBOARD, status: LOADING});
        onLoading?.(true)
        try {
            let params = {
                pagination: false,
            };

            let Config = {
                params,
            }
            if (localStorage.getItem("beauty_key")) {
                Config.headers = {Authorization: "Bearer " + localStorage.getItem("beauty_key")}
            }
            let response = await Axios.get(`${BASE_URL}users/getSellerDashboard`, Config);
            onSuccess?.(response?.data)
            dispatch({
                type: GET_SELLER_DASHBOARD,
                status: SUCCESS,
                payload: response?.data,
            });
        } catch (error) {
            onError?.(error?.response?.data)
            onResponseError(error?.response?.data)
            dispatch({
                type: GET_SELLER_DASHBOARD,
                status: ERROR,
                payload: error?.response?.data,
            });
        }
    };
}
