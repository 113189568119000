import Axios from "axios";
import {GET_COURSE_CATEGORIES} from "../commonActions/types";
import {BASE_URL} from "../../../config/config";
import {ERROR, LOADING, SUCCESS} from "../../../constants/generalConstant";
import { onResponseError } from "../../../function/Interceptor";

export function getCourseCategories(actionResponse = {}) {
    const {onSuccess, onError, onLoading} = actionResponse;
    return async (dispatch) => {
        dispatch({type: GET_COURSE_CATEGORIES, status: LOADING});
        onLoading?.(true)
        try {
            let params = {
                pagination: false,
                fields: 'id,name,value,sub_category,sub_category__id,sub_category__name,sub_category__value,available_course_count'
            };

            let Config = {
                params,
            }
            if (localStorage.getItem("beauty_key")) {
                Config.headers = {Authorization: "Bearer " + localStorage.getItem("beauty_key")}
            }

            let response = await Axios.get(`${BASE_URL}common/getCourseCategoryList`, Config);
            const modifiedData = {
                ...response.data,
                data: response?.data?.data.map((item, index) => ({
                    ...item,
                    index
                }))
            }
            onSuccess?.(modifiedData)
            dispatch({
                type: GET_COURSE_CATEGORIES,
                status: SUCCESS,
                payload: modifiedData,
            });
        } catch (error) {
            onError?.(error)
            onResponseError(error?.response?.data)
            dispatch({
                type: GET_COURSE_CATEGORIES,
                status: ERROR,
                payload: error,
            });
        }
    };
}