import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { userUpdatePasswordAction } from "../../../../../../redux/action/userActions/userActions";

export default function EditUserPassword({ activeTab }) {
	const { enqueueSnackbar } = useSnackbar();
	const dispatch = useDispatch()
	const [loading, setLoading] = useState(false)

	const matchPasswordAndConfirmPassword = (password, confirmPassword) => {
		return password === confirmPassword;
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		const currentPassword = e.target.elements.currentPassword.value;
		const newPassword = e.target.elements.newPassword.value;
		const confirmNewPassword = e.target.elements.confirmNewPassword.value;

		const passwordLengthChecked = newPassword.trim().length < 3;
		if (passwordLengthChecked) {
			enqueueSnackbar("New Password should be greater than 3 letters!", {
				variant: "warning",
			});
			return;
		}

		const isPasswordMatched = matchPasswordAndConfirmPassword(
			newPassword,
			confirmNewPassword
		);
		if (isPasswordMatched) {
			let body = {
				"old_password":currentPassword,
				"password":newPassword,
				"confirm_password":confirmNewPassword
			};
			dispatch(userUpdatePasswordAction(body, {
			    onLoading: (loading) => {
			        setLoading(true)
			        enqueueSnackbar('Loading...', {variant: 'info'});
			    },
			    onError: (error) => {
			        setLoading(false)
			        let errorMessage = String(error?.message ?? 'Something went wrong');
			        enqueueSnackbar(errorMessage, {variant: 'error'});
			    },
			    onSuccess: (success) => {
			        setLoading(false)
			        enqueueSnackbar(success.message[0], {variant: 'success'});
			        e.target.reset();
			    }
			}))
		} else {
			enqueueSnackbar("Password not matched!", { variant: "warning" });
		}
	};

	return (
		<div
			className={`tabs__pane -tab-item-2 ${
				activeTab === 2 ? "is-active" : ""
			} `}>
			<form onSubmit={handleSubmit} className="contact-form row y-gap-30">
				<div className="col-md-7">
					<label className="text-16 lh-1 fw-500 text-dark-1 mb-10">
						Current password
					</label>

					<input
						required
						type="text"
						name="currentPassword"
						placeholder="Current password"
					/>
				</div>

				<div className="col-md-7">
					<label className="text-16 lh-1 fw-500 text-dark-1 mb-10">
						New password
					</label>

					<input
						required
						type="text"
						name="newPassword"
						placeholder="New password"
					/>
				</div>

				<div className="col-md-7">
					<label className="text-16 lh-1 fw-500 text-dark-1 mb-10">
						Confirm New Password
					</label>

					<input
						required
						type="text"
						name="confirmNewPassword"
						placeholder="Confirm New Password"
					/>
				</div>

				<div className="col-12">
					<button disabled={loading} className="button -md -purple-1 text-white">
						{loading ? "Loading..." :"Save Password"}
					</button>
				</div>
			</form>
		</div>
	);
}
