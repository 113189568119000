import React from "react";
import { useDispatch } from "react-redux";
import {useLocation, useNavigate} from "react-router-dom";
import { getCourseFromLocalCartAction } from "../../../../../redux/action/cartActions/cartActions";

const sidebarItems = [
    {
        id: 1,
        key: "my-dashboard",
        iconClass: "text-20 icon-discovery",
        text: "Dashboard",
    },
    {
        id: 2,
        key: 'create-course',
        iconClass: "text-20 icon-list",
        text: "Create Course",
    },
    {
        id: 2,
        key: 'courses',
        iconClass: "text-20 icon-list-sort",
        text: "Courses",
    },
    {
        id: 3,
        key: 'instructor-courses',
        iconClass: "text-20 icon-play-button",
        text: "Purchased Courses",
    },
    {
        id: 4,
        key: 'instructor-bookmark-courses',
        iconClass: "text-20 icon-bookmark",
        text: "Bookmarked Courses",
    },
    {
        id: 5,
        key: 'instructor-billing-list',
        iconClass: "text-20 icon-person-2",
        text: "Transaction History",
    },
    {
        id: 6,
        key: 'instructor-course-payment-list',
        iconClass: "text-20 icon-person-2",
        text: "Course Payment",
    },
    {
        id: 7,
        key: 'instructor-review',
        iconClass: "text-20 icon-rating-2",
        text: "Review",
    },
    {
        id: 8,
        key: 'instructor-settings',
        iconClass: "text-20 icon-setting",
        text: "Settings",
    },
    {
        id: 9,
        iconClass: "text-20 icon-power",
        key: "logout",
        text: "Logout",
    },
];

export default function LoggedInSidebar() {
    const {pathname} = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch()
    
    const handleCloseSideBarForMobile = () => {
        if (window.innerWidth < 600) {
            document
                .getElementById("dashboardOpenClose")
                .classList.add("-is-sidebar-hidden");
        }
    };

    let pathSegments = pathname.split("/");
    let lastPathValue = pathSegments[pathSegments.length - 1];


    const handleSideBarClicked = (key) => {
        switch (key) {
            case "logout" : {
                localStorage.removeItem('beauty_key');
                localStorage.removeItem('beauty_cart_items');
                localStorage.removeItem('beauty_seller');
				localStorage.removeItem('beauty_buyer');
				localStorage.removeItem('loggedIn_user');
                dispatch(getCourseFromLocalCartAction())
                window.location.replace('/')
                break;
            }
            case "my-dashboard" : {
                navigate('')
                handleCloseSideBarForMobile()
                break;
            }
            case "create-course" :
            case "courses": 
            case "instructor-courses":
            case "instructor-billing-list":
            case "instructor-course-payment-list":
            case "instructor-settings":
            case "instructor-review":
            case "instructor-bookmark-courses":{
                navigate(key)
                handleCloseSideBarForMobile()
                break;
            }
            default:
                return
        }
    }
    return (
        <div className="sidebar -dashboard">
            {sidebarItems.map((elm, i) => (
                <div
                    key={i}
                    className={`sidebar__item   ${
                        lastPathValue === elm.key ? "-is-active" : ""
                    } `}
                    onClick={() => handleSideBarClicked(elm.key)}
                    style={{cursor: 'pointer'}}
                >
                    <i className={`${elm.iconClass} mr-15`}></i>
                    {elm.text}
                </div>
            ))}
        </div>
    );
}
