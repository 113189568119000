export const trainer_getting_started = {
	heading: "Getting Started",
	subHeading: "Learn How Pro Beauty Works and How to Start Learning.",
	faqs: [
		{
			id: 1,
			question: "How to sign up or create an account?",
			answer: (
				<>
					Click the <b>"Sign Up"</b> button on the top header. Fill out your
					Name, Email Address, and create an <b>8-digit strong password</b>.
					Click on the
					<b>Register</b> button. You will receive a confirmation email to
					verify your email address.
				</>
			),
		},
		{
			id: 2,
			question: "Do you offer special pricing for big teams?",
			answer: (
				<>
					<ul>
						<li className="custom-list">
							<span style={{ fontWeight: "bold" }}>Browse Courses:</span> On the
							homepage, browse course categories and select a category of
							interest and find the perfect course.
						</li>
						<br />
						<li className="custom-list">
							<span style={{ fontWeight: "bold" }}>Enroll:</span> Click on a
							course to view details, then click <b>Add to Cart</b>. Navigate to
							the cart icon at the top of the page. <b>Click View Cart</b> or{" "}
							<b>Login to Checkout</b>.
						</li>
						<br />
						<li className="custom-list">
							<span style={{ fontWeight: "bold" }}>Existing Users:</span> Log in
							with your username and password.
						</li>
						<br />
						<li className="custom-list">
							<span style={{ fontWeight: "bold" }}>New Users:</span> If you are
							new user click on sign up for free fill out your information
							(name, email, password) and register. You'll receive a
							confirmation email and be redirected to website.
						</li>
						<br />
						<li className="custom-list">
							<span style={{ fontWeight: "bold" }}>Checkout:</span> Complete
							checkout by filling out necessary details, selecting a payment
							option, and clicking <b>Pay</b>.
						</li>
					</ul>
				</>
			),
		},
		{
			id: 3,
			question: "Where can I find my Bookmarked Courses?",
			answer: (
				<>
					Log in to your dashboard. On the left-hand side menu, click the
					<b>"Bookmarked Courses"</b> tab to see all your saved courses.
				</>
			),
		},
	],
};

export const trainer_account_profile = {
	heading: "Account / Profile",
	subHeading: "Manage Your Account Settings.",
	faqs: [
		{
			id: 1,
			question: "How do I edit my personal information?",
			answer: (
				<>
					Log in to your dashboard. Click <b>"Settings"</b> then{" "}
					<b>"Edit Profile"</b> Update your name or phone number as needed.
					Click <b>"Update Profile"</b> to save changes.
				</>
			),
		},
		{
			id: 2,
			question: "Can I change my email address?",
			answer: (
				<>
					Unfortunately, you cannot directly change your email address. However,
					you can create a new account using your preferred email address.
				</>
			),
		},
		{
			id: 3,
			question: "How do I set a new password?",
			answer: (
				<>
					Log in to your dashboard. Click <b>"Settings"</b> then{" "}
					<b>"Password."</b> Enter your current password, then create and
					confirm a new strong password. Click
					<b>"Save Password.”</b>
				</>
			),
		},
		{
			id: 4,
			question: "How do I reset my password?",
			answer: (
				<>
					Click <b>"Login"</b> on the top header. On the login page, click{" "}
					<b>"Forgot Password."</b> Enter your registered email address and
					click <b>"Submit."</b>
					Check your inbox (including spam) for a password reset link. Click the
					link, create a new strong password, and confirm it. Click{" "}
					<b>"Submit"</b> to reset your password.
				</>
			),
		},
		{
			id: 5,
			question: "How do I change my profile picture?",
			answer: (
				<>
					Log in to your dashboard. Click <b>"Settings"</b> then{" "}
					<b>"Edit Profile."</b> Click the <b>"Upload"</b> icon to choose a new
					image. Click <b>"Update Profile"</b> to save changes.
				</>
			),
		},
	],
};

export const trainer_learning_experience = {
	heading: "Learning Experience",
	subHeading: "Everything About the Pro Beauty Port Learning Experience.",
	faqs: [
		{
			id: 1,
			question: "How do I start and access my enrolled courses?",
			answer: (
				<>
					Log in to your dashboard. Click the <b>"My Courses"</b> tab to see
					your enrolled courses. Click on the course you want to start and
					you'll see all the learning materials!
				</>
			),
		},
		{
			id: 2,
			question: "How do I download my certificate?",
			answer: (
				<>
					Log in to your dashboard. Click the <b>"My Courses"</b> tab. Click on
					the course you want to download the certificate for. Once the course
					is 100% complete, a <b>"Download Certificate"</b> button will appear
					in the top right corner. Click the button to save your certificate.
				</>
			),
		},
	],
};

export const instructor_getting_started = {
	heading: "Getting Started",
	subHeading:
		"Learn How Pro Beauty Works and How to Start Teaching on Pro Beauty Port.",
	faqs: [
		{
			id: 1,
			question: "How do I become an educator on Pro Beauty Port?",
			answer: (
				<>
					To become an educator on Pro Beauty Port, click on the{" "}
					<b>Become an Educator</b> button in the hero section of the home page
					or in the footer. Fill out the basic information such as name, phone
					number, business name, and profession type.
					<br />
					Click <b>Next</b> and fill out your address. Click Next again and
					enter your License Number, License Expiry Date, upload your
					professional license, provide your email address, and create an{" "}
					<b>8-digit strong password</b>. Accept the terms and conditions and
					click <b>Sign Up</b>. You will receive an email to verify your email
					address. Once verified, click <b>Try Login Now</b>.
				</>
			),
		},
		{
			id: 2,
			question: "What are the requirements to become an educator?",
			answer: (
				<>
					Educators must have recorded course videos, content, and a course
					banner image (Recommended Image Ratio: 400px * 350px). If possible,
					create a short intro video to introduce yourself and your course.
				</>
			),
		},
		{
			id: 3,
			question: "What kind of courses can I teach on Pro Beauty Port?",
			answer: (
				<>
					You can teach a wide range of beauty-related courses, including
					makeup, hairstyling, skincare, nail art, and more. Focus on creating
					courses that align with your expertise and target audience.
				</>
			),
		},
		{
			id: 4,
			question: "How do I navigate the educator dashboard?",
			answer: (
				<>
					The educator dashboard provides an overview of your courses, student
					engagement, and earnings. Navigate to the header on the top right-hand
					side, click on the <b>Profile Icon</b>, and then click on{" "}
					<b>Dashboard</b> to access different sections like{" "}
					<b>
						My Courses, Earnings, Transaction History, Purchased and Bookmarked
						Courses, and Settings
					</b>
					.
				</>
			),
		},
	],
};

export const instructor_course_building = {
	heading: "Course Building",
	subHeading: " Learn How to Create High-Quality Courses.",
	faqs: [
		{
			id: 1,
			question: " What type of content can I include in my courses?",
			answer: (
				<>
					You can include various content formats such as video lectures,
					presentations, downloadable materials, and more.
				</>
			),
		},
		{
			id: 2,
			question: "How to create and upload course content?",
			answer: (
				<>
					<div>
						<h5>1. Start with Course Details:</h5>
						<div className="mt-10">
							<ul>
								<li className="custom-list">
									<b>Go to your dashboard</b> and click on{" "}
									<b>"Create New Course"</b> . Fill out the basic course
									information:
									<br />
									<ul>
										<li className="custom-list-nested">Course Title.</li>
										<li className="custom-list-nested mt-5">
											Short Description.
										</li>
										<li className="custom-list-nested mt-5">About Course.</li>
										<li className="custom-list-nested mt-5">
											Course Level (e.g., Beginner, Intermediate, Advanced).
										</li>
										<li className="custom-list-nested mt-5">
											Course Price and Sale Price (Sale price should be less
											than course price).
										</li>
										<li className="custom-list-nested mt-5">
											Course Category and Subcategory.
										</li>
										<li className="custom-list-nested mt-5">
											What Students Will Learn (key points students will learn).
										</li>
										<li className="custom-list-nested mt-5">
											Materials Included (e.g., PDF, Presentation, Videos).
										</li>
									</ul>
								</li>
								<li className="custom-list">
									Click <b>Save and Next</b>.
								</li>
							</ul>
						</div>
					</div>

					<div className="mt-20">
						<h5>2. Add Media:</h5>
						<div className="mt-10">
							<ul>
								<li className="custom-list">
									Upload the <b>Course Banner</b> (Recommended Image Ratio:
									400px * 350px).
								</li>
								<li className="custom-list">
									Upload a <b>Thumbnail Video</b> (Recommended Resolution: 1920
									x 1080) - this is optional.
								</li>
								<li className="custom-list">
									Click on <b>Upload Files</b> and wait for the upload to
									complete.
								</li>
							</ul>
						</div>
					</div>

					<div className="mt-20">
						<h5>3. Create Course Structure:</h5>
						<div className="mt-10">
							<ul>
								<li className="custom-list">
									Click on <b>Add Chapter</b>, type the chapter name, and click
									Save.
								</li>
								<li className="custom-list">
									Click on the chapter to create lessons.
								</li>
								<li className="custom-list">
									Click on <b>Create Lesson</b> to add a lesson:
									<br />
									<ul>
										<li className="custom-list-nested">
											Enter the <b>Lesson Name</b> and <b>Description</b>.
										</li>
										<li className="custom-list-nested mt-5">
											Add a <b>Feature Image</b> and <b>Lesson Video</b>.
										</li>
										<li className="custom-list-nested mt-5">
											If needed, upload any <b>Attachment Files</b> for students
											to download.
										</li>
										<li className="custom-list-nested mt-5">
											Click <b>Save</b>.
										</li>
									</ul>
								</li>
							</ul>
						</div>
					</div>

					<div className="mt-20">
						<h5>4. Preview and publish:</h5>
						<div className="mt-10">
							<ul>
								<li className="custom-list">
									Click <b>"Preview"</b> to see how your course looks.
								</li>
								<li className="custom-list">
									Once satisfied, click <b>"Publish"</b> to make your course
									live.
								</li>
								<li className="custom-list">
									If you're not ready to publish, your course will be saved as a
									draft and accessible from your course list.
								</li>
							</ul>
						</div>
					</div>
				</>
			),
		},
	],
};

export const instructor_account_profile = {
	heading: "Account/Profile",
	subHeading: "Manage Your Educator Account Settings.",
	faqs: [
		{
			id: 1,
			question: "How do I update my educator profile?",
			answer: (
				<>
					Log into your dashboard, go to <b>Settings</b>, and click on{" "}
					<b>Edit Profile</b>. You can update your profile picture, name, phone
					number, and bio. Click <b>Update Profile</b> to apply changes.
				</>
			),
		},
		{
			id: 2,
			question: "How do I set a new password?",
			answer: (
				<>
					Log in to your dashboard. Click on <b>"Settings"</b> then{" "}
					<b>"Password."</b> Enter your current password, then create and
					confirm a new strong password. Click <b>"Save Password.”</b>
				</>
			),
		},
		{
			id: 3,
			question: "How do I reset my password?",
			answer: (
				<>
					Click <b>"Login"</b> on the top header. On the login page, click{" "}
					<b>"Forgot Password."</b> Enter your registered email address and
					click <b>"Submit."</b>
					Check your inbox (including spam) for a password reset link. Click the
					link, create a new strong password, and confirm it. Click{" "}
					<b>"Submit"</b> to reset your password.
				</>
			),
		},
		{
			id: 4,
			question: "How do I change my profile picture?",
			answer: (
				<>
					Log in to your dashboard. Click <b>"Settings"</b> then{" "}
					<b>"Edit Profile."</b> Click the <b>"Upload"</b> icon to choose a new
					image. Click <b>"Update Profile"</b> to save changes.
				</>
			),
		},
		{
			id: 5,
			question: "Can I change my email address?",
			answer: (
				<>
					Unfortunately, you cannot directly change your email address. However,
					you can create a new account using your preferred email address.
				</>
			),
		},
		{
			id: 6,
			question: "How can i change my address?",
			answer: (
				<>
					Log in to your dashboard. Click on <b>"Settings"</b> then{" "}
					<b>"Address."</b> Enter your address, then click on{" "}
					<b>save password</b>.
				</>
			),
		},
	],
};

export const instructor_payment = {
	heading: "Educator Payments",
	subHeading: "Understand the Revenue Sharing and How to Receive Payment.",
	faqs: [
		{
			id: 1,
			question: "How does the revenue-sharing model work?",
			answer: (
				<>
					At our E-learning platform, we prioritize our educators' success.
					For every course sold, educators receive 80% of the revenue, while
					we retain a 20% commission to support our platform’s maintenance and
					development. This structure ensures that you are rewarded generously
					for your expertise and effort, allowing you to focus on creating and
					sharing valuable content with your students.
				</>
			),
		},
		{
			id: 2,
			question: "When do I get paid?",
			answer: (
				<>
					Please note that our payment gateway provider holds the funds for 14
					days from the date of purchase. This holding period is a standard
					practice to ensure payment security and processing accuracy. During
					this time, you can track how many days remain until withdrawal in your
					profile dashboard. Once the funds are available for withdrawal, this
					will be reflected in your dashboard, and you will receive a
					notification. This ensures a smooth and reliable payment experience.
				</>
			),
		},
		{
			id: 3,
			question: "How do I set up my payment information?",
			answer: (
				<>
					Log into your dashboard. You will see the <b>Withdraw Amount</b> box. Type
					your PayPal email address and click on the <b>Withdraw</b> button.
				</>
			),
		},
	],
};
