import { Avatar, Rating } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";

export default function CoursesCardDashboard(props) {
	const { data, updateCourseBookmark } = props;
	const location = useLocation();
	const imageRef = useRef();
	const isBookmarkPage = location.pathname.includes("bookmark");
	const getRatingStars = (count) => {
		return (
			<Rating size="small" readOnly precision={0.5} defaultValue={count} />
		);
	};

	useEffect(() => {
        function adjustImageHeight() {
            const image = imageRef.current;
            if (image) {
                const width = image.offsetWidth;
                const height = (width / 451) * 316;
                image.style.height = `${height}px`;
            }
        }
        adjustImageHeight();
        window.addEventListener("resize", adjustImageHeight); 
        return () => {
            window.removeEventListener("resize", adjustImageHeight);
        };
    }, [data.id])

	return (
		<div
			className="w-1/5 xl:w-1/3 lg:w-1/2 sm:w-1/1">
			<div className="relative">
				<img
					className="rounded-8 w-1/1"
					src={data?.course_thumbnail_image ?? ""}
					alt="courses"
					style={{
						objectFit: "cover",
						height: "200px",
						width:'100%'
					}}
					ref={imageRef}
				/>
				<button
					onClick={() => updateCourseBookmark(data)}
					className="absolute-button">
					<span className="d-flex items-center justify-center size-35 bg-white shadow-1 rounded-8">
						{data?.is_bookmarked ? (
							<i className="icon-bookmark-filled text-purple-1"></i>
						) : (
							<i className="icon-bookmark"></i>
						)}
					</span>
				</button>
			</div>

			<div
				className="pt-15"
			>
				<div className="d-flex y-gap-10 justify-between items-center flex-wrap">
					<div className="d-flex align-items-center text-14 lh-1">
						<Avatar
							src={data?.seller_obj?.user_profile_image ?? ""}
							alt={`${data?.seller_obj?.user_first_name} ${data?.seller_obj?.user_last_name}`}
						/>
						&nbsp;
						<Link
							className="linkCustom"
							to={`/instructors/${data?.seller_obj?.slug_name}`}>
							{`${data?.seller_obj?.user_first_name ?? ""} ${
								data?.seller_obj?.user_last_name ?? ""
							}`}
						</Link>
					</div>

					<div className="d-flex items-center">
						<div className="text-14 lh-1 text-yellow-1">
							( {data?.ratings_obj?.total_rating ?? 0} )&nbsp;
						</div>
						<div className="d-flex x-gap-5 items-center">
							{getRatingStars(data?.ratings_obj?.total_rating ?? 0)}
						</div>
					</div>
				</div>

				<h3 className="text-16 fw-500 lh-15 mt-10">
					<Link
						to={
							isBookmarkPage
								? `/courses/${data.slug_name}`
								: `${data.slug_name}`
						}
						className="col text-purple-1">
						{data.title}
					</Link>
				</h3>

				{!props.isBookmarkPage ? (
					<div className="progress-bar mt-10">
						<div className="progress-bar__bg bg-light-3"></div>
						<div
							className="progress-bar__bar bg-purple-1"
							style={{ width: `${data?.progress ?? 0}%` }}></div>
					</div>
				) : null}

				{!props.isBookmarkPage ? (
					<div className="d-flex y-gap-10 justify-between items-center mt-10 flex-wrap">
						<div className="text-dark-1">{data?.progress ?? 0}% Completed</div>
						<div>Chapters: {data?.chapters_count ?? 0}</div>
					</div>
				) : (
					<div className="d-flex y-gap-10 justify-between items-center mt-10 flex-wrap">
						<div>
							Chapters: <span className="text-dark-1">{data?.chapters_count ?? 0}</span>
						</div>
						<div className="text-line-through">
							${data.course_price}
						</div>
					</div>
				)}
				{props.isBookmarkPage ? (
					<div className="d-flex y-gap-5 justify-between items-center flex-wrap">
						<div>Lessons: <span className="text-dark-1">{data?.lesson_count ?? 0}</span></div>
						<div className="text-dark-1">${data.sale_price}</div>
					</div>
				): null}
			</div>
		</div>
	);
}
