import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import CourseCartBox from "./CourseCartBox";
import { Spin } from "antd";

const CartToggle = ({ allClasses, parentClassess }) => {
  const [activeCart, setActiveCart] = useState(false);

  const {
    data: courseCart,
    isLoading : isCartLoading,
} = useSelector(state => state.cart.courseCart)

  return (
    <>
      <div className={parentClassess ? parentClassess : ""}>
        <button
          style={{ position: "relative" }}
          onClick={() => setActiveCart((pre) => !pre)}
          className={`${allClasses ? allClasses : ""}`}
          data-el-toggle=".js-cart-toggle"
        >
          <i className="text-20 icon icon-basket"></i>
          <div className="cartProductCount">
              <>{courseCart.length > 9 ? "9+" : courseCart.length} </>
          </div>
        </button>

        <div
          className={`toggle-element js-cart-toggle ${
            activeCart ? "-is-el-visible" : ""
          }`}
        >
        <Spin spinning={isCartLoading}>
          <CourseCartBox />
          </Spin>
        </div>
      </div>
    </>
  );
};

export default CartToggle;
