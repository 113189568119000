import { Avatar, Rating } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { IMAGE_FILE_URL } from "../../../config/config";

export default function CourseCard(props) {
	const { data } = props;
	const imageRef = useRef();

	const getRatingStars = (count) => {
		return (
			<Rating size="small" readOnly precision={0.5} defaultValue={count} />
		);
	};

	useEffect(() => {
        function adjustImageHeight() {
            const image = imageRef.current;
            if (image) {
                const width = image.offsetWidth;
                const height = (width / 451) * 316;
                image.style.height = `${height}px`;
            }
        }
        adjustImageHeight();
        window.addEventListener("resize", adjustImageHeight); 
        return () => {
            window.removeEventListener("resize", adjustImageHeight);
        };
    }, [data.id])

	return (
		<div className="col-sm-12 col-md-6 col-lg-3">
			<div>
				<div className="coursesCard -type-1">
					<div className="relative">
						<div className="coursesCard__image overflow-hidden rounded-8">
							<Avatar
								src={data?.course_thumbnail_image ?? ""}
								alt="courses"
								variant="square"
								sx={{
									width: "100%",
									objectFit: "contain",
								}}
								ref={imageRef}
							/>
							<div className="coursesCard__image_overlay rounded-8"></div>
						</div>
						<div className="d-flex justify-between py-10 px-10 absolute-full-center z-3">
							{data?.is_popular_badge && (
								<div>
									<div className="px-15 rounded-200 bg-purple-1">
										<span className="text-11 lh-1 uppercase fw-500 text-white">
											Popular
										</span>
									</div>
								</div>
							)}
							{data?.is_best_seller_badge && (
								<div>
									<div className="px-15 rounded-200 bg-green-1">
										<span className="text-11 lh-1 uppercase fw-500 text-dark-1">
											Best sellers
										</span>
									</div>
								</div>
							)}
						</div>
						<div className="d-flex justify-between py-10 px-10 absolute-full-center z-3"></div>
					</div>

					<div className="h-100 pt-15">
						<div className="d-flex items-center">
							<div className="text-14 lh-1 text-yellow-1 mr-10">
								{data?.ratings_obj?.total_rating}
							</div>
							<div className="d-flex x-gap-5 items-center">
								{getRatingStars(data?.ratings_obj?.total_rating)}
							</div>
							<div className="text-13 lh-1 ml-10">
								({data?.ratings_obj?.total_reviews_count})
							</div>
						</div>

						<div className="text-17 lh-15 fw-500 text-dark-1 mt-10">
							<Link className="linkCustom" to={`/courses/${data.slug_name}`}>
								{data.title}
							</Link>
						</div>

						<div className="d-flex justify-between items-center pt-10">
							<div className="d-flex items-center">
								<div className="mr-8">
									<img
										src={`${IMAGE_FILE_URL}/assets/img/coursesCards/icons/1.svg`}
										alt="lesson"
									/>
								</div>
								<div className="text-14 lh-1">{data.lesson_count} lesson</div>
							</div>

							<div className="d-flex items-center">
								<div className="mr-8">
									<img
										src={`${IMAGE_FILE_URL}/assets/img/coursesCards/icons/2.svg`}
										alt="time"
									/>
								</div>
								<div className="text-14 lh-1">{data?.course_duration ?? 0}</div>
							</div>

							<div className="d-flex items-center">
								<div className="mr-8">
									<img
										src={`${IMAGE_FILE_URL}/assets/img/coursesCards/icons/3.svg`}
										alt="level"
									/>
								</div>
								<div className="text-14 lh-1">{data?.level ?? ""}</div>
							</div>
						</div>

						<div className="coursesCard-footer">
							<div className="coursesCard-footer__author">
								<Avatar
									src={data.seller_obj?.user_profile_image}
									alt="authorName"
									style={{ width:'35px',height:'35px',marginRight:'5px',marginLeft:'0px'}}
								/>
								<Link
									className="linkCustom"
									to={`/instructors/${data?.seller_obj?.slug_name}`}>
									{data.seller_obj?.user_first_name +
										" " +
										data.seller_obj?.user_last_name}
								</Link>
							</div>

							<div className="coursesCard-footer__price">
								{!data.is_course_free ? (
									<>
										<div>${data.course_price}</div>
										<div>${data.sale_price}</div>
									</>
								) : (
									<>
										<div></div>
										<div>Free</div>
									</>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
