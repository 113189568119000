import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { useDispatch } from "react-redux";
import { trainerSignUp } from "../../../redux/action/userActions/userActions";
import { ConfigProvider, Steps, DatePicker } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faIdCard, faUser } from "@fortawesome/free-regular-svg-icons";
import { faMapLocationDot } from "@fortawesome/free-solid-svg-icons";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import { GetCountries, GetState, GetCity } from "react-country-state-city";
import "react-country-state-city/dist/react-country-state-city.css";
import Select from "react-select";
import { getMediaUploadLink } from "../../../redux/action/myDashboardActions/coursesActions";
import { TRAINER_LICENSE_IMAGE_PATH } from "../../../config/config";

export default function TrainerSignUpNewForm() {
	const { enqueueSnackbar } = useSnackbar();
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);
	const [currentStep, setCurrentStep] = useState(0);
	const [readCondition, setReadCondtion] = useState(false);
	const [countriesList, setCountriesList] = useState([]);
	const [stateList, setStateList] = useState([]);
	const [cityList, setCityList] = useState([]);
	const [countryLoading, setCountryLoading] = useState(false);
	const [stateLoading, setStateLoading] = useState(false);
	const [cityLoading, setCityLoading] = useState(false);

	const [trainerInputData, setTrainerInputData] = useState({
		first_name: "",
		last_name: "",
		email: "",
		password: "",
		confirm_password: "",
		phone: "",
		designation: "",
		business_name: "",
		licence_number: "",
		licence_number_image: "",
		licence_number_image_url: "",
		expiration: "",
		expiration_dateString: "",
		street: "",
		cityObject: {
			label: "",
			value: "",
		},
		stateObject: {
			label: "",
			value: "",
		},
		countryObject: {
			label: "",
			value: "",
		},
		pin_code: "",
	});

	useEffect(() => {
		setCountryLoading(true);
		GetCountries().then((result) => {
			const updateResult = result?.length
				? result.map((item) => ({
						...item,
						value: item.id,
						label: item.name,
				  }))
				: [];
			setCountriesList(updateResult);
			setCountryLoading(false)
		});
	}, []);

	const handleChange = (event) => {
		const { name, value } = event.target;
		setTrainerInputData({
			...trainerInputData,
			[name]: value,
		});
	};

	const handlePhoneChange = (event) => {
		setTrainerInputData({
			...trainerInputData,
			phone: event,
		});
	};

	const handleImageChange = (event) => {
		if (event.target.files[0]) {
			const imageUrl = URL.createObjectURL(event.target.files[0]);
			setTrainerInputData({
				...trainerInputData,
				licence_number_image: event.target.files[0],
				licence_number_image_url: imageUrl,
			});
		}
	};

	const contentStyle = {
		marginTop: 16,
	};

	// Function to generate a random string
	function generateRandomString(length) {
		const characters =
			"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
		let result = "";
		for (let i = 0; i < length; i++) {
			result += characters.charAt(
				Math.floor(Math.random() * characters.length)
			);
		}
		return result;
	}

	// Function to generate a unique filename
	function generateUniqueFilename() {
		const timestamp = new Date().getTime(); // Current timestamp
		const randomString = generateRandomString(8); // Generate a random string of length 8
		return `${timestamp}_${randomString}`;
	}

	const handleEmptyTrainerObject =()=>{
		setTrainerInputData({
			first_name: "",
			last_name: "",
			email: "",
			password: "",
			confirm_password: "",
			phone: "",
			designation: "",
			business_name: "",
			licence_number: "",
			licence_number_image: "",
			licence_number_image_url: "",
			expiration: "",
			expiration_dateString: "",
			street: "",
			cityObject: {
				label: "",
				value: "",
			},
			stateObject: {
				label: "",
				value: "",
			},
			countryObject: {
				label: "",
				value: "",
			},
			pin_code: "",
		})
	}

	const handleCreateTrainer = (license_image) => {
		let body = {
			first_name: trainerInputData.first_name,
			last_name: trainerInputData.last_name,
			email: trainerInputData.email,
			password: trainerInputData.password,
			confirm_password : trainerInputData.confirm_password,
			phone: trainerInputData?.phone ? trainerInputData.phone : null,
			designation: trainerInputData.designation,
			business_name: trainerInputData.business_name,
			licence_number : trainerInputData.licence_number,
			street : trainerInputData.street,
			pin_code : trainerInputData.pin_code,
			city : trainerInputData?.cityObject?.label ? trainerInputData.cityObject.label : null,
			city_id : trainerInputData?.cityObject?.value ? trainerInputData.cityObject.value : null,
			state : trainerInputData?.stateObject?.label ? trainerInputData.stateObject.label : null,
			state_id : trainerInputData?.stateObject?.value ? trainerInputData.stateObject.value : null,
			country : trainerInputData?.countryObject?.label ? trainerInputData.countryObject.label : null,
			country_id : trainerInputData?.countryObject?.value ? trainerInputData.countryObject.value : null,
			licence_number_image: license_image,
			expiration : trainerInputData.expiration_dateString
		};
		dispatch(trainerSignUp(body, {
			onLoading: (loading) => {
				setLoading(true)
				enqueueSnackbar('Loading...', {variant: 'info'});
			},
			onError: (error) => {
				setLoading(false)
				let errorMessage = String(error?.message ?? 'Something went wrong');
				enqueueSnackbar(errorMessage, {variant: 'error'});
			},
			onSuccess: (success) => {
				setLoading(false)
				enqueueSnackbar(success.message[0], {variant: 'success'});
				handleEmptyTrainerObject()
				setTimeout(() => {
				    window.location.replace(`verify-email-message/${window.btoa(trainerInputData.email)}`)
				}, 1000)
			}
		}))
	};

	const uploadFilesOnStorage = async (
		url,
		file,
		fileName
	) => {
		if (file) {
			setLoading(true);
			const renamedFile = new File([file], fileName, { type: file.type });

			try {
				await fetch(url, {
					method: "PUT",
					body: renamedFile,
				});

				let license_image = `${TRAINER_LICENSE_IMAGE_PATH}${fileName}`
				handleCreateTrainer(license_image);
			} catch (error) {
				enqueueSnackbar(error, { variant: "error" });
				setLoading(false);
			}
		}
	};

	const handleImageUpload = async () => {
		// Handle image upload here
		setLoading(true)
		const ImageExtension = trainerInputData.licence_number_image.name.split(".").pop();
		const imageName = generateUniqueFilename() + "." + ImageExtension;

		dispatch(
			getMediaUploadLink(`${TRAINER_LICENSE_IMAGE_PATH}${imageName}`, {
				onLoading: (loading) => {
					setLoading(true);
				},
				onError: (error) => {
					setLoading(false);
					let errorMessage = String(error?.message ?? "Something went wrong");
					enqueueSnackbar(errorMessage, { variant: "error" });
				},
				onSuccess: (success) => {
					uploadFilesOnStorage(
						success?.data?.signed_url,
						trainerInputData.licence_number_image,
						imageName
					);
				},
			})
		);
	};

	const handlePreviousSteps = (event) => {
		event.preventDefault();
		setCurrentStep(currentStep - 1);
	};

	const matchPasswordAndConfirmPassword = (password, confirmPassword) => {
		return password === confirmPassword;
	};
	const handleSubmitFirstStep = (event) => {
		event.preventDefault();
		setCurrentStep(currentStep + 1);
	};

	const handleSubmitSecondStep = (event) => {
		event.preventDefault();
		setCurrentStep(currentStep + 1);
	};

	const handleSubmitThirdStep = (event) => {
		event.preventDefault();

		const passwordLengthChecked = trainerInputData.password.trim().length < 3;
		if (passwordLengthChecked) {
			enqueueSnackbar("Password should be greater than 3 letters!", {
				variant: "warning",
			});
			return;
		}

		const isPasswordMatched = matchPasswordAndConfirmPassword(
			trainerInputData.password,
			trainerInputData.confirm_password
		);
		if (isPasswordMatched) {
			if (!trainerInputData.licence_number_image_url) {
				enqueueSnackbar("Please upload license image.", { variant: "error" });
				return;
			}
			if (!readCondition) {
				enqueueSnackbar("Please agree with terms and conditions.", {
					variant: "error",
				});
				return;
			}
			handleImageUpload();
		} else {
			enqueueSnackbar("Password not matched!", { variant: "warning" });
		}
		// Accessing form values
	};

	const firstStepContent = (
		<form
			className="contact-form respondForm__form row y-gap-10 pt-10"
			onSubmit={(event) => handleSubmitFirstStep(event)}
			id="First_Step_Form">
			<div className="col-lg-6">
				<label className="text-16 lh-1 fw-500 text-dark-1 mb-8">
					First Name <span className="text-red">*</span>
				</label>
				<input
					required
					type="text"
					name="first_name"
					value={trainerInputData.first_name}
					onChange={handleChange}
					placeholder="First Name"
				/>
			</div>
			<div className="col-lg-6">
				<label className="text-16 lh-1 fw-500 text-dark-1 mb-8">
					Last Name <span className="text-red">*</span>
				</label>
				<input
					required
					type="text"
					name="last_name"
					value={trainerInputData.last_name}
					onChange={handleChange}
					placeholder="Last Name"
				/>
			</div>

			<div className="col-lg-12">
				<label className="text-16 lh-1 fw-500 text-dark-1 mb-8">
					Phone Number
				</label>
				<PhoneInput
					country={"us"}
					placeholder="Phone Number"
					value={trainerInputData.phone}
					onChange={handlePhoneChange}
					enableSearch
					inputClass="external-phone-input-style"
					specialLabel=""
				/>
			</div>

			<div className="col-lg-6">
				<label className="text-16 lh-1 fw-500 text-dark-1 mb-8">
					Business Name <span className="text-red">*</span>
				</label>
				<input
					required
					type="text"
					name="business_name"
					placeholder="Business Name"
					value={trainerInputData.business_name}
					onChange={handleChange}
				/>
			</div>
			<div className="col-lg-6">
				<label className="text-16 lh-1 fw-500 text-dark-1 mb-8">
					Profession Type <span className="text-red">*</span>
				</label>
				<input
					required
					type="text"
					name="designation"
					placeholder="Profession Type"
					value={trainerInputData.designation}
					onChange={handleChange}
				/>
			</div>
			<div className="col-12 mt-20">
				<button
					type="submit"
					name="submit"
					id="submit"
					className={`button -md ${
						loading ? "-blue-3" : "-purple-1"
					} text-white fw-500 w-1/1`}
					disabled={loading}>
					{loading ? "Loading..." : "Next"}
				</button>
			</div>
		</form>
	);

	const secondStepContent = (
		<form
			className="contact-form respondForm__form row y-gap-10 pt-10"
			onSubmit={handleSubmitSecondStep}
			id="Second_Step_Form">
			<div className="col-lg-12">
				<label className="text-16 lh-1 fw-500 text-dark-1 mb-8">
					Street <span className="text-red">*</span>
				</label>
				<input
					required
					type="text"
					name="street"
					placeholder="Street"
					value={trainerInputData.street}
					onChange={handleChange}
				/>
			</div>
			<div className="col-lg-6">
				<label className="text-16 lh-1 fw-500 text-dark-1 mb-8">City</label>
				<Select
					searchable
					styles={{
						control: (baseStyles, state) => ({
							...baseStyles,
							height: "55px",
						}),
					}}
					onChange={(e) => {
						const city = cityList.find((item) => item.id === e.id);
						setTrainerInputData({
							...trainerInputData,
							cityObject: {
								label: city.label,
								value: city.value,
							},
						});
					}}
					options={cityList}
					value={
						trainerInputData.cityObject.value
							? trainerInputData.cityObject
							: null
					}
					placeholder={"Select City"}
					isLoading={cityLoading}
					loadingMessage={() => "Loading. Please wait..."}
				/>
			</div>
			<div className="col-lg-6">
				<label className="text-16 lh-1 fw-500 text-dark-1 mb-8">
					Zip Code <span className="text-red">*</span>
				</label>
				<input
					type="text"
					required
					autoComplete={"tel"}
					name="pin_code"
					placeholder="Zip Code"
					value={trainerInputData.pin_code}
					onChange={handleChange}
					maxLength={7}
					minLength={3}
				/>
			</div>
			<div className="col-lg-6">
				<label className="text-16 lh-1 fw-500 text-dark-1 mb-8">State</label>
				<Select
					searchable
					styles={{
						control: (baseStyles, state) => ({
							...baseStyles,
							height: "55px",
						}),
					}}
					onChange={(e) => {
						setCityLoading(true)
						const state = stateList.find((item) => item.id === e.id);
						setTrainerInputData({
							...trainerInputData,
							stateObject: {
								label: state.label,
								value: state.value,
							},
							cityObject: {
								label: '',
								value: '',
							}
						});
						GetCity(trainerInputData.countryObject.value, state.id).then(
							(result) => {
								const updateResult = result?.length
									? result.map((item) => ({
											...item,
											value: item.id,
											label: item.name,
									  }))
									: [];
								setCityList(updateResult);
								setCityLoading(false);
							}
						);
					}}
					options={stateList}
					value={
						trainerInputData.stateObject.value
							? trainerInputData.stateObject
							: null
					}
					placeholder={"Select State"}
					isLoading={stateLoading}
					loadingMessage={() => "Loading. Please wait..."}
				/>
			</div>
			<div className="col-lg-6">
				<label className="text-16 lh-1 fw-500 text-dark-1 mb-8">
					Country <span className="text-red">*</span>
				</label>
				<Select
					required
					searchable
					styles={{
						control: (baseStyles, state) => ({
							...baseStyles,
							height: "55px",
						}),
					}}
					onChange={(e) => {
						setStateLoading(true);
						setCityLoading(true);
						const country = countriesList.find((item) => item.id === e.id);
						setTrainerInputData({
							...trainerInputData,
							countryObject: {
								label: country.label,
								value: country.value,
							},
							stateObject: {
								label: '',
								value: '',
							},
							cityObject: {
								label: '',
								value: '',
							}
						});
						GetState(country.id).then((result) => {
							const updateResult = result?.length
								? result.map((item) => ({
										...item,
										value: item.id,
										label: item.name,
								  }))
								: [];
							setStateList(updateResult);
							setStateLoading(false);
							setCityLoading(false);
						});
					}}
					options={countriesList}
					value={
						trainerInputData.countryObject.value
							? trainerInputData.countryObject
							: null
					}
					placeholder={"Select Country"}
					isLoading={countryLoading}
					loadingMessage={() => "Loading. Please wait..."}
				/>
			</div>
			<div
				className="col-12 mt-20 d-flex"
				style={{ justifyContent: "space-between" }}>
				<button
					type="button"
					id="button"
					className="button -md h-60 -light-7 -dark-button-dark-2 text-purple-1 col-3"
					onClick={(event) => handlePreviousSteps(event)}
					disabled={loading}>
					{loading ? "Loading..." : "Previous"}
				</button>
				<button
					type="submit"
					name="submit"
					id="submit"
					className="button -md h-60 -purple-1 text-white col-3"
					disabled={loading}>
					{loading ? "Loading..." : "Next"}
				</button>
			</div>
		</form>
	);

	const thirdStepContent = (
		<form
			className="contact-form respondForm__form row y-gap-10 pt-10"
			onSubmit={handleSubmitThirdStep}
			id="Third_Step_Form">
			<div className="col-lg-6">
				<label className="text-16 lh-1 fw-500 text-dark-1 mb-8">
					License Number <span className="text-red">*</span>
				</label>
				<input
					required
					type="text"
					name="licence_number"
					value={trainerInputData.licence_number}
					onChange={handleChange}
					placeholder="License Number"
				/>
			</div>
			<div className="col-lg-6">
				<label className="text-16 lh-1 fw-500 text-dark-1 mb-8">
					License Expiry Date <span className="text-red">*</span>
				</label>
				<br />
				<ConfigProvider
					theme={{
						components: {
							DatePicker: {
								activeBorderColor: "#DDDDDD",
								activeShadow: "none",
							},
						},
					}}>
					<DatePicker
						size={"large"}
						required
						format={{
							format: "YYYY-MM-DD",
							type: "mask",
						}}
						style={{ height: "55px", width: "100%", color: "black" }}
						value={trainerInputData.expiration}
						onChange={(date, dateString) => {
							setTrainerInputData({
								...trainerInputData,
								expiration: date,
								expiration_dateString: dateString,
							});
						}}
					/>
				</ConfigProvider>
			</div>
			{/* License Number Imagge Starts */}
			<div className="col-12">
				<div className="w-1/1">
					<div className="form-upload col-12">
						<label className="text-16 lh-1 fw-500 text-dark-1 mb-10">
							Please provide an image of your professional license for
							verification <span className="text-red">*</span>
						</label>
						<div className="form-upload__wrap">
							<input
								type="text"
								name="licence_number_image"
								disabled
								value={trainerInputData.licence_number_image.name}
								placeholder={"License Image"}
							/>
							<button
								className="button -dark-3 text-white"
								type="button"
								onClick={() =>
									document.getElementById("licenseImageUpload1").click()
								}>
								<label style={{ cursor: "pointer" }}>Upload File</label>
								<input
									id="licenseImageUpload1"
									type="file"
									accept="image/*,.pdf"
									onChange={handleImageChange}
									style={{ display: "none" }}
								/>
							</button>
						</div>
					</div>
					{/* <p className="mt-10">
						Upload your license image here. It must meet our license image
						quality standards to be accepted. Important guidelines: 750x440
						pixels; .jpg, .jpeg,. gif, or .png.
					</p> */}
				</div>
			</div>
			{/* License Number Image Ends */}
			<div className="col-lg-12">
				<label className="text-16 lh-1 fw-500 text-dark-1 mb-8">
					Email address <span className="text-red">*</span>
				</label>
				<input
					required
					type="email"
					name="email"
					value={trainerInputData.email}
					onChange={handleChange}
					placeholder="Email Address"
				/>
			</div>
			<div className="col-lg-6">
				<label className="text-16 lh-1 fw-500 text-dark-1 mb-8">
					Password <span className="text-red">*</span>
				</label>
				<input
					required
					autoComplete="new-password"
					type="password"
					name="password"
					placeholder="Password"
					value={trainerInputData.password}
					onChange={handleChange}
				/>
			</div>
			<div className="col-lg-6">
				<label className="text-16 lh-1 fw-500 text-dark-1 mb-8">
					Confirm Password <span className="text-red">*</span>
				</label>
				<input
					required
					autoComplete="new-password"
					type="password"
					name="confirm_password"
					placeholder="Confirm Password"
					value={trainerInputData.confirm_password}
					onChange={handleChange}
				/>
			</div>
			<div className="col-12">
				<label className="text-16 lh-1 fw-500 text-dark-1 mb-10">
					Terms and Conditions <span className="text-red">*</span>
				</label>
				<div className="col-12 d-flex items-center">
					<div className="form-checkbox">
						<input
							type="checkbox"
							readOnly
							checked={readCondition}
							onChange={() => setReadCondtion(!readCondition)}
						/>
						<div className="form-checkbox__mark">
							<div className="form-checkbox__icon icon-check"></div>
						</div>
					</div>
					<div className="fw-500 ml-12">
						Yes, I agree with the{" "}
						<Link className="text-purple-1" to={"/terms"}>
							Privacy Policy
						</Link>{" "}
						and{" "}
						<Link className="text-purple-1" to={"/terms"}>
							Terms and Conditions
						</Link>
						.
					</div>
				</div>
			</div>

			<div
				className="col-12 mt-20 d-flex"
				style={{ justifyContent: "space-between" }}>
				<button
					type="button"
					id="button"
					className="button -md h-60 -light-7 -dark-button-dark-2 text-purple-1 col-3"
					onClick={(event) => handlePreviousSteps(event)}
					disabled={loading}>
					{loading ? "Loading..." : "Previous"}
				</button>
				<button
					type="submit"
					name="submit"
					id="submit"
					className="button -md h-60 -purple-1 text-white col-3"
					style={{padding:'12px 20px'}}
					disabled={loading}>
					{loading ? "Loading..." : "Sign Up"}
				</button>
			</div>
		</form>
	);

	const stepperSteps = [
		{
			key: 0,
			title: "Basic Information",
			content: firstStepContent,
			icon: <FontAwesomeIcon icon={faIdCard} />,
		},
		{
			key: 1,
			title: "Address",
			content: secondStepContent,
			icon: <FontAwesomeIcon icon={faMapLocationDot} />,
		},
		{
			key: 2,
			title: "Login & License Information",
			content: thirdStepContent,
			icon: <FontAwesomeIcon icon={faUser} />,
		},
	];

	return (
		<div className="form-page__content lg:py-50">
			<div className="container">
				<div className="row justify-center items-center">
					<div className="col-xl-9 col-lg-10">
						<div className="px-50 py-50 md:px-25 md:py-25 bg-white shadow-1 rounded-16">
							<h3 className="text-24 lh-13 text-center">Educator Sign Up</h3>
							<p className="mt-10 mb-10 text-center">
								Already have  an educator account? &nbsp;
								<Link to="/educator-login" className="text-purple-1">
								Login as Educator
								</Link>
							</p>
							<div className="mt-25 mb-25">
								<ConfigProvider
									theme={{
										components: {
											Steps: {
												colorPrimary: "#6440FB",
											},
										},
									}}>
									<Steps
										current={currentStep}
										items={stepperSteps}
										labelPlacement="vertical"
										size="small"
									/>
								</ConfigProvider>
							</div>
							<div style={contentStyle}>
								{stepperSteps[currentStep].content}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
