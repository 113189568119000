import { LinearProgress, Pagination } from "@mui/material";
import { Empty, Spin } from "antd";
import React from "react";
import SuperCoursesCardDashboard from "./SuperCourseCardDashboard";

export const SuperAllCourses = (props) => {
    const {courseObject,handleCheckBoxClicked,handlePageChange} = props;
    const { data,isLoading, meta_data } = courseObject

	

	return (
		<div className="row y-gap-30">
			<div className="col-12">
				{isLoading && <LinearProgress />}
				<div className="rounded-16 bg-white -dark-bg-dark-1 shadow-4 p-20">
					<Spin spinning={isLoading}>
						{data?.length ? (
							<div className="row y-gap-30 pt-30 px-20">
								{data.map((data, i) => (
									<SuperCoursesCardDashboard
										data={data}
										key={i}
										handleCheckBoxClicked={handleCheckBoxClicked}
                                        showRightTopCheckBox={true}
									/>
								))}
							</div>
						) : (
							<Empty description={"Courses are not added yet..."} />
						)}
					</Spin>
				</div>
			</div>
			<div className="row justify-center pt-30 pb-20">
				<div className="col-auto">
					<Pagination
						count={meta_data?.total_pages ?? 0}
						page={meta_data?.current ?? 1}
						onChange={(event, value) => handlePageChange(value)}
						size="large"
						sx={{
							ul: {
								"& .Mui-selected": {
									background: "#6440FB",
									color: "white",
								},
							},
						}}
						disabled={isLoading}
					/>
				</div>
			</div>
		</div>
	);
};
