import { faCloudArrowDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar } from "@mui/material";
import React from "react";

export const LessonOverview = (props) => {
	const { lesson } = props;

	// Check if htmlText contains any non-empty tags
	const containsContent = /<[^/][^>]*>[\s\S]+?<\/[^>]+>/g.test(
		lesson?.video_obj?.description
	);

	return (
		<div id="overview" className="pt-60 pb-60 lg:pt-40 to-over">
			<h4 className="text-24 fw-500">Lesson Summary</h4>
			{!lesson?.video_obj?.key && lesson?.video_obj?.image?.url ? (
				<div className="mt-40">
					<Avatar
						src={lesson?.video_obj?.image?.url ?? ""}
						alt="lesson"
						variant="square"
						sx={{
							height: "250px",
							// width: "100%",
							width: "300px",
							objectFit: "contain",
						}}
					/>
				</div>
			) : null}
			<h4 className="text-18 fw-500 mt-40 mb-20">{lesson?.title ?? ""}</h4>
			{lesson?.video_obj?.attachment?.url ? (
				<a
					className="text-purple-1 mt-20 underline text-18 fw-600"
					href={lesson?.video_obj?.attachment?.url ?? ""}
					target="_blank"
					rel="noopener noreferrer">
					<FontAwesomeIcon icon={faCloudArrowDown} />	Download resource
				</a>
			) : null}

			<div className="pt-20 mt-20">
				{containsContent ? (
					<div
						dangerouslySetInnerHTML={{ __html: lesson?.video_obj?.description }}
					/>
				) : (
					<div className="text-center pt-10">Not available yet...</div>
				)}
			</div>
		</div>
	);
};
