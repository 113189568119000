import { LinearProgress } from "@mui/material";
import { Empty, Spin } from "antd";
import React from "react";
import SuperCoursesCardDashboard from "./SuperCourseCardDashboard";

export const SuperOnlyPopularCourses = (props) => {
    const {courseObject,handleCheckBoxClicked,handleMoveIconClicked} = props;
    const { data,isLoading } = courseObject

	return (
		<div className="row y-gap-30">
			<div className="col-12">
				{isLoading && <LinearProgress />}
				<div className="rounded-16 bg-white -dark-bg-dark-1 shadow-4 p-20">
					<Spin spinning={isLoading}>
						{data?.length ? (
							<div className="row y-gap-30 pt-30 px-20">
								{data.map((singleData, i) => (
									<SuperCoursesCardDashboard
										data={singleData}
										key={i}
										handleCheckBoxClicked={handleCheckBoxClicked}
                                        showRightTopCheckBox={true}
										showMoveLeftRightButtons
										isFirst={i===0}
										isLast={i===data.length - 1}
										handleMoveIconClicked={handleMoveIconClicked}
									/>
								))}
							</div>
						) : (
							<Empty description={"Courses are not added yet..."} />
						)}
					</Spin>
				</div>
			</div>
		</div>
	);
};
