import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
	getTrainerDetailsAction,
	updateTrainerDetailsAction,
} from "../../../../../../redux/action/trainerActions/trainerActions";
import { useSnackbar } from "notistack";

export default function EditSocialProfile({ activeTab }) {
	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();
	const [loading, setLoading] = useState(false);
	const { data: instructorDetails, isLoading } = useSelector(
		(state) => state.trainers.singleTrainer
	);

	const { register, handleSubmit, setValue, control } = useForm({
		defaultValues: {
			instagram_link: "",
			linkedin_link: "",
			twitter_link: "",
			facebook_link: "",
			website_link: "",
			youtube_channel_link: ""
		},
	});

	const instagram = useWatch({ control, name: "instagram_link" });
	const linkedIn = useWatch({ control, name: "linkedin_link" });
	const twitter = useWatch({ control, name: "twitter_link" });
	const facebook = useWatch({ control, name: "facebook_link" });
	const youtube = useWatch({ control, name: "youtube_channel_link" });
	const website = useWatch({ control, name: "website_link" });

	useEffect(() => {
		setValue("instagram_link", instructorDetails?.instagram_link ?? "");
		setValue("linkedin_link", instructorDetails?.linkedin_link ?? "");
		setValue("twitter_link", instructorDetails?.twitter_link ?? "");
		setValue("facebook_link", instructorDetails?.facebook_link ?? "");
		setValue("youtube_channel_link", instructorDetails?.youtube_channel_link ?? "");
		setValue("website_link", instructorDetails?.website_link ?? "");
	}, [instructorDetails, setValue]);

	const onSubmitBasicDetails = (e) => {
		setLoading(true);
		let body = {
			instagram_link: e?.instagram_link ? e.instagram_link : null,
			linkedin_link: e?.linkedin_link ? e.linkedin_link : null,
			twitter_link: e?.twitter_link ? e.twitter_link : null,
			facebook_link: e?.facebook_link ? e.facebook_link : null,
			youtube_channel_link: e?.youtube_channel_link ? e.youtube_channel_link : null,
			website_link: e?.website_link ? e.website_link : null,
		};
		dispatch(
			updateTrainerDetailsAction(body, instructorDetails.slug_name, {
				onSuccess: (success) => {
					setLoading(false);
					enqueueSnackbar(success.message[0], { variant: "success" });
					let newData = {
						...instructorDetails,
						...success.data.user,
						slug_name: success.data.slug_name,
					};
					localStorage.setItem("loggedIn_user", JSON.stringify(newData));
					dispatch(getTrainerDetailsAction(success.data.slug_name));
				},
				onError: (error) => {
					setLoading(false);
					let errorMessage = String(error?.message ?? 'Something went wrong');
					enqueueSnackbar(errorMessage, { variant: "error" });
				},
			})
		);
	};

	return (
		<div
			className={`tabs__pane -tab-item-2 ${
				activeTab === 2 ? "is-active" : ""
			} `}>
			<form
				onSubmit={handleSubmit(onSubmitBasicDetails)}
				className="contact-form row y-gap-30">
				<div className="col-md-6">
					<label className="text-16 lh-1 fw-500 text-dark-1 mb-10">
						Twitter Profile URL
					</label>

					<input
						{...register("twitter_link")}
						type="text"
						placeholder="Twitter Profile URL"
					/>
				</div>

				<div className="col-md-6">
					<label className="text-16 lh-1 fw-500 text-dark-1 mb-10">
						Facebook Profile URL
					</label>

					<input
						{...register("facebook_link")}
						type="text"
						placeholder="Facebook Profile URL"
					/>
				</div>

				<div className="col-md-6">
					<label className="text-16 lh-1 fw-500 text-dark-1 mb-10">
						Instagram Profile URL
					</label>

					<input
						{...register("instagram_link")}
						type="text"
						placeholder="Instagram Profile URL"
					/>
				</div>

				<div className="col-md-6">
					<label className="text-16 lh-1 fw-500 text-dark-1 mb-10">
						LinkedIn Profile URL
					</label>

					<input
						{...register("linkedin_link")}
						type="text"
						placeholder="LinkedIn Profile URL"
					/>
				</div>

				<div className="col-md-6">
					<label className="text-16 lh-1 fw-500 text-dark-1 mb-10">
						Youtube Profile URL
					</label>

					<input
						{...register("youtube_channel_link")}
						type="text"
						placeholder="Youtube Profile URL"
					/>
				</div>

				<div className="col-md-6">
					<label className="text-16 lh-1 fw-500 text-dark-1 mb-10">
						Website Profile URL
					</label>

					<input
						{...register("website_link")}
						type="text"
						placeholder="Website Profile URL"
					/>
				</div>

				<div className="col-12">
					{instagram.trim().length ||
					twitter.trim().length ||
					facebook.trim().length ||
					youtube.trim().length ||
					website.trim().length ||
					linkedIn.trim().length ? (
						<Button
							className="button -md -purple-1 text-white"
							style={{ textTransform: "inherit" }}
							type="submit"
							onClick={handleSubmit(onSubmitBasicDetails)}
							disabled={isLoading || loading}>
							Save Social Profile
						</Button>
					) : (
						<span className="text-purple-1">Please fill fields to update</span>
					)}
				</div>
			</form>
		</div>
	);
}
