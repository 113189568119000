import React from "react";
import { COMPANY_NAME } from "../../../config/config";
import MetaComponent from "../../../components/common/MetaComponent";
import Preloader from "../../../components/common/Preloader";
import Header from "../../../components/layout/headers/Header";
import Footer from "../../../components/layout/footers/Footer";

const metadata = {
	title: `Terms || ${COMPANY_NAME}`,
	description: `Elevate your e-learning content with ${COMPANY_NAME}, the most impressive LMS template for online courses, education and LMS platforms.`,
};
export default function TermsAndCondition() {
	return (
		<div className="main-content  ">
			<MetaComponent meta={metadata} />
			<Preloader />

			<Header />
			<div className="content-wrapper js-content-wrapper overflow-hidden">
				<>
					<section className="page-header -type-1 mt-90">
						<div className="container">
							<div className="page-header__content">
								<div className="row justify-center text-center">
									<div className="col-auto">
										<div>
											<h1 className="page-header__title">Terms & Conditions</h1>
										</div>

										{/* <div>
                                            <p className="page-header__text">
                                                We’re on a mission to deliver engaging, curated courses at a
                                                reasonable price.
                                            </p>
                                        </div> */}
									</div>
								</div>
							</div>
						</div>
					</section>
					<section className="layout-pt-md layout-pb-lg">
						<div className="container">
							<div className="row justify-center">
								<div className="col-xl-8 col-lg-9 col-md-11">
									<p>
										Welcome to Pro Beauty Port ("Pro Beauty Port", "we", "us",
										or "our"), your one-stop platform for professional beauty
										education! These Terms and Conditions ("Terms") outline the
										rules and regulations for using our website (the "Service")
										and the courses offered by our educators ("Educators").
									</p>

									<p className="mt-10 mb-30">
										By accessing or using the Service, you agree to be bound by
										these Terms. If you disagree with any part of the Terms, you
										may not access or use the Service.
									</p>
								</div>
								<div className="col-xl-8 col-lg-9 col-md-11">
									<h5>1. Content Ownership</h5>
									<div className="mt-30">
										<ul>
											<li className="custom-list">
												<span style={{ fontWeight: "bold" }}>
													Pro Beauty Port Content:
												</span>{" "}
												The content on the Service, including text, graphics,
												images, video, audio, trademarks, logos, and other
												materials (collectively, the "Pro Beauty Port Content")
												is our property or that of our licensors and is
												protected by copyright, trademark, and other
												intellectual property laws under the United States Code
												(the "U.S.C.").
											</li>
											<br />
											<li className="custom-list">
												<span style={{ fontWeight: "bold" }}>
													Educator Content:
												</span>{" "}
												Educators retain ownership of the content within their
												courses (the "Educator Content"). Educators warrant that
												they have all necessary rights and licenses to upload
												their Educator Content to the Service.
											</li>
										</ul>
									</div>
								</div>

								<div className="col-xl-8 col-lg-9 col-md-11">
									<div className="mt-60 lg:mt-40">
										<h5>2. User Accounts</h5>
										<div className="mt-30">
											<ul>
												<li className="custom-list">
													You may be required to create an account to access
													certain features of the Service. You are responsible
													for maintaining the confidentiality of your account
													information, including your password, and for all
													activity that occurs under your account.
												</li>
												<br />
												<li className="custom-list">
													You agree to notify us immediately of any unauthorized
													use of your account or any other security breach.
												</li>
												<br />
												<li className="custom-list">
													We reserve the right to terminate or suspend your
													account at any time without notice for any violation
													of these Terms.
												</li>
											</ul>
										</div>
									</div>
								</div>

								<div className="col-xl-8 col-lg-9 col-md-11">
									<div className="mt-60 lg:mt-40">
										<h5>3. Use of the Service</h5>
										<div className="mt-30">
											<ul>
												<li className="custom-list">
													You agree to use the Service only for lawful purposes
													and in accordance with these Terms.
												</li>
												<br />
												<li className="custom-list">
													You agree not to use the Service:
													<br />
													<ul>
														<li className="custom-list-nested">
															In any way that violates any applicable U.S. law
															or regulation.
														</li>
														<br />
														<li className="custom-list-nested">
															For the purpose of exploiting, harming, or
															threatening others.
														</li>
														<br />
														<li className="custom-list-nested">
															To transmit, or procure the transmission of, any
															material that is harassing, obscene, defamatory,
															or otherwise unlawful.
														</li>
														<br />
														<li className="custom-list-nested">
															To interfere with or disrupt the Service or
															servers or networks connected to the Service.
														</li>
														<br />
														<li className="custom-list-nested">
															To impersonate any person or entity.
														</li>
														<br />
														<li className="custom-list-nested">
															To attempt to gain unauthorized access to the
															Service, other accounts, computer systems, or
															networks connected to the Service, through
															hacking, password mining, or other means.
														</li>
														<br />
														<li className="custom-list-nested">
															To download or copy any Pro Beauty Port Content or
															Educator Content other than for your personal,
															non-commercial use.
														</li>
														<br />
														<li className="custom-list-nested">
															To share your course access with anyone who has
															not purchased the course.
														</li>
													</ul>
												</li>
											</ul>
										</div>
									</div>
								</div>

								<div className="col-xl-8 col-lg-9 col-md-11">
									<div className="mt-60 lg:mt-40">
										<h5>4. Educator Uploads</h5>
										<div className="mt-30">
											<ul>
												<li className="custom-list">
													Educators are solely responsible for the content of
													their courses.
												</li>
												<br />
												<li className="custom-list">
													Educators agree to:
													<br />
													<ul>
														<li className="custom-list-nested">
															Comply with all applicable U.S. laws and
															regulations regarding the content of their
															courses.
														</li>
														<br />
														<li className="custom-list-nested">
															Ensure their courses are accurate, up-to-date, and
															of high quality.
														</li>
														<br />
														<li className="custom-list-nested">
															Clearly disclose any potential conflicts of
															interest related to their courses.
														</li>
													</ul>
												</li>
											</ul>
										</div>
									</div>
								</div>

								<div className="col-xl-8 col-lg-9 col-md-11">
									<div className="mt-60 lg:mt-40">
										<h5>5. Student Conduct</h5>
										<div className="mt-30">
											<ul>
												<li className="custom-list">
													Students are responsible for using the Service in
													accordance with these Terms.
												</li>
												<br />
												<li className="custom-list">
													Students agree not to:
													<br />
													<ul>
														<li className="custom-list-nested">
															Share course materials with anyone who has not
															purchased the course.
														</li>
														<br />
														<li className="custom-list-nested">
															Record or distribute course materials without the
															express written permission of the Educator.
														</li>
													</ul>
												</li>
											</ul>
										</div>
									</div>
								</div>

								<div className="col-xl-8 col-lg-9 col-md-11">
									<div className="mt-60 lg:mt-40">
										<h5>6. Disclaimer</h5>
										<div className="mt-30">
											<ul>
												<li className="custom-list">
													The Service and Educator Content are provided on an
													"as is" and "as available" basis. We make no
													representations or warranties of any kind, express or
													implied, as to the operation of the Service, the
													Educator Content, or the results you may achieve from
													using the Service.
												</li>
												<br />
												<li className="custom-list">
													We do not warrant that the Service will be
													uninterrupted or error-free.
												</li>
												<br />
												<li className="custom-list">
													We will not be liable for any damages arising from the
													use of the Service or Educator Content, including but
													not limited to direct, indirect, incidental,
													consequential, or punitive damages.
												</li>
											</ul>
										</div>
									</div>
								</div>

								<div className="col-xl-8 col-lg-9 col-md-11">
									<div className="mt-60 lg:mt-40">
										<h5>7. Limitation of Liability</h5>
										<div className="mt-30">
											<ul>
												<li className="custom-list">
													To the extent permitted by applicable U.S. law, we
													shall not be liable for any damages arising from or
													related to your use of the Service or Educator
													Content, including but not limited to personal injury,
													property damage, loss of data, or loss of profits.
												</li>
											</ul>
										</div>
									</div>
								</div>

								<div className="col-xl-8 col-lg-9 col-md-11">
									<div className="mt-60 lg:mt-40">
										<h5>8. Termination</h5>
										<div className="mt-30">
											<ul>
												<li className="custom-list">
													We may terminate or suspend your access to the Service
													at any time, without notice or reason.
												</li>
												<br />
												<li className="custom-list">
													Educators may remove their courses from the Service at
													any time.
												</li>
											</ul>
										</div>
									</div>
								</div>

								<div className="col-xl-8 col-lg-9 col-md-11">
									<div className="mt-60 lg:mt-40">
										<h5>9. Governing Law</h5>
										<div className="mt-30">
											<ul>
												<li className="custom-list">
													These Terms and Conditions shall be governed and
													construed in accordance with the laws of the United
													States of America and the State of [Your State],
													without regard to its conflict of law provisions.
												</li>
											</ul>
										</div>
									</div>
								</div>

								<div className="col-xl-8 col-lg-9 col-md-11">
									<div className="mt-60 lg:mt-40">
										<h5>10. Dispute Resolution</h5>
										<div className="mt-30">
											<ul>
												<li className="custom-list">
													Any dispute arising out of or relating to these Terms
													and Conditions shall be resolved by binding
													arbitration in accordance with the rules of the
													American Arbitration Association ("AAA").
												</li>
											</ul>
										</div>
									</div>
								</div>

								<div className="col-xl-8 col-lg-9 col-md-11">
									<div className="mt-60 lg:mt-40">
										<h5>11. Entire Agreement</h5>
										<div className="mt-30">
											<ul>
												<li className="custom-list">
													These Terms and Conditions constitute the entire
													agreement between you and us regarding your use of the
													Service.
												</li>
											</ul>
										</div>
									</div>
								</div>

								<div className="col-xl-8 col-lg-9 col-md-11">
									<div className="mt-60 lg:mt-40">
										<h5>12. Changes to the Terms and Conditions</h5>
										<div className="mt-30">
											<ul>
												<li className="custom-list">
													We may update these Terms and Conditions at any time.
													We will notify you of any changes by posting the new
													Terms on the Service. You are advised to periodically
													review these Terms for any changes.
												</li>
											</ul>
										</div>
									</div>
								</div>

								<div className="col-xl-8 col-lg-9 col-md-11">
									<div className="mt-60 lg:mt-40">
										<h5>13. Contact Us</h5>
										<div className="mt-30">
											<ul>
												<li className="custom-list">
													If you have any questions about these Terms and
													Conditions, please contact us at
													Info@probeautyport.com
												</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
				</>
				<Footer />
			</div>
		</div>
	);
}
