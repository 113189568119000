import { combineReducers } from 'redux';
import coursesReducers from "./coursesReducers";
import testimonialsReducers from "./testimonialsReducers";
import trainersReducers from "./trainerReducers";
import superAdminReducers from './superAdminReducers'
import myDashboardReducers from './myDashboardReducers';
import cartReducers from './cartReducers';
import customerReducers from './customerReducers';
import billingReducers from './billingReducers';

const rootReducer = combineReducers({
    courses: coursesReducers,
    testimonials: testimonialsReducers,
    trainers: trainersReducers,
    superAdmin: superAdminReducers,
    myDashboard : myDashboardReducers,
    cart: cartReducers,
    customers: customerReducers,
    billing: billingReducers
});

export default rootReducer;
