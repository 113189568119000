export const COMPANY_NAME = 'Pro Beauty Port';

// --------- This BASE URL is for PRODUCTION ACCOUNT --------------------------
export const BASE_URL = 'https://thebeautyhub.pythonanywhere.com/api/'
// ----------------------------------------------------------------------------


// ---------- This BASE URL is for DEVELOPMENT  -----------------------------
// export const BASE_URL = 'https://beautyporttestenv.pythonanywhere.com/api/'
// --------------------------------------------------------------------------

export const IMAGE_FILE_URL = 'https://educrat-react.vercel.app'

export const BUYER_ROLE_TYPE = "BUYER"
export const SELLER_ROLE_TYPE = "SELLER"

export const PROFILE_AWS_STARTING_PATH = "profile/"
export const COURSE_AWS_STARTING_PATH = "courses/"
export const COURSE_THUMBNAIL_IMAGE_AWS_STARTING_PATH = "courses/thumbnail/images/"
export const COURSE_THUMBNAIL_VIDEO_AWS_STARTING_PATH = "courses/thumbnail/videos/"
export const LESSON_AWS_STARTING_PATH = "courses/chapters/lessons/"
export const LESSON_VIDEO_AWS_STARTING_PATH = "courses/chapters/lessons/videos/"
export const LESSON_IMAGE_AWS_STARTING_PATH = "courses/chapters/lessons/images/"
export const LESSON_ATTACHMENT_AWS_STARTING_PATH = "courses/chapters/lessons/attachments/"

export const TRAINER_LICENSE_IMAGE_PATH = "seller/licence_number_img/"

export const SUPER_CATEGORIES_IMAGE_LOCATION = "super_admin/categories/images/"
export const SUPER_TESTIMONIALS_PROFILE_IMAGE_LOCATION = "super_admin/testimonials/profile/"

// ---------------------- Stripe key is for developer account -------------------------------------------------------
// export const Stripe_PUBLIC_API_KEY = 'pk_test_51OiypOEsJKzMuq5gPbhhNT5imDC5z4wKDTFZLaWrXzi42twUawuZLlbDAYouQqLOT0GUmkTdvkVh84BjXpfOw8Xx00ICQVV85g'
// -----------------------------------------------------------------------------------------------------------------

// ----------------------- Stripe key is for production account -----------------------------------------------------
export const Stripe_PUBLIC_API_KEY = 'pk_live_51OiypOEsJKzMuq5gTf7Iu1t1MqiiRgpKFxtPn0llr8DkuztxbpECyN1bWsBMw2C9sx6JCU03UXraZgFVrd8Wc8UQ00WnQmCxaA'
// ------------------------------------------------------------------------------------------------------------------

// export const temp_user_Email_dev = 'sitag27106@brinkc.com'
// capacitor id - com.proBeautyPort.app
// initial node version - 16.8.0 and npm version - 7.21.0