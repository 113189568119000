import React from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Send } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { LinearProgress } from "@mui/material";
import { useLocation } from "react-router-dom";

const VisuallyHiddenInput = styled("input")({
	clip: "rect(0 0 0 0)",
	clipPath: "inset(50%)",
	height: 1,
	overflow: "hidden",
	position: "absolute",
	bottom: 0,
	left: 0,
	whiteSpace: "nowrap",
	width: 1,
});

export default function UploadCourseThumbnail(props) {
	const {
		courseThumbnailImage,
		setCourseThumbnailImage,
		courseThumbnailVideo,
		setCourseThumbnailVideo,
		courseThumbnailImageURL,
		setCourseThumbnailImageURL,
		courseThumbnailVideoURL,
		setCourseThumbnailVideoURL,
		handleUploadFiles,
		loading,
		uploadedPath,
		setUploadedPath,
		isImagePresent,
		isVideoPresent,
		setFinalThumbnailStatus,
		handleImageDelete,
		handleVideoDelete
	} = props;

	const location = useLocation()

	const isPathIsUpdated = location.pathname.includes('update-course')

	const handleImageChange = (event) => {
		if (event.target.files[0]) {
			setCourseThumbnailImage(event.target.files[0]);
			const imageUrl = URL.createObjectURL(event.target.files[0]);
			setCourseThumbnailImageURL(imageUrl);
			setUploadedPath(prevState => ({
				...prevState,
				image: "",
			}));
			setFinalThumbnailStatus((prevState) => ({
				...prevState,
				image: true,
			}));
		}
	};

	const handleVideoChange = (event) => {
		if (event.target.files[0]) {
			setCourseThumbnailVideo(event.target.files[0]);
			const videoUrl = URL.createObjectURL(event.target.files[0]);
			setCourseThumbnailVideoURL(videoUrl);
			setUploadedPath(prevState => ({
				...prevState,
				video: "",
			}));
			setFinalThumbnailStatus((prevState) => ({
				...prevState,
				video: true,
			}));
		}
	};

	const disableUploadButton = ()=>{
		if(!isPathIsUpdated){
			// return (!(courseThumbnailImageURL && courseThumbnailVideoURL))
			return !courseThumbnailImageURL
		}else{	
			if(isImagePresent && isVideoPresent){
				return (isImagePresent && isVideoPresent)
			}else{
				return !courseThumbnailImageURL
				// return (!(courseThumbnailImageURL && courseThumbnailVideoURL))
			}
		}
	}
	return (
		<div className="col-12">
			<div className="rounded-16 bg-white -dark-bg-dark-1 shadow-4 h-100">
				<div className="d-flex items-center py-20 px-30 border-bottom-light">
					<h2 className="text-17 lh-1 fw-500">Upload Media</h2>
				</div>

				<div className="py-10 px-30">
					<div className="row  y-gap-20">
						{/* Upload Image Starts */}
						<div className="col-md-12 col-sm-12">
							Thumbnail Image<span className="text-red">*</span>&nbsp;( Recommended Image Ratio : 400px * 350px )
							<div
								className="relative shrink-0 pt-10"
								style={
									courseThumbnailImage
										? {}
										: {
												backgroundColor: "#f2f3f4",
												// width: 250,
												// height: 200,
												width: 350,
												height: 200,
										  }
								}>
								{courseThumbnailImageURL && (
									<div style={{ position: "relative", display: "inline-block" }}>
										<img
											key={courseThumbnailImageURL}
											className="w-1/1"
											style={{
												// maxWidth: "250px",
												maxWidth: "350px",
												height: "200px",
												maxHeight: "200px",
												objectFit: "cover",
												objectPosition: "center",
											}}
											src={courseThumbnailImageURL}
											alt="thumbnail"
										/>
										<div className="absolute-full-center d-flex justify-end py-20 px-20">
											<span
												style={{ cursor: "pointer" }}
												onClick={() => handleImageDelete()}
												className="d-flex justify-center items-center bg-white size-40 rounded-8 shadow-1">
												<i className="icon-bin text-16"></i>
											</span>
										</div>
										{loading ? (
											<LinearProgress style={{ maxWidth: "350px" }} />
										) : (
											uploadedPath.image && (
												<LinearProgress
													color="success"
													variant="determinate"
													value={100}
													style={{ maxWidth: "350px" }}
												/>
											)
										)}
									</div>
								)}
							</div>
							<div className="pt-20 items-center">
								<Button
									component="label"
									role={undefined}
									tabIndex={-1}
									className="button h-50 px-25 -outline-dark-1 text-dark-1"
									startIcon={<CloudUploadIcon />}>
									Choose Image
									<VisuallyHiddenInput
										type="file"
										accept="image/*"
										onChange={handleImageChange}
										id="imageUpload1"
									/>
								</Button>
							</div>
						</div>
						{/* Upload Image ends */}

						{/* Upload Video Starts */}
						<div className="col-md-12 col-sm-12">
							Thumbnail Video ( Recommended Resolution : 1920 * 1080 )
							<div
								className="relative shrink-0 pt-10"
								style={
									courseThumbnailVideo
										? {}
										: {
												backgroundColor: "#f2f3f4",
												// width: 250,
												// height: 200,
												width: 350,
												height: 200,
										  }
								}>
								{courseThumbnailVideoURL && (
									<div style={{ position: "relative", display: "inline-block" }}>
										<video
											key={courseThumbnailVideoURL}
											className="w-1/1"
											style={{
												// maxWidth: "250px",
												maxWidth: "350px",
												height: "200px",
												maxHeight: "200px",
												objectFit: "cover",
											}}
											controls>
											<source src={courseThumbnailVideoURL} type="video/mp4" />
											Your browser does not support the video tag.
										</video>
										<div className="absolute-full-center d-flex justify-end py-20 px-20">
											<span
												style={{ cursor: "pointer" }}
												onClick={() => handleVideoDelete()}
												className="d-flex justify-center items-center bg-white size-40 rounded-8 shadow-1">
												<i className="icon-bin text-16"></i>
											</span>
										</div>
										{loading ? (
											<LinearProgress style={{ maxWidth: "350px" }} />
										) : (
											uploadedPath.video && (
												<LinearProgress
													color="success"
													variant="determinate"
													value={100}
													style={{ maxWidth: "350px" }}
												/>
											)
										)}
									</div>
								)}
							</div>
							<div className="pt-20 items-center">
								<Button
									component="label"
									role={undefined}
									tabIndex={-1}
									className="button h-50 px-25 -outline-dark-1 text-dark-1"
									startIcon={<CloudUploadIcon />}>
									Choose Video
									<VisuallyHiddenInput
										type="file"
										accept="video/*"
										onChange={handleVideoChange}
										id="videoUpload1"
									/>
								</Button>
							</div>
						</div>
						{/* Upload Video ends */}

						{/* Upload Files Button starts */}
						<div className="col-md-12 d-flex justify-content-end">
							<div>
								<LoadingButton
									variant="contained"
									disabled={disableUploadButton()}
									color="success"
									component="label"
									endIcon={<Send />}
									className={`button h-50 px-25 -dark-1 -dark-button-white text-dark`}
									onClick={() => handleUploadFiles()}
									loading={loading}>
									Upload Files
								</LoadingButton>
							</div>
						</div>
						{/* Upload Files Button ends */}
					</div>
				</div>
			</div>
		</div>
	);
}
