import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {Provider} from 'react-redux';
import reportWebVitals from './reportWebVitals';
import store from "./redux/store";
import {SnackbarProvider} from 'notistack';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <SnackbarProvider maxSnack={1} autoHideDuration={2000} anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}>
            <App/>
        </SnackbarProvider>
    </Provider>
);

reportWebVitals();
