export const TESTIMONIAL_DEFAULT_TEMPLATE = {
    id: 0,
    imageSrc: "",
    name: "",
    position: "",
    comment: "",
    description:""
}

export const audioLanguageArray = [
	{ value: "1", label: "English" },
	{ value: "2", label: "Hindi" },
	{ value: "3", label: "French" },
	{ value: "4", label: "Greek" },
];

export const courseLevelArray = [
	{ value: "BEGINNER", label: "Beginner" },
	{ value: "INTERMEDIATE", label: "Intermediate" },
	{ value: "EXPERT", label: "Expert" },
];

export const levels = [
	{ id: "BEGINNER", title: "Beginner",value: "BEGINNER" },
	{ id: "INTERMEDIATE", title: "Intermediate",value: "INTERMEDIATE" },
	{ id: "EXPERT", title: "Expert",value: "EXPERT" },
];

export const prices = [
	{ id: 1, title: "All", value: "1" },
	{ id: 2, title: "Free", value: "2" },
	{ id: 3, title: "Paid", value: "3" },
];

export const ratingList = [
	{ id: 1, star: 5, text: "4.5 & up", range: [4.5, 5] , value : '4.5' },
	{ id: 2, star: 5, text: "4.0 & up", range: [4, 5] , value : '4.0'  },
	{ id: 3, star: 5, text: "3.5 & up", range: [3.5, 5] , value : '3.5'  },
	{ id: 4, star: 5, text: "3.0 & up", range: [3, 5] , value : '3.0'  },
];

export const ratingListWithAll = [
    { id: 0, star: 5, text: "All", range: [] , value : '0' },
	{ id: 1, star: 5, text: "4.5 & up", range: [4.5, 5] , value : '4.5' },
	{ id: 2, star: 5, text: "4.0 & up", range: [4, 5] , value : '4.0'  },
	{ id: 3, star: 5, text: "3.5 & up", range: [3.5, 5] , value : '3.5'  },
	{ id: 4, star: 5, text: "3.0 & up", range: [3, 5] , value : '3.0'  },
];

export const durations = [
    { id: 1, title: "Less than 4 hours", range: [0, 239] , value: '4'},
    { id: 2, title: "4 - 7 hours", range: [240, 419] , value: '5'},
    { id: 3, title: "7 - 18 hours", range: [420, 1079], value: '6' },
    { id: 4, title: "20 + Hours", range: [1080, 5000], value: '7' },
  ];