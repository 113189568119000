import React, {useCallback, useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid } from "@mui/x-data-grid";
import { Typography } from "@mui/material";
import moment from "moment/moment";
import { getSuperContactUsListData } from "../../../../redux/action/superAdminActions/superContactUsListActions";

export default function SuperContactUs() {

    const dispatch = useDispatch()

    const fetchContactUs = useCallback(() => {
		dispatch(getSuperContactUsListData());
	}, [dispatch]);

	useEffect(() => {
		fetchContactUs();
	}, [fetchContactUs]);

    useEffect(() => {
        if (!localStorage.getItem('beauty_super_key')) {
            window.location.replace('login')
        }
    }, [])

    const {
		data: ContactUsList,
		isLoading,
        meta_data
	} = useSelector((state) => state.superAdmin.superContactUsList);

    const handlePageChange =()=>{
        console.log("page changed")
    }

    const columns = [
		{
			field: "name",
			width: 100,
			sortable: false,
			flex: 0.75,
			renderHeader: () => <Typography variant="h6">{"Name"}</Typography>,
			renderCell: (params) => {
				return (
					<div>
						<b>{params?.row?.name ?? '--'}</b>
					</div>
				);
			},
		},
		{
			field: "email",
			width: 100,
			sortable: false,
			flex: 0.75,
			renderHeader: () => <Typography variant="h6">{"Email"}</Typography>,
			renderCell: (params) => {
				return (
					<div>
						{params?.row?.email ?? '--'}
					</div>
				);
			},
		},
		{
			field: "message",
			width: 200,
			sortable: false,
			flex: 1,
			renderHeader: () => <Typography variant="h6">{"Message"}</Typography>,
			renderCell: (params) => {
				return (
					<div
						style={{
							maxHeight: '100px',  
							overflowY: 'auto',    
							whiteSpace: 'normal', 
							wordBreak: 'break-word' 
						}}
					>
						<b>{params?.row?.message ?? '--'}</b>
					</div>
				);
			},
		},
        {
			field: "created_at",
			width: 50,
			sortable: false,
			flex: 0.5,
			renderHeader: () => <Typography variant="h6">{"Created At"}</Typography>,
			renderCell: (params) => {
				return (
					<div>
						{moment(params?.row?.created_at).format("DD-MM-YYYY") ?? '--'}
					</div>
				);
			},
		},
		// {
		// 	field: "actions",
		// 	renderHeader: () => <Typography variant="h6">{"Actions"}</Typography>,
		// 	sortable: false,
		// 	renderCell: (params) => {
		// 		return (
		// 			<div className="d-flex justify-content-end">
		// 				<div
		// 					className="icon-edit"
		// 					style={{ cursor: "pointer" }}
		// 					onClick={() => {
		// 						setSelectedCategory(params.row)
		// 						setOpenEditForm(true)
		// 					}}></div>
		// 				&emsp;
		// 				<Popconfirm
		// 					title="Are you sure to delete this category?"
		// 					description={
		// 						<div>
		// 							<p>All the related details will be deleted.</p>
		// 							<span>Sub Categories:</span>&nbsp;<b>{params?.row?.sub_category?.length ?? 0}</b><br/>
		// 							<span>Courses:</span>&nbsp;<b>{params?.row?.available_course_count ?? 0}</b>
		// 						</div>
		// 					}
		// 					okText="Yes"
		// 					cancelText="No"
		// 					onConfirm={() => handleDelete(params.row.id)}>
		// 					<div className="icon-bin" style={{ cursor: "pointer" }}></div>
		// 				</Popconfirm>
		// 			</div>
		// 		);
		// 	},
		// },
	];

    return (
		<div className="dashboard__main">
			<div className="dashboard__content bg-light-4">
				<div className="row pb-30">
					<div className="col-auto">
						<h1 className="text-20 lh-12 fw-500">Contact Us list</h1>
						<div className="mt-1">Welcome to the Contact Us List Section.</div>
					</div>
					<div className="col-auto ms-auto">
						{/* <Button
                            className="button py-10 px-10 -dark-1 text-white -dark-button-white col-12"
							startIcon={<Add />}
							onClick={() => setOpenAddForm(true)}>
							Add Category
						</Button> */}
					</div>
				</div>

				{/*Table Starts here */}
				<div
					style={{
						width: "100%",
						overflowY: "scroll",
						height: "calc(100vh - 260px)",
					}}>
					<DataGrid
						rows={ContactUsList}
						columns={columns}
                        rowHeight={100}
						initialState={{
						    pagination: {
						        paginationModel: {page: meta_data.current, pageSize: 100},
						    },
						}}
                        onPaginationModelChange={()=>handlePageChange()}
						// pageSizeOptions={[5, 10]}
                        checkboxSelection
                        showCellVerticalBorder
						disableColumnMenu
						disableRowSelectionOnClick={true}
						disableColumnSelector
						disableFocus
						loading={isLoading}
					/>
				</div>
			</div>
			{/*<FooterNine />*/}
		</div>
	);
}
