import Axios from "axios";
import { onResponseError } from "../../../function/Interceptor";
import { BASE_URL } from "../../../config/config";

export function getCheckOutLinkActions(body,actionResponse = {}) {
    const {onSuccess, onError, onLoading} = actionResponse;
    return async (dispatch) => {
        onLoading?.(true)
        try {
            let config = {
                headers: {} // Initialize headers object
            };
            if (localStorage.getItem("beauty_key")) {
                config.headers.Authorization = "Bearer " + localStorage.getItem("beauty_key");
            }
            // let body ={
            //     products : products
            // }
            let response = await Axios.post(`${BASE_URL}courses/getCourseCheckoutLink`, body,config);
            onSuccess?.(response?.data)
           
        } catch (error) {
            onError?.(error)
            onResponseError(error?.response?.data)
        }
    };
}