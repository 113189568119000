import React, {useEffect} from 'react';
import "./styles/index.scss";
import "@fortawesome/fontawesome-svg-core/styles.css";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import ScrollTopBehaviour from "./components/common/ScrollTopBehaviour";
import HomePage from "./pages/homepage/HomePage";
import CustomerLoginPage from "./pages/others/login/customerLogin";
// import CustomerSignUp from "./pages/others/signup/customerSignUp";
import ContactUs from "./pages/contactUs/ContactUs";
import PageNotFound from "./pages/others/PageNotFound";
import HelpCenterPage from "./pages/others/help-center/HelpAndSupport";
import TermsAndCondition from "./pages/others/terms/TermsAndCondition";
import TrainerMemberShipPricing from "./pages/others/memberShip/TrainerMemberShipPricing";
import BecomeAnInstructor from "./pages/others/instructor/BecomeAnInstructor";
import AboutUs from "./pages/aboutUs/AboutUs";
import InstructorsList from "./pages/others/instructor/InstructorsList";
import InstructorDetails from "./pages/others/instructor/InstructorDetails";
import SuperAdmin from "./superAdmin/SuperAdmin";
import MyDashboard from './pages/myDashboard/MyDashboard';
import CustomerCourses from './pages/myDashboard/dashboard/pages/customer/customerCourses/CustomerCourses';
import CustomerCoursesDetail from './pages/myDashboard/dashboard/pages/customer/customerCourses/CustomerCoursesDetail';
import CourseCartPage from './components/common/CourseCartPage';
import CourseCheckoutPage from './components/common/checkout/CourseCheckoutPage';
import ReturnCheckoutPage from './components/common/checkout/ReturnCheckoutPage';
import ForgotPasswordPage from './pages/others/forgotPassword/ForgotPassword';
import ResetPasswordPage from './pages/others/forgotPassword/ResetPassword';
import TrainerSignUpNew from './pages/others/signup/TrainerSignUpNew';
import VerifyEmailPage from './pages/others/signup/VerifyEmailPage';
import ShowVerifyMessagePage from './pages/others/signup/ShowVerifyMessagePage';
import PrivacyPolicy from './pages/others/privacy-policy/PrivacyPolicy';
import FAQSection from './pages/others/faqSection/FAQSection';

function App() {
    useEffect(() => {
        AOS.init({
            duration: 700,
            offset: 120,
            easing: "ease-out",
            once: true,
        });
    }, []);
    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route path="/">
                        <Route index element={<HomePage/>}/>
                        <Route path="login" element={<CustomerLoginPage/>}/>
                        <Route path="educator-login" element={<CustomerLoginPage/>}/>
                        {/* <Route path="signup" element={<CustomerSignUp/>}/> */}
                        <Route path="signup" element={<TrainerSignUpNew/>}/>
                        <Route path="forgot-password" element={<ForgotPasswordPage/>}/>
                        <Route path="reset-password/:otp/:encrytedEmail" element={<ResetPasswordPage/>}/>
                        <Route path="verify-email/:otp/:encrytedEmail" element={<VerifyEmailPage/>}/>
                        <Route path="verify-email-message/:encrytedEmail" element={<ShowVerifyMessagePage/>}/>

                        <Route path="instructors" element={<InstructorsList/>}/>
                        <Route path="instructors/:instructorId" element={<InstructorDetails/>}/>
                        <Route path="trainer-sign-up" element={<TrainerSignUpNew/>}/>

                        <Route path="about-us" element={<AboutUs/>}/>
                        <Route path="instructor-become" element={<BecomeAnInstructor/>}/>
                        <Route path="membership-plans" element={<TrainerMemberShipPricing/>}/>

                        <Route path="terms" element={<TermsAndCondition/>}/>
                        <Route path="privacy-policy" element={<PrivacyPolicy/>}/>
                        <Route path="help-center" element={<HelpCenterPage/>}/>
                        <Route path="contact-us" element={<ContactUs/>}/>
                        <Route path="faq/:faqTopic" element={<FAQSection/>}/>

                        <Route path="courses" element={<CustomerCourses/>}/>
                        <Route path="courses/category/:categoryId" element={<CustomerCourses />}/>
                        <Route path="courses/category/:categoryId/subcategory/:subCategoryId" element={<CustomerCourses />}/>
                        <Route path="courses/:slugId" element={<CustomerCoursesDetail/>}/>

                        <Route path="course-cart" element={<CourseCartPage/>}/>
                        <Route path="course-checkout" element={<CourseCheckoutPage/>}/>
                        <Route path="return/course-purchased" element={<ReturnCheckoutPage/>}/>

                        {/* My Dashboard Starts */}
                        <Route path="my-dashboard/*" element={<MyDashboard/>}/>

                        {/*Page Not Found*/}
                        <Route path="*" element={<PageNotFound/>}/>

                        {/*Super Admin Panel*/}
                        <Route path="super_admin/*" element={<SuperAdmin/>}/>
                    </Route>
                </Routes>
                <ScrollTopBehaviour/>
            </BrowserRouter>
        </>
    );
}

export default App;
