import Axios from "axios";
import {
	GET_CUSTOMER_COURSE_LIST,
	GET_CUSTOMER_COURSE_DETAIL,
	GET_CUSTOMER_COURSE_FILTER_LIST,
	GET_CUSTOMER_ENROLLED_COURSES,
	GET_ENROLLED_COURSE_DETAIL,
	GET_BOOKMARK_COURSES,
} from "../commonActions/types";
import { BASE_URL } from "../../../config/config";
import { ERROR, LOADING, SUCCESS } from "../../../constants/generalConstant";
import { onResponseError } from "../../../function/Interceptor";
import { notification } from "antd";

export function getCustomerCourseListAction(filters = {}, actionResponse = {}) {
	const { onSuccess, onError, onLoading } = actionResponse;
	return async (dispatch) => {
		dispatch({ type: GET_CUSTOMER_COURSE_LIST, status: LOADING });
		onLoading?.(true);
		try {
			let params = {
				pagination: true,
				fields: "",
				search: filters?.search ?? "",
				page: filters?.page !== undefined ? filters.page : 1,
				rating: filters?.rating ? filters.rating : "",
				category: filters?.category !== "0" ? filters.category : "",
				subcategory: filters?.subcategory !== "0" ? filters.subcategory : "",
				seller: filters?.seller !== "0" ? filters.seller : "",
				level: filters?.level !== "0" ? filters.level : "",
				audio_language:
					filters?.audio_language !== "0" ? filters.audio_language : "",
				duration: filters?.duration !== "0" ? filters.duration : "",
			};

			let Config = {
				params,
			};
			if (localStorage.getItem("beauty_key")) {
				Config.headers = {
					Authorization: "Bearer " + localStorage.getItem("beauty_key"),
				};
			}

			let response = await Axios.get(`${BASE_URL}courses/listCourse`, Config);
			const modifiedData = {
				meta_data: {
					count: response.data.data[0].count,
					current: response.data.data[0].links.current,
					hasNext: Boolean(response.data.data[0].links.next),
					total_pages: response.data.data[0].links.total_pages,
					hasPrevious: Boolean(response.data.data[0].links.previous),
				},
				data: response?.data?.data[0].results,
			};
			onSuccess?.(modifiedData);
			dispatch({
				type: GET_CUSTOMER_COURSE_LIST,
				status: SUCCESS,
				payload: modifiedData,
			});
		} catch (error) {
			onError?.(error?.response?.data);
			onResponseError(error?.response?.data);
			dispatch({
				type: GET_CUSTOMER_COURSE_LIST,
				status: ERROR,
				payload: error?.response?.data,
			});
		}
	};
}

export function getCustomerCourseDetailsAction(
	courseSlug,
	actionResponse = {}
) {
	const { onSuccess, onError, onLoading } = actionResponse;
	return async (dispatch) => {
		dispatch({ type: GET_CUSTOMER_COURSE_DETAIL, status: LOADING });
		onLoading?.(true);
		try {
			let params = {
				pagination: false,
				fields: "",
			};

			let Config = {
				params,
			};
			if (localStorage.getItem("beauty_key")) {
				Config.headers = {
					Authorization: "Bearer " + localStorage.getItem("beauty_key"),
				};
			}
			let response = await Axios.get(
				`${BASE_URL}courses/getCourse/${courseSlug}/`,
				Config
			);
			onSuccess?.(response?.data);
			dispatch({
				type: GET_CUSTOMER_COURSE_DETAIL,
				status: SUCCESS,
				payload: response?.data,
			});
		} catch (error) {
			onError?.(error?.response?.data);
			onResponseError(error?.response?.data);
			dispatch({
				type: GET_CUSTOMER_COURSE_DETAIL,
				status: ERROR,
				payload: error?.response?.data,
			});
		}
	};
}

export function getCustomerCourseFilterListAction(actionResponse = {}) {
	const { onSuccess, onError, onLoading } = actionResponse;
	return async (dispatch) => {
		dispatch({ type: GET_CUSTOMER_COURSE_FILTER_LIST, status: LOADING });
		onLoading?.(true);
		try {
			let params = {
				pagination: true,
			};

			let Config = {
				params,
			};
			if (localStorage.getItem("beauty_key")) {
				Config.headers = {
					Authorization: "Bearer " + localStorage.getItem("beauty_key"),
				};
			}

			let response = await Axios.get(
				`${BASE_URL}courses/courseFliterList`,
				Config
			);
			onSuccess?.(response.data);
			dispatch({
				type: GET_CUSTOMER_COURSE_FILTER_LIST,
				status: SUCCESS,
				payload: response.data,
			});
		} catch (error) {
			onError?.(error?.response?.data);
			onResponseError(error?.response?.data);
			dispatch({
				type: GET_CUSTOMER_COURSE_FILTER_LIST,
				status: ERROR,
				payload: error?.response?.data,
			});
		}
	};
}

export function getEnrolledMyCoursesAction(body = {}, actionResponse = {}) {
	const { onSuccess, onError, onLoading } = actionResponse;
	return async (dispatch) => {
		dispatch({ type: GET_CUSTOMER_ENROLLED_COURSES, status: LOADING });
		onLoading?.(true);
		try {
			let params = {
				pagination: true,
				search: body?.search ?? "",
			};

			if(body.status){
				params.status = body.status
			}

			let Config = {
				params,
			};
			if (localStorage.getItem("beauty_key")) {
				Config.headers = {
					Authorization: "Bearer " + localStorage.getItem("beauty_key"),
				};
			}

			let response = await Axios.get(
				`${BASE_URL}courses/getEnrolledCourseList`,
				Config
			);
			const modifiedData = {
				meta_data: {
					count: response.data.data[0].count,
					current: response.data.data[0].links.current,
					hasNext: Boolean(response.data.data[0].links.next),
					total_pages: response.data.data[0].links.total_pages,
					hasPrevious: Boolean(response.data.data[0].links.previous),
				},
				data: response?.data?.data[0].results,
			};
			onSuccess?.(modifiedData);
			dispatch({
				type: GET_CUSTOMER_ENROLLED_COURSES,
				status: SUCCESS,
				payload: modifiedData,
			});
		} catch (error) {
			onError?.(error?.response?.data);
			onResponseError(error?.response?.data);
			dispatch({
				type: GET_CUSTOMER_ENROLLED_COURSES,
				status: ERROR,
				payload: error?.response?.data,
			});
		}
	};
}

export function getEnrolledCourseDetailsAction(
	courseSlug,
	actionResponse = {}
) {
	const { onSuccess, onError, onLoading } = actionResponse;
	return async (dispatch) => {
		dispatch({ type: GET_ENROLLED_COURSE_DETAIL, status: LOADING });
		onLoading?.(true);
		try {
			let params = {
				pagination: false,
				fields: "",
			};

			let Config = {
				params,
			};
			if (localStorage.getItem("beauty_key")) {
				Config.headers = {
					Authorization: "Bearer " + localStorage.getItem("beauty_key"),
				};
			}
			let response = await Axios.get(
				`${BASE_URL}courses/getEnrolledCourse/${courseSlug}/`,
				Config
			);
			onSuccess?.(response?.data);
			dispatch({
				type: GET_ENROLLED_COURSE_DETAIL,
				status: SUCCESS,
				payload: response?.data,
			});
		} catch (error) {
			onError?.(error?.response?.data);
			onResponseError(error?.response?.data);
			notification["error"]({
				message: "Something went wrong.",
				description: "Please contact administration for further query.",
				duration: 2,
			});
			dispatch({
				type: GET_ENROLLED_COURSE_DETAIL,
				status: ERROR,
				payload: error?.response?.data,
			});
		}
	};
}

export function updateLessonProgressAction(body, actionResponse = {}) {
	const { onSuccess, onError, onLoading } = actionResponse;
	return async (dispatch) => {
		onLoading?.(true);
		try {
			let config = {
				headers: {}, // Initialize headers object
			};
			if (localStorage.getItem("beauty_key")) {
				config.headers.Authorization =
					"Bearer " + localStorage.getItem("beauty_key");
			}
			let response = await Axios.post(
				`${BASE_URL}courses/addCourseProgressCourse`,
				body,
				config
			);
			onSuccess?.(response?.data);
		} catch (error) {
			onError?.(error?.response?.data);
			onResponseError(error?.response?.data);
		}
	};
}

export function updateCourseBookmarkAction(body, actionResponse = {}) {
	const { onSuccess, onError, onLoading } = actionResponse;
	return async (dispatch) => {
		onLoading?.(true);
		try {
			let config = {
				headers: {}, // Initialize headers object
			};
			if (localStorage.getItem("beauty_key")) {
				config.headers.Authorization =
					"Bearer " + localStorage.getItem("beauty_key");
			}
			let response = await Axios.post(
				`${BASE_URL}courses/updateBookmarkCourse`,
				body,
				config
			);
			onSuccess?.(response?.data);
		} catch (error) {
			onError?.(error?.response?.data);
			onResponseError(error?.response?.data);
		}
	};
}

export function getBoookmarkCoursesAction(body = {}, actionResponse = {}) {
	const { onSuccess, onError, onLoading } = actionResponse;
	return async (dispatch) => {
		dispatch({ type: GET_BOOKMARK_COURSES, status: LOADING });
		onLoading?.(true);
		try {
			let params = {
				pagination: true,
				search: body?.search ?? "",
			};

			let Config = {
				params,
			};
			if (localStorage.getItem("beauty_key")) {
				Config.headers = {
					Authorization: "Bearer " + localStorage.getItem("beauty_key"),
				};
			}

			let response = await Axios.get(
				`${BASE_URL}courses/getBookmarkCourse`,
				Config
			);
			const modifiedData = {
				meta_data: {
					count: response.data.data[0].count,
					current: response.data.data[0].links.current,
					hasNext: Boolean(response.data.data[0].links.next),
					total_pages: response.data.data[0].links.total_pages,
					hasPrevious: Boolean(response.data.data[0].links.previous),
				},
				data: response?.data?.data[0].results,
			};
			onSuccess?.(modifiedData);
			dispatch({
				type: GET_BOOKMARK_COURSES,
				status: SUCCESS,
				payload: modifiedData,
			});
		} catch (error) {
			onError?.(error?.response?.data);
			onResponseError(error?.response?.data);
			dispatch({
				type: GET_BOOKMARK_COURSES,
				status: ERROR,
				payload: error?.response?.data,
			});
		}
	};
}
