import React from "react";
import { COMPANY_NAME } from "../../../config/config";
import MetaComponent from "../../../components/common/MetaComponent";
import Preloader from "../../../components/common/Preloader";
import Header from "../../../components/layout/headers/Header";
import Footer from "../../../components/layout/footers/Footer";

const metadata = {
	title: `Privacy Policy || ${COMPANY_NAME}`,
	description: `Elevate your e-learning content with ${COMPANY_NAME}, the most impressive LMS template for online courses, education and LMS platforms.`,
};
export default function PrivacyPolicy() {
	return (
		<div className="main-content  ">
			<MetaComponent meta={metadata} />
			<Preloader />

			<Header />
			<div className="content-wrapper js-content-wrapper overflow-hidden">
				<>
					<section className="page-header -type-1 mt-90">
						<div className="container">
							<div className="page-header__content">
								<div className="row justify-center text-center">
									<div className="col-auto">
										<div>
											<h1 className="page-header__title">Privacy Policy</h1>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
					<section className="layout-pt-md layout-pb-lg">
						<div className="container">
							<div className="row justify-center">
								<div className="col-xl-8 col-lg-9 col-md-11">
									<p className="mb-30">
										At Pro Beauty Port ("Pro Beauty Port", "we", "us", or
										"our"), we take the privacy of our users seriously. This
										Privacy Policy ("Policy") describes how we collect, use, and
										disclose your information when you use our website (the
										"Service") and the courses offered by our educators
										("Educators").
									</p>
								</div>
								<div className="col-xl-8 col-lg-9 col-md-11">
									<h5>1. Information We Collect</h5>
									<p className="my-10">
										We collect several types of information about you when you
										use the Service:
									</p>
									<div className="mt-30">
										<ul>
											<li className="custom-list">
												<span style={{ fontWeight: "bold" }}>
													Information You Provide:
												</span>
												<ul>
													<li className="custom-list-nested">
														<span style={{ fontWeight: "bold" }}>
															Account Information:
														</span>
														When you create an account, we collect your name,
														email address, username, and password.
													</li>
													<br />
													<li className="custom-list-nested">
														<span style={{ fontWeight: "bold" }}>
															Course Information:
														</span>
														When you enroll in a course, we collect information
														about the course, such as the title and educator.
													</li>
													<br />
													<li className="custom-list-nested">
														<span style={{ fontWeight: "bold" }}>
															Payment Information:
														</span>
														We use a secure payment processor to collect your
														payment information when you purchase a course. We
														do not store your full credit card details
														ourselves.
													</li>
													<br />
													<li className="custom-list-nested">
														<span style={{ fontWeight: "bold" }}>
															Contact Information:
														</span>
														If you contact us through our contact form or email,
														we collect your name, email address, and the content
														of your message.
													</li>
												</ul>
											</li>
											<br />
											<li className="custom-list">
												<span style={{ fontWeight: "bold" }}>
													Information Collected Automatically:
												</span>{" "}
												<ul>
													<li className="custom-list-nested">
														<span style={{ fontWeight: "bold" }}>
															Log Data:
														</span>
														When you use the Service, we automatically collect
														information about your device and browsing activity.
														This information may include your IP address,
														browser type, operating system, referral URL, pages
														visited, and time spent on the Service.
													</li>
													<br />
													<li className="custom-list-nested">
														<span style={{ fontWeight: "bold" }}>Cookies:</span>
														We use cookies and similar tracking technologies to
														track the activity on our Service and hold certain
														information.
													</li>
												</ul>
											</li>
										</ul>
									</div>
								</div>

								<div className="col-xl-8 col-lg-9 col-md-11">
									<div className="mt-60 lg:mt-40">
										<h5>2. Use of Your Information</h5>
										<p className="mb-10">
											We use the information we collect about you to:
										</p>
										<div className="mt-30">
											<ul>
												<li className="custom-list">
													Provide and operate the Service
												</li>
												<br />
												<li className="custom-list">
													Process your course enrollments and payments
												</li>
												<br />
												<li className="custom-list">
													Send you important information about the Service, such
													as course updates and administrative notices
												</li>
												<br />
												<li className="custom-list">
													Respond to your inquiries and requests
												</li>
												<br />
												<li className="custom-list">
													Send you marketing communications, such as newsletters
													and promotions (with your consent)
												</li>
												<br />
												<li className="custom-list">
													Analyze how you use the Service to improve our
													offerings
												</li>
											</ul>
										</div>
									</div>
								</div>

								<div className="col-xl-8 col-lg-9 col-md-11">
									<div className="mt-60 lg:mt-40">
										<h5>3. Sharing Your Information</h5>
										<div className="mt-30">
											<p>
												We may share your information with third-party service
												providers who help us operate the Service. These service
												providers are contractually obligated to keep your
												information confidential and secure.
											</p>
											<br />
											<p>
												We may also share your information with Educators when
												you enroll in their courses. Educators will use your
												information to provide you with the course and
												communicate with you about it.
											</p>
											<br />
											<p>
												We may disclose your information if required by law or
												to protect the rights and safety of ourselves or others.
											</p>
										</div>
									</div>
								</div>

								<div className="col-xl-8 col-lg-9 col-md-11">
									<div className="mt-60 lg:mt-40">
										<h5>4. Your Choices</h5>
										<p className="my-10">
											You can control your privacy settings by:
										</p>
										<div className="mt-30">
											<ul>
												<li className="custom-list">
													Accessing your account settings
												</li>
												<br />
												<li className="custom-list">
													Unsubscribing from marketing communications
												</li>
											</ul>
										</div>
									</div>
								</div>

								<div className="col-xl-8 col-lg-9 col-md-11">
									<div className="mt-60 lg:mt-40">
										<h5>5. Data Retention</h5>
										<p className="mt-20">
											We will retain your information for as long as your
											account is active or as needed to provide you with the
											Service. We may also retain your information for legal or
											regulatory reasons.
										</p>
									</div>
								</div>

								<div className="col-xl-8 col-lg-9 col-md-11">
									<div className="mt-60 lg:mt-40">
										<h5>6. Children's Privacy</h5>
										<p className="mt-20">
											The Service is not intended for children under the age of
											13. We do not knowingly collect personal information from
											children under 13. If you are a parent or guardian and you
											believe that your child has provided us with personal
											information, please contact us
										</p>
									</div>
								</div>

								<div className="col-xl-8 col-lg-9 col-md-11">
									<div className="mt-60 lg:mt-40">
										<h5>7. Security</h5>
										<p className="mt-20">
											We take steps to protect your information from
											unauthorized access, disclosure, alteration, or
											destruction. However, no internet transmission or
											electronic storage is ever completely secure.
										</p>
									</div>
								</div>

								<div className="col-xl-8 col-lg-9 col-md-11">
									<div className="mt-60 lg:mt-40">
										<h5>8. International Transfers</h5>
										<p className="mt-20">
											Your information may be transferred to and processed in
											countries other than your own. These countries may have
											different data protection laws than your own country.
										</p>
									</div>
								</div>

								<div className="col-xl-8 col-lg-9 col-md-11">
									<div className="mt-60 lg:mt-40">
										<h5>9. Changes to this Policy</h5>
										<p className="mt-20">
											We may update this Policy from time to time. We will
											notify you of any changes by posting the new Policy on the
											Service.
										</p>
									</div>
								</div>

								<div className="col-xl-8 col-lg-9 col-md-11">
									<div className="mt-60 lg:mt-40">
										<h5>10. Contact Us</h5>
										<p className="mt-20">
											If you have any questions about this Policy, please
											contact us at Info@probeautyport.com.
										</p>
									</div>
								</div>
							</div>
						</div>
					</section>
				</>
				<Footer />
			</div>
		</div>
	);
}
