import Axios from "axios";
import {GET_TESTIMONIALS} from "../commonActions/types";
import {BASE_URL} from "../../../config/config";
import {ERROR, LOADING, SUCCESS} from "../../../constants/generalConstant";
import { onResponseError } from "../../../function/Interceptor";

export function getTestimonials(actionResponse = {}) {
    const {onSuccess, onError, onLoading} = actionResponse;
    return async (dispatch) => {
        dispatch({type: GET_TESTIMONIALS, status: LOADING});
        onLoading?.(true)
        try {
            let params = {
                pagination: false,
                fields: 'content,id,title,user_first_name,user_last_name,user_position,user_profile_image'
            };

            let Config = {
                params,
            }
            if (localStorage.getItem("beauty_key")) {
                Config.headers = {Authorization: "Bearer " + localStorage.getItem("beauty_key")}
            }

            let response = await Axios.get(`${BASE_URL}common/getTestimonialsList`, Config);
            onSuccess?.(response?.data)
            dispatch({
                type: GET_TESTIMONIALS,
                status: SUCCESS,
                payload: response?.data,
            });
        } catch (error) {
            onError?.(error?.response?.data)
            onResponseError(error?.response?.data)
            dispatch({
                type: GET_TESTIMONIALS,
                status: ERROR,
                payload: error?.response?.data,
            });
        }
    };
}