import React from "react";
import {
	Button,
	Dialog,
	DialogContent,
	DialogTitle,
	IconButton,
	styled,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
	FacebookIcon,
	FacebookShareButton,
	LinkedinIcon,
	LinkedinShareButton,
	TwitterIcon,
	TwitterShareButton,
	WhatsappIcon,
	WhatsappShareButton,
} from "react-share";
import { useSnackbar } from "notistack";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
	"& .MuiDialogContent-root": {
		padding: theme.spacing(2),
	},
	"& .MuiDialogActions-root": {
		padding: theme.spacing(1),
	},
}));

const SocialShare = (props) => {
	const { open, setOpen, url, title } = props;
	const { enqueueSnackbar } = useSnackbar();

	const handleClose = () => {
		setOpen(false);
	};

	const handleCopyUrl = () => {
		const urlToCopy = window.location.href;
		navigator.clipboard
			.writeText(urlToCopy)
			.then(() => {
				enqueueSnackbar("URL copied to clipboard.", { variant: "success" });
			})
			.catch((error) => {
				enqueueSnackbar("Failed to copy URL to clipboard.", {
					variant: "error",
				});
			});
	};

    const propsForShareComponent = {
        windowHeight: 5000,
        windowWidth: 5000,
        title:title ?? "",
    }

	return (
		<>
			<BootstrapDialog
				onClose={handleClose}
				aria-labelledby="customized-dialog-title"
				open={open}
				maxWidth={"sm"}
				fullWidth>
				<DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
					Share to learn with friends.
				</DialogTitle>
				<IconButton
					aria-label="close"
					onClick={handleClose}
					sx={{
						position: "absolute",
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
					}}>
					<CloseIcon />
				</IconButton>
				<DialogContent dividers>
					<div style={{ display: "flex", gap: "15px" }}>
						<FacebookShareButton url={url} {...propsForShareComponent}>
							<FacebookIcon size={50} round />
						</FacebookShareButton>
						<WhatsappShareButton url={url} {...propsForShareComponent}>
							<WhatsappIcon size={50} round />
						</WhatsappShareButton>
						<TwitterShareButton url={url} {...propsForShareComponent}>
							<TwitterIcon size={50} round />
						</TwitterShareButton>
						<LinkedinShareButton url={url} {...propsForShareComponent}>
							<LinkedinIcon size={50} round />
						</LinkedinShareButton>
					</div>
					<form className="contact-form respondForm__form row y-gap-20 pt-30">
						<div className="col-12">
							<label className="text-16 lh-1 fw-500 text-dark-1 mb-10">
								Click to copy url:
							</label>
							<Button
								variant="outlined"
								fullWidth
								size="large"
								style={{
									display: "flex",
									justifyContent: "flex-start",
									color: "grey",
									borderColor: "lightGrey",
									textTransform: "inherit",
								}}
								disableElevation
								onClick={() => handleCopyUrl()}>
								{url}
							</Button>
						</div>
					</form>
				</DialogContent>
			</BootstrapDialog>
		</>
	);
};

export default SocialShare;
