import React from "react";
import { COMPANY_NAME } from "../../config/config";
import MetaComponent from "../../components/common/MetaComponent";
import Preloader from "../../components/common/Preloader";
import Header from "../../components/layout/headers/Header";
import Testimonials from "../../components/others/testimonials/Testimonials";
// import LearningJourneyColumns from "../others/instructor/LearningJourneyColumns";
import Footer from "../../components/layout/footers/Footer";
import { Link } from "react-router-dom";
import HowItWorks from "./HowItWorks";
import InstructorJoinToday from "../others/instructor/InstructorJoinToday";
// import BecomeStudent from "./BecomeStudent";
import aboutUsImage from '../../images/aboutUs/about_us_image.png'

const metadata = {
	title: `About Us | ${COMPANY_NAME}`,
	description: `Elevate your e-learning content with ${COMPANY_NAME}, the most impressive LMS template for online courses, education and LMS platforms.`,
};

export default function AboutUs() {
	return (
		<div className="main-content  ">
			<MetaComponent meta={metadata} />
			<Preloader />

			<Header />
			<div className="content-wrapper js-content-wrapper overflow-hidden">
				{/*Page Heading Start*/}
				<>
					<section className="page-header -type-1">
						<div className="container layout-pt-lg">
							<div className="page-header__content">
								<div className="row justify-center text-center">
									<div className="col-auto">
										<div>
											<h1 className="page-header__title">About Us</h1>
										</div>

										<div>
											<p className="page-header__text">
												We’re on a mission to deliver engaging, curated courses at a reasonable price.
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
					<section className=" layout-pb-lg mt-90">
						<div className="container">
							<div className="row y-gap-50 justify-between items-center">
								<div 
								// className="col-lg-6 pr-50 sm:pr-15"
								className="col-lg-6 "
								>
									<div className="composition -type-8" style={{display:'flex',justifyContent:'center'}}>
										<div className="-el-1">
											<img
												src={aboutUsImage}
												alt="about-us-1"
											/>
										</div>
									</div>
								</div>

								<div className="col-lg-5">
									<h2 className="text-30 lh-16">
										Welcome to Pro Beauty Port: Where Your Beauty Career
										Thrives!
									</h2>
									<p className="text-dark-1 mt-30">
										At Pro Beauty Port, beauty professionals embark on a journey
										of growth and success. Our platform offers a curated
										selection of premium beauty courses taught by industry
										experts. Whether you're just starting out and eager to
										refine your skills, or a seasoned professional in search of
										advanced training, Pro Beauty Port provides the tools and
										resources necessary for your success.
									</p>
									<div className="d-inline-block">
										<Link
											to="/signup"
											className="button -md -purple-1 text-white mt-30">
											Start Your Journey Now!
										</Link>
									</div>
								</div>
							</div>
						</div>
					</section>
				</>
				{/*Page Heading ends here*/}
				<HowItWorks />
				{/* <LearningJourneyColumns /> */}
				<Testimonials />

				{/* <LearningJourneyColumns /> */}

				<InstructorJoinToday />
				{/* <BecomeStudent /> */}

				<Footer />
			</div>
		</div>
	);
}
