import React, {useEffect, useState} from 'react';
import {Dialog, DialogActions, DialogContent, DialogTitle, IconButton, styled, TextField,} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {useDispatch, useSelector} from "react-redux";
import {useSnackbar} from "notistack";
import LoadingButton from '@mui/lab/LoadingButton';
import { getChaptersListAction, updateChaptersAction,addChaptersAction } from './../../../../../../redux/action/myDashboardActions/chapterActions';
import { getCoursePreviewAction } from '../../../../../../redux/action/myDashboardActions/coursesActions';

const BootstrapDialog = styled(Dialog)(({theme}) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));


const AddChapters = (props) => {
    const {open, setOpen,courseId,type,updateChapter,course_slug_name} = props;
    const [chapterName, setChapterName] = useState('');
    const [chapterNameError, setChapterNameError] = useState('')
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const {enqueueSnackbar} = useSnackbar();

    useEffect(()=>{
        if(type === "update"){
            setChapterName(updateChapter.title)
        }
    },[type, updateChapter])

    const {data: SellerChapters } = useSelector(state => state.myDashboard.sellerChapters)

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = () => {
        if (chapterName.trim().length === 0) {
            setChapterNameError('Chapter Name is Required')
            return
        }
        if(type === "update"){
            let body = {
                title: chapterName,
            }
            dispatch(updateChaptersAction(body,updateChapter.id, {
                onLoading: (loading) => {
                    setLoading(true)
                    enqueueSnackbar('Loading...', {variant: 'info'});
                },
                onError: (error) => {
                    setLoading(false)
                    let errorMessage = String(error?.message);
                    enqueueSnackbar(errorMessage, {variant: 'error'});
                },
                onSuccess: (success) => {
                    setLoading(false)
                    setChapterName('');
                    setChapterNameError('')
                    enqueueSnackbar(success.message[0], {variant: 'success'});
                    dispatch(getChaptersListAction(courseId))
                    props?.handleShowAutoText?.()
                    course_slug_name && dispatch(getCoursePreviewAction(course_slug_name))
                    handleClose();
                }
            }))
        }else{
            let body = {
                title: chapterName,
                course : courseId,
                order_no : SellerChapters.length + 1
            }
            dispatch(addChaptersAction(body, {
                onLoading: (loading) => {
                    setLoading(true)
                    enqueueSnackbar('Loading...', {variant: 'info'});
                },
                onError: (error) => {
                    setLoading(false)
                    let errorMessage = String(error?.message);
                    enqueueSnackbar(errorMessage, {variant: 'error'});
                },
                onSuccess: (success) => {
                    setLoading(false)
                    setChapterName('');
                    setChapterNameError('')
                    enqueueSnackbar(success.message[0], {variant: 'success'});
                    dispatch(getChaptersListAction(courseId))
                    props?.handleShowAutoText?.()
                    course_slug_name && dispatch(getCoursePreviewAction(course_slug_name))
                    handleClose();
                }
            }))
        }
    };

    return (
        <BootstrapDialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
            maxWidth={'xs'}
            fullWidth
        >
            <DialogTitle sx={{m: 0, p: 2}} id="customized-dialog-title">
               {type === "update" ? "Update Chapter" :"Add Chapter"}
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon/>
            </IconButton>
            <DialogContent dividers>
                <TextField
                    label="Chapter Name"
                    variant="outlined"
                    value={chapterName}
                    onChange={(e) => setChapterName(e.target.value)}
                    fullWidth
                    margin="normal"
                    error={Boolean(chapterNameError)}
                    helperText={chapterNameError}
                />
            </DialogContent>
            <DialogActions>
                <LoadingButton variant={"contained"} loading={loading} autoFocus onClick={handleSubmit} className="button h-50 px-25 -dark-1 -dark-button-white text-dark">
                    Save
                </LoadingButton>
            </DialogActions>
        </BootstrapDialog>
    );
};

export default AddChapters;
