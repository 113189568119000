import Axios from "axios";
import { ERROR, LOADING, SUCCESS } from "../../../constants/generalConstant";
import { BASE_URL } from "../../../config/config";
import { ADD_CHAPTERS, DELETE_CHAPTERS, GET_CHAPTERS, UPDATE_CHAPTERS } from "../commonActions/types";
import { onResponseError } from "../../../function/Interceptor";

export function getChaptersListAction(courseId,actionResponse = {}) {
    const {onSuccess, onError, onLoading} = actionResponse;
    return async (dispatch) => {
        dispatch({type: GET_CHAPTERS, status: LOADING});
        onLoading?.(true)
        try {
            let params = {
                pagination: false,
                course : courseId,
                fields: 'course,id,lessons,order_no,title',
                ordering : 'order_no'
            };

            let Config = {
                params,
            }
            if (localStorage.getItem("beauty_key")) {
                Config.headers = {Authorization: "Bearer " + localStorage.getItem("beauty_key")}
            }
            let response = await Axios.get(`${BASE_URL}courses/chapterList`, Config);
            const modifiedData = {
                ...response.data,
                data: response?.data?.data.map((item, index) => ({
                    ...item,
                    index
                }))
            }
            onSuccess?.(modifiedData)
            dispatch({
                type: GET_CHAPTERS,
                status: SUCCESS,
                payload: modifiedData,
            });
        } catch (error) {
            onError?.(error?.response?.data)
            onResponseError(error?.response?.data)
            dispatch({
                type: GET_CHAPTERS,
                status: ERROR,
                payload: error?.response?.data,
            });
        }
    };
}


export function addChaptersAction(body, actionResponse = {}) {
    const {onSuccess, onError, onLoading} = actionResponse;
    return async (dispatch) => {
        dispatch({type: ADD_CHAPTERS, status: LOADING});
        onLoading?.(true)
        try {
            let config = {
                headers: {} // Initialize headers object
            };
            if (localStorage.getItem("beauty_key")) {
                config.headers.Authorization = "Bearer " + localStorage.getItem("beauty_key");
            }
            let response = await Axios.post(`${BASE_URL}courses/addChapter`, body,config);
            onSuccess?.(response?.data)
            dispatch({
                type: ADD_CHAPTERS,
                status: SUCCESS,
                payload: response?.data,
            });
        } catch (error) {
            onError?.(error?.response?.data)
            onResponseError(error?.response?.data)
            dispatch({
                type: ADD_CHAPTERS,
                status: ERROR,
                payload: error?.response?.data,
            });
        }
    };
}

export function updateChaptersAction(body,chapterId, actionResponse = {}) {
    const {onSuccess, onError, onLoading} = actionResponse;
    return async (dispatch) => {
        dispatch({type: UPDATE_CHAPTERS, status: LOADING});
        onLoading?.(true)
        try {
            let config = {
                headers: {} // Initialize headers object
            };

            if (localStorage.getItem("beauty_key")) {
                config.headers.Authorization = "Bearer " + localStorage.getItem("beauty_key");
            }
            let response = await Axios.patch(`${BASE_URL}courses/updateChapter/${chapterId}/`, body,config);
            onSuccess?.(response?.data)
            dispatch({
                type: UPDATE_CHAPTERS,
                status: SUCCESS,
                payload: response?.data,
            });
        } catch (error) {
            onError?.(error?.response?.data)
            onResponseError(error?.response?.data)
            dispatch({
                type: UPDATE_CHAPTERS,
                status: ERROR,
                payload: error?.response?.data,
            });
        }
    };
}

export function deleteChapterAction(chapterId, actionResponse = {}) {
    const { onSuccess, onError, onLoading } = actionResponse;
    return async (dispatch) => {
        dispatch({ type: DELETE_CHAPTERS, status: LOADING });
        onLoading?.(true);
        try {
            let config = {
                headers: {} // Initialize headers object
            };

            if (localStorage.getItem("beauty_key")) {
                config.headers.Authorization = "Bearer " + localStorage.getItem("beauty_key");
            }
            let response = await Axios.delete(`${BASE_URL}courses/deleteChapter/${chapterId}/`, config);
            onSuccess?.(response?.data);
            dispatch({
                type: DELETE_CHAPTERS,
                status: SUCCESS,
                payload: response?.data,
            });
        } catch (error) {
            onError?.(error?.response?.data);
            onResponseError(error?.response?.data)
            dispatch({
                type: DELETE_CHAPTERS,
                status: ERROR,
                payload: error?.response?.data,
            });
        }
    };
}
