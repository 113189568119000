import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getCourseCategories } from "../../../redux/action/courseActions/courseCategoryActions";

export default function HeaderMenuArea(props) {
	const { allClasses, headerPosition } = props;
	const { pathname } = useLocation();
	const dispatch = useDispatch();

	const {
		data: Categories,
		isLoading,
		isSuccess,
	} = useSelector((state) => state.courses.categories);

	useEffect(() => {
		!isSuccess && dispatch(getCourseCategories());
	}, [dispatch, isSuccess]);

	return (
		<div
			className={`header-menu js-mobile-menu-toggle ${
				headerPosition ? headerPosition : ""
			}`}>
			<div className="header-menu__content">
				<div className="mobile-bg js-mobile-bg"></div>
				<div className="d-none xl:d-flex items-center px-20 py-20 border-bottom-light">
					<Link to="/login" className="text-dark-1">
						Log in
					</Link>
					<Link to="/signup" className="text-dark-1 ml-30">
						Sign Up
					</Link>
				</div>
				<div className="menu js-navList">
					<ul className={`${allClasses ? allClasses : ""}`}>
						<li className="menu-item-has-children">
							<Link
								data-barba
								to={{
									pathname: `/courses/`,
								}}
                                className={
                                    pathname.split("/")[1] === "courses"
                                        ? "activeMenu"
                                        : "inActiveMenuTwo"
                                }
                                >
								Courses <i className="icon-chevron-right text-13 ml-10"></i>
							</Link>
							<ul className="subnav">
								<li className="menu__backButton js-nav-list-back">
									<Link
										to="#">
										<i className="icon-chevron-left text-13 mr-10"></i> Courses
									</Link>
								</li>
								{isLoading && <>Loading...</>}
								{Categories.map((elm, i) => (
									<li
										key={i}
										className={
											pathname.split("/")[1] === elm.value
												? "activeMenu"
												: "inActiveMenu"
										}>
										<Link
											to={{
												pathname: `/courses/category/${elm.id}`,
											}}>
											{elm.name}
											{elm?.sub_category?.length ? (
												<div className="icon-chevron-right text-11"></div>
											) : (
												""
											)}
										</Link>
										{elm?.sub_category?.length ? (
											<ul className="subnav">
												<li className="menu__backButton js-nav-list-back">
													<Link
														to={{
															pathname: `/courses/category/${elm.id}`,
														}}>
														<i className="icon-chevron-left text-13 mr-10"></i>
														{elm.name}
													</Link>
												</li>

												{elm.sub_category.map((subElm, subI) => (
													<li key={subI} className={"inActiveMenu"}>
														<Link
															key={subI}
															data-barba
															to={{
																pathname: `/courses/category/${elm.id}/subcategory/${subElm.id}`,
															}}>
															{subElm.name}
														</Link>
													</li>
												))}
											</ul>
										) : (
											""
										)}
									</li>
								))}
							</ul>
						</li>
						<li>
							<Link
								data-barba
								to="/about-us"
								className={
									pathname === "/about-us" ? "activeMenu" : "inActiveMenuTwo"
								}>
								About Us
							</Link>
						</li>
						<li>
							<Link
								data-barba
								to="/contact-us"
								className={
									pathname === "/contact-us" ? "activeMenu" : "inActiveMenuTwo"
								}>
								Contact Us
							</Link>
						</li>
					</ul>
				</div>
			</div>
		</div>
	);
}
