import {
	Box,
	Button,
} from "@mui/material";
import { Popconfirm } from "antd";
import React, { useState } from "react";
import AddLessons from "./AddLessons";
import { useDispatch } from "react-redux";
import { deleteLessonAction } from "../../../../../../redux/action/myDashboardActions/lessonActions";
import { useSnackbar } from "notistack";
import { getChaptersListAction } from "../../../../../../redux/action/myDashboardActions/chapterActions";
import { getCoursePreviewAction } from "../../../../../../redux/action/myDashboardActions/coursesActions";

export default function Lessons(props) {
	const { row, courseId, course_slug_name } = props;
	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();
	const [openAddForm, setOpenAddForm] = useState(false);
	const [selectedLesson, setSelectedLesson] = useState({
		id: 0,
	});
	const [formType, setFormType] = useState("");

	const handleLessonDelete = (lesson) => {
		dispatch(
			deleteLessonAction(lesson.id, {
				onLoading: (loading) => {
					enqueueSnackbar("Loading...", { variant: "info" });
				},
				onError: (error) => {
					let errorMessage = String(error?.message);
					enqueueSnackbar(errorMessage, { variant: "error" });
				},
				onSuccess: (success) => {
					enqueueSnackbar(success.message[0], { variant: "success" });
					dispatch(getChaptersListAction(courseId));
					props?.handleShowAutoText?.()
					course_slug_name && dispatch(getCoursePreviewAction(course_slug_name))
				},
			})
		);
	};

	const handleLessonUpdate = (lesson) => {
		setFormType("update");
		setOpenAddForm(true);
		setSelectedLesson(lesson);
	};

	return (
		<Box sx={{ margin: 2 }}>
			{openAddForm && (
				<AddLessons
					open={openAddForm}
					setOpen={setOpenAddForm}
					chapter={row}
					courseId={courseId}
					updateLessons={selectedLesson}
					type={formType}
					course_slug_name={course_slug_name}
					handleShowAutoText={props?.handleShowAutoText}
				/>
			)}
			<div
				style={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
				}}>
				<h3 className="text-17 lh-1 fw-500 mb-20" style={{ color: "grey" }}>
					Lessons
				</h3>
				<Button
					variant="outlined"
					size="large"
					className="text-purple-1 fw-500 mt-10"
					onClick={() => {
						setFormType("add");
						setOpenAddForm(true);
					}}>
					+ Add Lesson
				</Button>
			</div>

			<div className="accordion__content__inner px-30 py-30" style={{maxHeight:'400px',overflowY:'scroll'}}>
				{row?.lessons?.length ? (
					<div className="y-gap-20">
						{row.lessons.map((itm, index) => (
							<div key={index} className="d-flex justify-between">
								<div className="d-flex items-center">
									<div className="d-flex justify-center items-center size-30 rounded-full bg-purple-3 mr-10">
										<div className="icon-play text-9"></div>
									</div>
									<div>{itm.title}</div>
								</div>

								<div className="d-flex x-gap-20 items-center">
									<div className="d-flex justify-content-end">
										<div
											className="icon-edit"
											style={{ cursor: "pointer" }}
											onClick={() => handleLessonUpdate(itm)}></div>
										&emsp;
										<Popconfirm
											title="Delete Lesson"
											description="Are you sure to delete this lesson?"
											okText="Yes"
											cancelText="No"
											onConfirm={() => handleLessonDelete(itm)}>
											<div
												className="icon-bin"
												style={{ cursor: "pointer" }}></div>
										</Popconfirm>
									</div>
								</div>
							</div>
						))}
					</div>
				) : (
					<div className="text-center">Lessons not available yet.</div>
				)}
			</div>
		</Box>
	);
}
