import React, {useEffect, useState} from "react";
import {Button, Typography} from "@mui/material";
import {DataGrid} from '@mui/x-data-grid';
import {Add} from "@mui/icons-material";
import {useDispatch, useSelector} from "react-redux";
import { deleteSuperSubCourseCategoryAction, getSuperSubCategories } from "../../../../redux/action/superAdminActions/superSubCatergoryActions";
import AddSuperSubCategory from "./AddSuperSubCategory";
import { Popconfirm } from "antd";
import { useSnackbar } from "notistack";

export default function SuperSubCategories() {
    const dispatch = useDispatch()
    const [openAddForm, setOpenAddForm] = useState(false)
    const [openEditForm, setOpenEditForm] = useState(false);
    const [selectedSubCategory, setSelectedSubCategory] = useState({});
    const { enqueueSnackbar } = useSnackbar();

    const {data: SubCategories, isLoading, isSuccess } = useSelector(state => state.superAdmin.superSubCategories)

    useEffect(() => {
        !isSuccess && dispatch(getSuperSubCategories())
    }, [dispatch, isSuccess])

    useEffect(() => {
        if (!localStorage.getItem('beauty_super_key')) {
            window.location.replace('login')
        }
    }, [])

    const handleDelete = (categoryId) => {
		dispatch(
			deleteSuperSubCourseCategoryAction(categoryId, {
				onSuccess: (success) => {
					enqueueSnackbar("Sub-Category Deleted Successfully...", {
						variant: "success",
					});
					dispatch(getSuperSubCategories());
				},
			})
		);
	};

    const columns = [
        {
            field: 'index',
            width: 65,
            sortable: false,
            renderHeader: () => (
                <Typography variant="h6">
                    {'S.no '}
                </Typography>
            ),
            renderCell: (params) => {
                return (
                    <Typography>
                        {params.row.index + 1}{'.'}
                    </Typography>
                )
            }
        },
        {
            field: 'name',
            width: 200,
            sortable: false,
            flex: 1,
            renderHeader: () => (
                <Typography variant="h6">
                    {'Sub Category '}
                </Typography>
            ),
            renderCell: (params) => {
				return (
					<div>
						<b>{params?.row?.name ?? '--'}</b>
					</div>
				);
			},
        },
        {
            field: 'category_name',
            width: 200,
            sortable: false,
            flex: 1,
            renderHeader: () => (
                <Typography variant="h6">
                    {'Category Name'}
                </Typography>
            ),
        },
        {
			field: "number_of_course",
			width: 50,
			sortable: false,
			flex: 1,
			renderHeader: () => <Typography variant="h6">{"Total Courses"}</Typography>,
            renderCell: (params) => {
				return (
					<div>
						{params?.row?.available_course_count?? 0}
					</div>
				);
			},
		},
        {
			field: "actions",
			renderHeader: () => <Typography variant="h6">{"Actions"}</Typography>,
			sortable: false,
			renderCell: (params) => {
				return (
					<div className="d-flex justify-content-end">
						<div
							className="icon-edit"
							style={{ cursor: "pointer" }}
							onClick={() => {
								setSelectedSubCategory(params.row)
								setOpenEditForm(true)
							}}></div>
						&emsp;
						<Popconfirm
							title="Are you sure to delete this Sub-category?"
							description={
								<div>
									<p>All the related details will be deleted.</p>
									{/* <span>Sub Categories:</span>&nbsp;<b>{params?.row?.sub_category?.length ?? 0}</b><br/>
									<span>Courses:</span>&nbsp;<b>{params?.row?.available_course_count ?? 0}</b> */}
								</div>
							}
							okText="Yes"
							cancelText="No"
							onConfirm={() => handleDelete(params.row.id)}>
							<div className="icon-bin" style={{ cursor: "pointer" }}></div>
						</Popconfirm>
					</div>
				);
			},
		},
    ];

    const propsForAddForm = {
		open : openAddForm,
		setOpen : setOpenAddForm,
		type : "ADD"
	}

    const propsForEditForm = {
		open : openEditForm,
		setOpen : setOpenEditForm,
		type : "EDIT",
		selectedSubCategory
	}

    return (
        <div className="dashboard__main">
            {openAddForm && <AddSuperSubCategory {...propsForAddForm}/>}
            {openEditForm && <AddSuperSubCategory {...propsForEditForm}/>}
            <div className="dashboard__content bg-light-4">
                <div className="row pb-30">
                    <div className="col-auto">
                        <h1 className="text-20 lh-12 fw-500">Sub-Categories</h1>
                        <div className="mt-1">
                            Welcome to the Sub-Categories Section.
                        </div>
                    </div>
                    <div className="col-auto ms-auto">
                        <Button
                            className="button py-10 px-10 -dark-1 text-white -dark-button-white col-12"
                            startIcon={<Add/>}
                            onClick={() => setOpenAddForm(true)}
                        >
                            Add Sub-Category
                        </Button>
                    </div>
                </div>

                {/*Table Starts here */}
                <div style={{
							width: "100%",
							overflowY: "scroll",
							height: "calc(100vh - 260px)",
						}}>
                    <DataGrid
                        rows={SubCategories}
                        columns={columns}
                        // initialState={{
                        //     pagination: {
                        //         paginationModel: {page: 0, pageSize: 5},
                        //     },
                        // }}
                        // pageSizeOptions={[5, 10]}
                        showCellVerticalBorder
						// checkboxSelection
                        disableColumnMenu
                        disableRowSelectionOnClick={true}
                        disableColumnSelector
                        disableFocus
                        loading={isLoading}
                    />
                </div>
            </div>
        </div>
    );
}
