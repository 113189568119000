import React, { useEffect } from "react";
import {
	Avatar,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Typography,
	Rating,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { getSuperInstructors } from "../../../../redux/action/superAdminActions/superInstructorsActions";

export default function SuperInstructors() {
	const dispatch = useDispatch();

	const {
		data: Instructors,
		isLoading,
		isSuccess,
	} = useSelector((state) => state.superAdmin.superInstructors);

	useEffect(() => {
		if (!localStorage.getItem("beauty_super_key")) {
			window.location.replace("login");
		}
	}, []);

	useEffect(() => {
		!isSuccess && dispatch(getSuperInstructors());
	}, [dispatch, isSuccess]);

	const getRatingStars = (count) => {
		return (
			<Rating size="small" readOnly precision={0.5} defaultValue={count} />
		);
	};

	const columns = [
		{
			field: "instructor",
			width: 200,
			sortable: false,
			flex: 1,
			renderHeader: () => <Typography variant="h6">{"Instructor"}</Typography>,
			renderCell: (params) => {
				return (
					<ListItem style={{ paddingLeft: "0px" }}>
						<ListItemAvatar>
							<Avatar
								alt={String(params.row.index + 1)}
								src={params.row.user_profile_image}
								sx={{ height: 60, width: 60, marginRight: "10px" }}
							/>
						</ListItemAvatar>
						<ListItemText
							primary={
								<b>
									{params?.row?.user_first_name +
										" " +
										params?.row?.user_last_name ?? "--"}
								</b>
							}
							secondary={
								<div>
									{params.row.designation}&nbsp; (
									{params.row.email_verified ? (
										<span style={{ color: "green" }}>Verified</span>
									) : (
										<span style={{ color: "red" }}>Not Verified</span>
									)}
									)
								</div>
							}
						/>
					</ListItem>
				);
			},
		},
		{
			field: "email",
			width: 100,
			sortable: false,
			flex: 1,
			renderHeader: () => <Typography variant="h6">{"Email"}</Typography>,
			renderCell: (params) => {
				return (
					<div>
						{params?.row?.user_email ?? '--'}
					</div>
				);
			},
		},
		{
			field: "courses_count",
			width: 100,
			sortable: false,
			flex: 0.5,
			renderHeader: () => <Typography variant="h6">{"Courses"}</Typography>,
		},
		{
			field: "student_count",
			width: 100,
			sortable: false,
			flex: 0.5,
			renderHeader: () => <Typography variant="h6">{"Students"}</Typography>,
		},
		{
			field: "rating",
			width: 100,
			sortable: false,
			flex: 0.5,
			renderHeader: () => <Typography variant="h6">{"Ratings"}</Typography>,
			renderCell: (params) => {
				return (
					<ListItemText
						primary={
							<span style={{ display: "flex", alignItems: "center" }}>
								{getRatingStars(params.row?.ratings?.total_rating ?? 0)}(
								{params.row?.ratings?.total_rating ?? 0})
							</span>
						}
						secondary={`${params.row?.ratings?.total_reviews_count} reviews`}
					/>
				);
			},
		},
		// {field: 'actions', headerName: 'Actions', sortable: false},
	];

	return (
		<div className="dashboard__main">
			<div className="dashboard__content bg-light-4">
				<div className="row pb-30">
					<div className="col-auto">
						<h1 className="text-20 lh-12 fw-500">Educators</h1>
						<div className="mt-1">Welcome to the Educators Section.</div>
					</div>
				</div>

				{/*Table Starts here */}
				<div
					style={{
						width: "100%",
						overflowY: "scroll",
						height: "calc(100vh - 260px)",
					}}>
					<DataGrid
						rows={Instructors}
						columns={columns}
						rowHeight={100}
						// initialState={{
						//     pagination: {
						//         paginationModel: {page: 0, pageSize: 5},
						//     },
						// }}
						// pageSizeOptions={[5, 10]}
						showCellVerticalBorder
						checkboxSelection
						disableColumnMenu
						disableRowSelectionOnClick={true}
						disableColumnSelector
						disableFocus
						loading={isLoading}
					/>
				</div>
			</div>
		</div>
	);
}
