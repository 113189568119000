import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import { useDispatch, useSelector } from "react-redux";
import { getTestimonials } from "../../../redux/action/testimonialsActions/testimonialsActions";
import { Avatar } from "@mui/material";
import { Empty, Spin } from "antd";

export default function Testimonials() {
	const [showSlider, setShowSlider] = useState(false);
	const dispatch = useDispatch();
	useEffect(() => {
		setShowSlider(true);
	}, []);

	const {
		data: testimonials,
		isLoading,
		isSuccess,
	} = useSelector((state) => state.testimonials.testimonials);

	useEffect(() => {
		!isSuccess && dispatch(getTestimonials());
	}, [dispatch, isSuccess]);

	return (
		<section className="layout-pt-lg mt-80 layout-pb-lg bg-purple-1">
			<div className="container ">
				<div className="row justify-center text-center">
					<div className="col-auto">
						<div className="sectionTitle ">
							<h2 className="sectionTitle__title text-green-1 lh-14">
								What People Are Saying?
							</h2>

							<p className="sectionTitle__text text-white">
								Explore the Achievements of Others Through Learning
							</p>
						</div>
					</div>
				</div>

				<Spin spinning={isLoading}>
					{testimonials?.length ? (
						<div className="js-section-slider pt-50">
							{showSlider && (
								<Swiper
									className="overflow-visible"
									// {...setting}
									modules={[Navigation, Pagination]}
									navigation={{
										nextEl: ".js-next",
										prevEl: ".js-prev",
									}}
									loop={true}
									observer={true}
									observeParents={true}
									spaceBetween={30}
									slidesPerView={1}
									breakpoints={{
										// when window width is >= 576px
										450: {
											slidesPerView: 1,
										},
										// when window width is >= 768px
										768: {
											slidesPerView: 2,
										},
										1200: {
											// when window width is >= 992px
											slidesPerView: 3,
										},
									}}>
									{testimonials.map((elm, i) => (
										<SwiperSlide key={i} className="swiper-slide">
											<div
												className="testimonials -type-1"
												data-aos="fade-left"
												data-aos-duration={(i + 1) * 550}>
												<div className="testimonials__content">
													<h4 className="testimonials__title">{elm.comment}</h4>
													<div
														className="testimonials__text"
														style={{
															height: "120px",
															overflowY :'scroll'
														}}>
														{`“${elm.description}”`}
													</div>

													<div className="testimonials-footer">
														<div className="testimonials-footer__image">
															<Avatar src={elm.imageSrc} alt="testimonials" sx={{height:60, width: 60}}/>
														</div>

														<div className="testimonials-footer__content">
															<div className="testimonials-footer__title">
																{elm.name}
															</div>
															<div className="testimonials-footer__text">
																{elm.position}
															</div>
														</div>
													</div>
												</div>
											</div>
										</SwiperSlide>
									))}
								</Swiper>
							)}

							<div className="d-flex x-gap-20 items-center justify-end pt-60 lg:pt-40">
								<div className="col-auto">
									<button className="button -outline-white text-white size-50 rounded-full d-flex justify-center items-center js-prev">
										<i className="icon icon-arrow-left text-24"></i>
									</button>
								</div>
								<div className="col-auto">
									<button className="button -outline-white text-white size-50 rounded-full d-flex justify-center items-center js-next">
										<i className="icon icon-arrow-right text-24"></i>
									</button>
								</div>
							</div>
						</div>
					) : (
						<div className="mt-50">
							<Empty
								description={
									<p className="sectionTitle__text text-white">
										Testimonials not added yet.
									</p>
								}
							/>
						</div>
					)}
				</Spin>

				{/*<div className="row y-gap-30  counter__row">*/}
				{/*    {counters.map((elm, i) => (*/}
				{/*        <div*/}
				{/*            key={i}*/}
				{/*            className="col-lg-3 col-sm-6"*/}
				{/*            data-aos="fade-left"*/}
				{/*            data-aos-duration={(i + 1) * 350}*/}
				{/*        >*/}
				{/*            <div className="counter -type-1">*/}
				{/*                <div className="counter__number">{elm.number}</div>*/}
				{/*                <div className="counter__title">{elm.title}</div>*/}
				{/*            </div>*/}
				{/*        </div>*/}
				{/*    ))}*/}
				{/*</div>*/}
			</div>
		</section>
	);
}
