import React, { useEffect, useState } from "react";
import { Link, Navigate } from "react-router-dom";
import Header from "../../layout/headers/Header";
import { useDispatch } from "react-redux";
import {
	getCourseFromLocalCartAction,
	getCourseInCartAction,
} from "../../../redux/action/cartActions/cartActions";
import { BASE_URL } from "../../../config/config";
import PageNotFound from "./../../../pages/others/PageNotFound";
import LoadingPage from "../../../pages/others/LoadingPage";

const ReturnCheckoutPage = () => {
	const [status, setStatus] = useState(null);
	// const [customerEmail, setCustomerEmail] = useState("");
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(true);

	const loggedInUser = localStorage.getItem("loggedIn_user") ?? "{}";
	const parsedLoggedInData = JSON.parse(loggedInUser);

	const handleEmptyCart = () => {
		localStorage.removeItem("beauty_cart_items");
		if (parsedLoggedInData.id) {
			dispatch(getCourseInCartAction());
		} else {
			dispatch(getCourseFromLocalCartAction());
		}
	};

	useEffect(() => {
		const queryString = window.location.search;
		const urlParams = new URLSearchParams(queryString);
		const sessionId = urlParams.get("session_id");

		// authentication required
		fetch(`${BASE_URL}courses/session-status?session_id=${sessionId}`, {
			headers: {
				Authorization: localStorage.getItem("beauty_key")
					? `Bearer ${localStorage.getItem("beauty_key")}`
					: "",
				"Content-Type": "application/json",
			},
		})
			.then((res) => res.json())
			.then((data) => {
				setLoading(false);
				setStatus(data.status);
				// setCustomerEmail(data.customer_email);
			});
	}, []);

	if (status === "open") {
		return <Navigate to="/checkout" />;
	}

	if (loading) {
		return <LoadingPage/>;
	}

	if (status === "complete") {
		handleEmptyCart();
		return (
			<div className="main-content">
				<Header />
				<div className="content-wrapper js-content-wrapper overflow-hidden">
					<>
						<section className="page-header -type-1 mt-50">
							<div className="container">
								<div className="page-header__content">
									<div
										className="row justify-center text-center"
										style={{ marginTop: "20%" }}>
										<div className="col-auto">
											<div>
												<h1 className="page-header__title">
													Thank You for Your Purchase!
												</h1>
												<p
													className="page-header__text mt-30"
													style={{ maxWidth: "600px", textAlign: "justify" }}>
													Your course enrollment is complete. We're thrilled
													that you've chosen to enhance your skills with Pro
													Beauty Port. You can now access your course and start
													learning right away.
												</p>
											</div>

											<div className="lh-12 text-dark-1 fw-500 text-center mt-20">
												<Link onClick={()=>window.location.replace('/')} className="text-purple-1">
													Go to Dashboard
												</Link>
											</div>

											<div>
												
												{/* <p className="page-header__text">
													We appreciate your business! A confirmation email will
													be sent to your email : {customerEmail}.
												</p> */}
											</div>
										</div>
									</div>
								</div>
							</div>
						</section>
					</>
				</div>
			</div>
		);
	}

	return <PageNotFound />;
};

export default ReturnCheckoutPage;
