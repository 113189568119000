import React, { useEffect, useState } from "react";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	LinearProgress,
	styled,
	Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import LoadingButton from "@mui/lab/LoadingButton";
import { CloudUpload } from "@mui/icons-material";
import { getChaptersListAction } from "../../../../../../redux/action/myDashboardActions/chapterActions";
import {
	getCoursePreviewAction,
	getMediaUploadLink,
} from "../../../../../../redux/action/myDashboardActions/coursesActions";
import {
	LESSON_VIDEO_AWS_STARTING_PATH,
	LESSON_IMAGE_AWS_STARTING_PATH,
	LESSON_ATTACHMENT_AWS_STARTING_PATH,
} from "../../../../../../config/config";
import {
	addLessonsAction,
	updateLessonsAction,
} from "./../../../../../../redux/action/myDashboardActions/lessonActions";
import {
	ContentState,
	EditorState,
	convertToRaw,
	convertFromHTML,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
	"& .MuiDialogContent-root": {
		padding: theme.spacing(2),
	},
	"& .MuiDialogActions-root": {
		padding: theme.spacing(1),
	},
}));

const VisuallyHiddenInput = styled("input")({
	clip: "rect(0 0 0 0)",
	clipPath: "inset(50%)",
	height: 1,
	overflow: "hidden",
	position: "absolute",
	bottom: 0,
	left: 0,
	whiteSpace: "nowrap",
	width: 1,
});

const AddLessons = (props) => {
	const {
		open,
		setOpen,
		chapter,
		type,
		updateLessons,
		courseId,
		course_slug_name,
	} = props;
	const [lessonName, setLessonName] = useState("");
	const [lessonNameError, setLessonNameError] = useState("");
	const [description, setDescription] = useState(EditorState.createEmpty());
	const [loading, setLoading] = useState(false);
	const [thumbnailImageURL, setThumbnailImageURL] = useState("");
	const [thumbnailImage, setThumbnailImage] = useState("");
	const [videoURL, setVideoURL] = useState("");
	const [video, setVideo] = useState("");
	const [attachment, setAttachment] = useState("");
	const [attachmentURL, setAttachmentURL] = useState("");
	const [attachmentName, setAttachmentName] = useState("");
	const [videoDetail, setVideoDetail] = useState({
		duration: 0,
		formattedDuration: "",
	});
	const [imageUploadLoading, setImageUploadLoading] = useState(false);
	const [videoUploadLoading, setVideoUploadLoading] = useState(false);
	const [attachmentUploadLoading, setAttachmentUploadLoading] = useState(false);

	const [finalStatus, setFinalStatus] = useState({
		lesson: true,
	});

	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();

	const isVideoPresent = videoURL.includes("amazonaws");
	const isImagePresent = thumbnailImageURL.includes("amazonaws");
	const isAttachmentPresent = attachmentURL.includes("amazonaws");

	useEffect(() => {
		if (type === "update") {
			setLessonName(updateLessons.title);
			// for description
			const htmlContent = updateLessons?.video_obj?.description ?? "";
			// Convert the HTML content to ContentState
			const blocksFromHTML = convertFromHTML(htmlContent);
			const contentState = ContentState.createFromBlockArray(
				blocksFromHTML.contentBlocks,
				blocksFromHTML.entityMap
			);
			// Create EditorState with the obtained ContentState
			const descriptionEditorState =
				EditorState.createWithContent(contentState);
			// Set the EditorState value
			setDescription(descriptionEditorState);
			// here need to add feature image and video and attachments
			setThumbnailImageURL(updateLessons?.video_obj?.image?.url ?? "");
			setVideoURL(updateLessons?.video_obj?.key ?? "");
			// attachments pending...
			setAttachmentURL(updateLessons?.video_obj?.attachment?.url ?? "");
			if (updateLessons?.video_obj?.attachment?.key) {
				let url = updateLessons?.video_obj?.attachment?.key;
				let updatedAttachmentName = url.substring(url.lastIndexOf("/") + 1);
				setAttachmentName(updatedAttachmentName);
			}
		}
	}, [type, updateLessons]);

	const { data: SellerChapters } = useSelector(
		(state) => state.myDashboard.sellerChapters
	);

	const handleClose = () => {
		if (loading) {
			enqueueSnackbar(
				"Please do not close this screen until video is not uploaded.",
				{ variant: "info" }
			);
			return;
		} else {
			setOpen(false);
		}
	};

	// Function to format duration into hh:mm:ss
	const formatDuration = (duration) => {
		var hours = Math.floor(duration / 3600);
		var minutes = Math.floor((duration % 3600) / 60);
		var seconds = Math.floor(duration % 60);
		return (
			hours +
			":" +
			minutes.toString().padStart(2, "0") +
			":" +
			seconds.toString().padStart(2, "0")
		);
	};

	const handleImageChange = (event) => {
		if (event.target.files[0]) {
			setThumbnailImage(event.target.files[0]);
			const imageUrl = URL.createObjectURL(event.target.files[0]);
			setThumbnailImageURL(imageUrl);
			setFinalStatus((prevState) => ({
				...prevState,
				image: true,
			}));
		}
	};

	const handleVideoChange = (event) => {
		if (event.target.files[0]) {
			setVideo(event.target.files[0]);
			const videoUrl = URL.createObjectURL(event.target.files[0]);
			setVideoURL(videoUrl);

			setFinalStatus((prevState) => ({
				...prevState,
				video: true,
			}));

			// Create a temporary video element to retrieve metadata
			const tempVideo = document.createElement("video");
			tempVideo.src = videoUrl;
			// Once metadata is loaded, get duration
			tempVideo.addEventListener("loadedmetadata", () => {
				const duration = tempVideo.duration;
				const formattedDuration = formatDuration(duration);
				setVideoDetail({
					duration: duration,
					formattedDuration: formattedDuration,
				});
			});
		}
	};

	const handleFileChange = (event) => {
		if (event.target.files[0]) {
			setAttachment(event.target.files[0]);
			setAttachmentName(event.target.files[0].name);
			const attachmentUrl = URL.createObjectURL(event.target.files[0]);
			setAttachmentURL(attachmentUrl);
			setFinalStatus((prevState) => ({
				...prevState,
				attachment: true,
			}));
		}
	};

	// Function to generate a random string
	function generateRandomString(length) {
		const characters =
			"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
		let result = "";
		for (let i = 0; i < length; i++) {
			result += characters.charAt(
				Math.floor(Math.random() * characters.length)
			);
		}
		return result;
	}

	// Function to generate a unique filename
	function generateUniqueFilename() {
		const timestamp = new Date().getTime(); // Current timestamp
		const randomString = generateRandomString(8); // Generate a random string of length 8
		return `${timestamp}_${randomString}`;
	}

	const handleFinalStatus = (fileType) => {
		let temporaryStatus = finalStatus;

		const operateFinalSteps = () => {
			enqueueSnackbar(
				type === "update"
					? "Lesson Updated Successfully..."
					: "Lesson Created Successfully...",
				{
					variant: "success",
				}
			);
			setLessonName("");
			setLessonNameError("");
			setDescription("");
			dispatch(getChaptersListAction(courseId));
			props?.handleShowAutoText?.();
			course_slug_name && dispatch(getCoursePreviewAction(course_slug_name));
			handleClose();
		};

		if (fileType === "IMAGE") {
			delete temporaryStatus.lesson;
			if (Object.keys(temporaryStatus).length === 1) {
				operateFinalSteps();
				return;
			}
			delete temporaryStatus.image;
			if (Object.keys(temporaryStatus).length === 1) {
				if (
					temporaryStatus?.video === false ||
					temporaryStatus?.attachment === false
				) {
					operateFinalSteps();
					return;
				}
			}
			if (Object.keys(temporaryStatus).length === 2) {
				if (
					temporaryStatus?.video === false &&
					temporaryStatus?.attachment === false
				) {
					operateFinalSteps();
					return;
				}
			}
		} else if (fileType === "VIDEO") {
			delete temporaryStatus.lesson;
			if (Object.keys(temporaryStatus).length === 1) {
				operateFinalSteps();
				return;
			}
			delete temporaryStatus.video;
			if (Object.keys(temporaryStatus).length === 1) {
				if (
					temporaryStatus?.image === false ||
					temporaryStatus?.attachment === false
				) {
					operateFinalSteps();
					return;
				}
			}
			if (Object.keys(temporaryStatus).length === 2) {
				if (
					temporaryStatus?.image === false &&
					temporaryStatus?.attachment === false
				) {
					operateFinalSteps();
					return;
				}
			}
		} else if (fileType === "ATTACHMENT") {
			delete temporaryStatus.lesson;
			if (Object.keys(temporaryStatus).length === 1) {
				operateFinalSteps();
				return;
			}
			delete temporaryStatus.attachment;
			if (Object.keys(temporaryStatus).length === 1) {
				if (
					temporaryStatus?.image === false ||
					temporaryStatus?.video === false
				) {
					operateFinalSteps();
					return;
				}
			}
			if (Object.keys(temporaryStatus).length === 2) {
				if (
					temporaryStatus?.image === false &&
					temporaryStatus?.video === false
				) {
					operateFinalSteps();
					return;
				}
			}
		}
	};

	const handleUpdateLessonAfterCreating = (body, fileType, lesson_id) => {
		dispatch(
			updateLessonsAction(body, lesson_id, {
				onLoading: (loading) => {
					setLoading(true);
				},
				onError: (error) => {
					setLoading(false);
					let errorMessage = String(error?.message);
					enqueueSnackbar(errorMessage, { variant: "error" });
					if (fileType === "IMAGE") {
						setImageUploadLoading(false);
					} else if (fileType === "VIDEO") {
						setVideoUploadLoading(false);
					} else if (fileType === "ATTACHMENT") {
						setAttachmentUploadLoading(false);
					}
				},
				onSuccess: (success) => {
					setLoading(false);
					if (fileType === "IMAGE") {
						setFinalStatus((prevState) => ({
							...prevState,
							image: false,
						}));
						enqueueSnackbar("Image Added Successfully", {
							variant: "success",
						});
						setImageUploadLoading(false);
						handleFinalStatus("IMAGE");
					} else if (fileType === "VIDEO") {
						setFinalStatus((prevState) => ({
							...prevState,
							video: false,
						}));
						enqueueSnackbar("Video Added Successfully", {
							variant: "success",
						});
						setVideoUploadLoading(false);
						handleFinalStatus("VIDEO");
					} else if (fileType === "ATTACHMENT") {
						setFinalStatus((prevState) => ({
							...prevState,
							attachment: false,
						}));
						enqueueSnackbar("Attachment Added Successfully", {
							variant: "success",
						});
						setAttachmentUploadLoading(false);
						handleFinalStatus("ATTACHMENT");
					}
				},
			})
		);
	};

	const uploadFilesOnStorage = async (
		url,
		file,
		fileName,
		fileType,
		lesson_id
	) => {
		if (file) {
			setLoading(true);
			const renamedFile = new File([file], fileName, { type: file.type });

			try {
				await fetch(url, {
					method: "PUT",
					body: renamedFile,
				});

				let body = {
					chapter: chapter.id,
				};

				if (fileType === "IMAGE") {
					body = {
						image: `${LESSON_IMAGE_AWS_STARTING_PATH}${fileName}`,
					};
				} else if (fileType === "VIDEO") {
					body = {
						duration: videoDetail.formattedDuration,
						video: `${LESSON_VIDEO_AWS_STARTING_PATH}${fileName}`,
					};
				} else if (fileType === "ATTACHMENT") {
					body = {
						attachment: `${LESSON_ATTACHMENT_AWS_STARTING_PATH}${fileName}`,
					};
				}
				handleUpdateLessonAfterCreating(body, fileType, lesson_id);
			} catch (error) {
				enqueueSnackbar(error, { variant: "error" });
				setLoading(false);
			}
		}
	};

	const handleImageUpload = async (lesson_id) => {
		// Handle image upload here
		setImageUploadLoading(true);
		const ImageExtension = thumbnailImage.name.split(".").pop();
		const imageName = generateUniqueFilename() + "." + ImageExtension;

		dispatch(
			getMediaUploadLink(`${LESSON_IMAGE_AWS_STARTING_PATH}${imageName}`, {
				onLoading: (loading) => {
					setImageUploadLoading(true);
				},
				onError: (error) => {
					setImageUploadLoading(false);
					let errorMessage = String(error?.message ?? "Something went wrong");
					enqueueSnackbar(errorMessage, { variant: "error" });
				},
				onSuccess: (success) => {
					uploadFilesOnStorage(
						success?.data?.signed_url,
						thumbnailImage,
						imageName,
						"IMAGE",
						lesson_id
					);
				},
			})
		);
	};

	const handleVideoUpload = async (lesson_id) => {
		// Handle video upload here
		setVideoUploadLoading(true);
		const VideoExtension = video.name.split(".").pop();
		const videoName = generateUniqueFilename() + "." + VideoExtension;
		dispatch(
			getMediaUploadLink(`${LESSON_VIDEO_AWS_STARTING_PATH}${videoName}`, {
				onLoading: (loading) => {
					setVideoUploadLoading(true);
				},
				onError: (error) => {
					setVideoUploadLoading(false);
					let errorMessage = String(error?.message ?? "Something went wrong");
					enqueueSnackbar(errorMessage, { variant: "error" });
				},
				onSuccess: (success) => {
					uploadFilesOnStorage(
						success?.data?.signed_url,
						video,
						videoName,
						"VIDEO",
						lesson_id
					);
				},
			})
		);
	};

	const handleAttachmentUpload = async (lesson_id) => {
		// Handle attachment upload here
		setAttachmentUploadLoading(true);
		const AttachmentExtension = attachment.name.split(".").pop();
		const attachmentName = generateUniqueFilename() + "." + AttachmentExtension;

		dispatch(
			getMediaUploadLink(
				`${LESSON_ATTACHMENT_AWS_STARTING_PATH}${attachmentName}`,
				{
					onLoading: (loading) => {
						setAttachmentUploadLoading(true);
					},
					onError: (error) => {
						setAttachmentUploadLoading(false);
						let errorMessage = String(error?.message ?? "Something went wrong");
						enqueueSnackbar(errorMessage, { variant: "error" });
					},
					onSuccess: (success) => {
						uploadFilesOnStorage(
							success?.data?.signed_url,
							attachment,
							attachmentName,
							"ATTACHMENT",
							lesson_id
						);
					},
				}
			)
		);
	};

	const createLessonFirst = (body) => {
		dispatch(
			addLessonsAction(body, {
				onLoading: (loading) => {
					setLoading(true);
					enqueueSnackbar("Loading...", { variant: "info" });
				},
				onError: (error) => {
					setLoading(false);
					let errorMessage = String(error?.message);
					enqueueSnackbar(errorMessage, { variant: "error" });
				},
				onSuccess: (success) => {
					let lesson_id = success.data.id;
					if (thumbnailImageURL || videoURL || attachmentURL) {
						setLoading(true);
						enqueueSnackbar("Uploding process may take sometime...", {
							variant: "info",
						});
					} else {
						setLoading(false);
						enqueueSnackbar("Lesson Created Successfully...", {
							variant: "info",
						});
						props?.handleShowAutoText?.();
						course_slug_name &&
							dispatch(getCoursePreviewAction(course_slug_name));
						setLessonName("");
						setLessonNameError("");
						setDescription("");
						dispatch(getChaptersListAction(courseId));
						handleClose();
					}
					// after adding ... we will check if video or image present , then individually we will update video and image into update lesson api...
					if (thumbnailImageURL) {
						handleImageUpload(lesson_id);
					}
					if (videoURL) {
						handleVideoUpload(lesson_id);
					}
					if (attachmentURL) {
						handleAttachmentUpload(lesson_id);
					}
				},
			})
		);
	};

	const updateLessonFirst = (body) => {
		dispatch(
			updateLessonsAction(body, updateLessons.id, {
				onLoading: (loading) => {
					setLoading(true);
					enqueueSnackbar("Loading...", { variant: "info" });
				},
				onError: (error) => {
					setLoading(false);
					let errorMessage = String(error?.message);
					enqueueSnackbar(errorMessage, { variant: "error" });
				},
				onSuccess: (success) => {
					let lesson_id = success.data.id;
					if (thumbnailImage || video || attachment) {
						setLoading(true);
						enqueueSnackbar("Uploding process may take sometime...", {
							variant: "info",
						});
					} else {
						setLoading(false);
						enqueueSnackbar("Lesson Updated Successfully...", {
							variant: "info",
						});
						props?.handleShowAutoText?.();
						course_slug_name &&
							dispatch(getCoursePreviewAction(course_slug_name));
						setLessonName("");
						setLessonNameError("");
						setDescription("");
						dispatch(getChaptersListAction(courseId));
						handleClose();
					}
					// after adding ... we will check if video or image present , then individually we will update video and image into update lesson api...

					if (!isImagePresent && thumbnailImageURL) {
						handleImageUpload(lesson_id);
					}
					if (!isVideoPresent && videoURL) {
						handleVideoUpload(lesson_id);
					}
					if (!isAttachmentPresent && attachmentURL) {
						handleAttachmentUpload(lesson_id);
					}
				},
			})
		);
	};

	const handleSubmit = async () => {
		if (lessonName.trim().length === 0) {
			setLessonNameError("Lesson Name is Required");
			return;
		}

		const descriptionHtml = draftToHtml(
			convertToRaw(description.getCurrentContent())
		);

		if (type === "add") {
			// handle add
			let body = {
				chapter: chapter.id,
				title: lessonName,
				description: descriptionHtml,
				order_no: (SellerChapters?.lessons?.length ?? 0) + 1,
				duration: null,
				video: null,
			};
			// Create the lesson first
			createLessonFirst(body);
		} else {
			// handle update
			let body = {
				title: lessonName,
				description: descriptionHtml,
			};
			// Update the lesson first
			updateLessonFirst(body);
		}
	};

	const handleImageDelete = () => {
		setThumbnailImage("");
		setThumbnailImageURL("");
		document.getElementById("lessonImageUpload1").value = "";
		if (type === "add") {
			// No API Call
			enqueueSnackbar("Image Removed Successfully", {
				variant: "success",
			});
		} else {
			// api call in case of update
			setImageUploadLoading(true);
			let body = {
				image: null,
			};
			// Update the lesson first
			dispatch(
				updateLessonsAction(body, updateLessons.id, {
					onLoading: (loading) => {
						setImageUploadLoading(true);
					},
					onError: (error) => {
						let errorMessage = String(error?.message);
						enqueueSnackbar(errorMessage, { variant: "error" });
						setImageUploadLoading(false);
					},
					onSuccess: (success) => {
						enqueueSnackbar("Image Removed Successfully", {
							variant: "success",
						});
						setImageUploadLoading(false);
						dispatch(getChaptersListAction(courseId));
					},
				})
			);
		}
	};

	const handleVideoDelete = () => {
		setVideo("");
		setVideoURL("");
		setVideoDetail({
			duration: 0,
			formattedDuration: "",
		});
		document.getElementById("lessonVideoUpload1").value = "";
		if (type === "add") {
			// No API Call
			enqueueSnackbar("Video Removed Successfully", {
				variant: "success",
			});
		} else {
			// api call in case of update
			setVideoUploadLoading(true);
			let body = {
				duration: null,
				video: null,
			};
			// Update the lesson first
			dispatch(
				updateLessonsAction(body, updateLessons.id, {
					onLoading: (loading) => {
						setVideoUploadLoading(true);
					},
					onError: (error) => {
						let errorMessage = String(error?.message);
						enqueueSnackbar(errorMessage, { variant: "error" });
						setVideoUploadLoading(false);
					},
					onSuccess: (success) => {
						enqueueSnackbar("Video Removed Successfully", {
							variant: "success",
						});
						setVideoUploadLoading(false);
						dispatch(getChaptersListAction(courseId));
					},
				})
			);
		}
	};

	const handleAttachmentDelete = () => {
		setAttachment("");
		setAttachmentName("");
		setAttachmentURL("");
		document.getElementById("lessonAttachmentUpload1").value = "";
		if (type === "add") {
			// No API Call
			enqueueSnackbar("Attachment Removed Successfully", {
				variant: "success",
			});
		} else {
			// api call in case of update
			setAttachmentUploadLoading(true);
			let body = {
				attachment: null,
			};
			// Update the lesson first
			dispatch(
				updateLessonsAction(body, updateLessons.id, {
					onLoading: (loading) => {
						setAttachmentUploadLoading(true);
					},
					onError: (error) => {
						let errorMessage = String(error?.message);
						enqueueSnackbar(errorMessage, { variant: "error" });
						setAttachmentUploadLoading(false);
					},
					onSuccess: (success) => {
						enqueueSnackbar("Attachment Removed Successfully", {
							variant: "success",
						});
						setAttachmentUploadLoading(false);
						dispatch(getChaptersListAction(courseId));
					},
				})
			);
		}
	};

	return (
		<BootstrapDialog
			onClose={handleClose}
			aria-labelledby="customized-dialog-title"
			open={open}
			maxWidth={"md"}
			fullWidth>
			<DialogTitle sx={{ m: 0, p: 2, pb: 1 }} id="customized-dialog-title">
				{type === "update" ? "Update Lesson" : "Add Lesson"}
				{/* <br />
				<Typography variant="subtitle1" color={"#4f547b"}>
					( Recommended Resolution : 1920 * 1080 )
				</Typography> */}
				{(loading ||
					imageUploadLoading ||
					videoUploadLoading ||
					attachmentUploadLoading) && (
					<Typography variant="subtitle1" className="text-red-1">
						Please do not close this screen until files are not uploaded.
					</Typography>
				)}
			</DialogTitle>
			<IconButton
				aria-label="close"
				onClick={handleClose}
				sx={{
					position: "absolute",
					right: 8,
					top: 8,
					color: (theme) => theme.palette.grey[500],
				}}>
				<CloseIcon />
			</IconButton>
			<DialogContent dividers>
				<form className="contact-form row y-gap-30">
					<div className="col-12">
						<label className="text-16 lh-1 fw-500 text-dark-1 mb-10">
							Lesson Name<span className="text-red">*</span>
						</label>
						<input
							required
							type="text"
							value={lessonName}
							onChange={(e) => setLessonName(e.target.value)}
							placeholder="Learn Something..."
						/>
						{Boolean(lessonNameError) && (
							<span className="error-message">{lessonNameError}</span>
						)}
					</div>
					<div className="col-12">
						<label className="text-16 lh-1 fw-500 text-dark-1 mb-10">
							Description
						</label>
						<div
							style={{
								border: "1px solid #ccc",
								minHeight: "300px",
								padding: "5px",
							}}>
							<Editor
								editorState={description}
								onEditorStateChange={(editableText) =>
									setDescription(editableText)
								}
								stripPastedStyles={false}
							/>
						</div>
					</div>
					<div className="col-12">
						<label className="text-16 lh-1 fw-500 text-dark-1 mb-10">
							Feature Image
						</label>
						<br />
						<Typography variant="subtitle1" color={"#4f547b"}>
							( Recommended Resolution : 1920 * 1080 )
						</Typography>
						<div
							className="relative shrink-0 pt-10"
							style={
								thumbnailImageURL
									? {}
									: {
											backgroundColor: "#f2f3f4",
											width: 350,
											height: 200,
									  }
							}>
							{thumbnailImageURL && (
								<div style={{ position: "relative", display: "inline-block" }}>
									<img
										key={thumbnailImageURL}
										className="w-1/1"
										style={{
											maxWidth: "350px",
											height: "200px",
											maxHeight: "200px",
											objectFit: "cover",
											objectPosition: "center",
										}}
										src={thumbnailImageURL}
										alt="thumbnail"
									/>
									<div className="absolute-full-center d-flex justify-end py-20 px-20">
										<span
											style={{ cursor: "pointer" }}
											onClick={() => handleImageDelete()}
											className="d-flex justify-center items-center bg-white size-40 rounded-8 shadow-1">
											<i className="icon-bin text-16"></i>
										</span>
									</div>
									{imageUploadLoading ? (
										<LinearProgress style={{ maxWidth: "350px" }} />
									) : null}
								</div>
							)}
						</div>
						<div className="pt-20 items-center">
							<Button
								component="label"
								role={undefined}
								tabIndex={-1}
								className="button h-50 px-25 -outline-dark-1 text-dark-1"
								startIcon={<CloudUpload />}>
								Choose Image
								<VisuallyHiddenInput
									type="file"
									accept="image/*"
									onChange={handleImageChange}
									id="lessonImageUpload1"
								/>
							</Button>
						</div>
					</div>

					<div className="col-12">
						<label className="text-16 lh-1 fw-500 text-dark-1 mb-10">
							Feature Video
						</label>
						<br />
						<Typography variant="subtitle1" color={"#4f547b"}>
							( Recommended Resolution : 1920 * 1080 )
						</Typography>
						<div
							className="relative shrink-0 pt-10"
							style={
								videoURL
									? {}
									: {
											backgroundColor: "#f2f3f4",
											width: 350,
											height: 200,
									  }
							}>
							{videoURL && (
								<div style={{ position: "relative", display: "inline-block" }}>
									<video
										key={videoURL}
										className="w-1/1"
										style={{
											maxWidth: "350px",
											height: "200px",
											maxHeight: "200px",
											objectFit: "cover",
											objectPosition: "center",
										}}
										controls>
										<source src={videoURL} type="video/mp4" />
										Your browser does not support the video tag.
									</video>
									<div className="absolute-full-center d-flex justify-end py-20 px-20">
										<span
											style={{ cursor: "pointer" }}
											onClick={() => handleVideoDelete()}
											className="d-flex justify-center items-center bg-white size-40 rounded-8 shadow-1">
											<i className="icon-bin text-16"></i>
										</span>
									</div>
									{videoUploadLoading ? (
										<LinearProgress style={{ maxWidth: "350px" }} />
									) : null}
									<br />
									{videoDetail.duration
										? `Duration: ${videoDetail.formattedDuration}`
										: null}
								</div>
							)}
						</div>
						<div className="pt-20 items-center">
							<Button
								component="label"
								role={undefined}
								tabIndex={-1}
								className="button h-50 px-25 -outline-dark-1 text-dark-1"
								startIcon={<CloudUpload />}>
								Choose Video
								<VisuallyHiddenInput
									type="file"
									accept="video/*"
									onChange={handleVideoChange}
									id="lessonVideoUpload1"
								/>
							</Button>
						</div>
					</div>

					{/* Attachment starts here */}
					<div className="form-upload col-12 mb-30">
						<label className="text-16 lh-1 fw-500 text-dark-1 mb-10">
							Attachment
						</label>
						<div className="form-upload__wrap d-flex">
							<div style={{ position: "relative", flex: "1" }}>
								<input
									type="text"
									value={attachmentName}
									readOnly
									placeholder={"Click on Upload File button to select file"}
									style={{ width: "100%" }}
								/>
								{/* Add the cross icon inside a span */}
								{attachmentURL && (
									<span
										className="cross-icon"
										onClick={()=>handleAttachmentDelete()}
										style={{
											position: "absolute",
											top: "50%",
											right: "10px",
											transform: "translateY(-50%)",
											cursor: "pointer",
										}}>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="20"
											height="20"
											viewBox="0 0 24 24">
											<path d="M12 10.586l5.293-5.293 1.414 1.414-5.293 5.293 5.293 5.293-1.414 1.414-5.293-5.293-5.293 5.293-1.414-1.414 5.293-5.293-5.293-5.293 1.414-1.414z" />
										</svg>
									</span>
								)}
							</div>
							<Button
								component="label"
								role={undefined}
								tabIndex={-1}
								className="button -dark-3 text-white"
								style={{ flexBasis: "200px" }}
								startIcon={<CloudUpload />}>
								Upload File
								<VisuallyHiddenInput
									type="file"
									accept=".pdf, .xlsx, .xls, .doc, .docx, .zip"
									onChange={handleFileChange}
									id="lessonAttachmentUpload1"
								/>
							</Button>
						</div>
						<p className="mt-10">
							Upload your file here. Important guidelines: .pdf, .xlsx, .xls,
							.doc, .docx, .zip accepted.
						</p>
					</div>
					{/* Attachment ends here  */}
				</form>
			</DialogContent>
			<DialogActions>
				<LoadingButton
					variant="contained"
					loading={
						loading ||
						imageUploadLoading ||
						videoUploadLoading ||
						attachmentUploadLoading
					}
					autoFocus
					onClick={handleSubmit}
					className="button h-50 px-25 -dark-1 -dark-button-white text-dark"
					disabled={Boolean(!lessonName.trim().length)}>
					Save
				</LoadingButton>
			</DialogActions>
		</BootstrapDialog>
	);
};

export default AddLessons;
