import { Divider } from "@mui/material";
import React, { useCallback, useEffect } from "react";
import { SuperAllCourses } from "./SuperAllCourses";
import { useDispatch, useSelector } from "react-redux";
import {
	AddSuperPopularCourseAction,
	getSuperPopularCourses,
	getSuperUnPopularCourses,
	removeSuperPopularCourseAction,
    updateSuperPopularCourseAction,
} from "../../../../redux/action/superAdminActions/superCoursesActions";
import { SuperOnlyPopularCourses } from "./SuperOnlyPopularCourses";

export default function SuperPopularCourses() {
	const dispatch = useDispatch();

	const { popularCourses, unPopularCourses } = useSelector(
		(state) => state.superAdmin.superCourses
	);

	const lastPopularCourseOrderNumber = popularCourses?.data?.length
		? popularCourses.data[popularCourses.data.length - 1].order_no
		: 0;

	useEffect(() => {
		if (!localStorage.getItem("beauty_super_key")) {
			window.location.replace("login");
		}
	}, []);

	const fetchPopularCourses = useCallback(() => {
		dispatch(getSuperPopularCourses());
	}, [dispatch]);

	const fetchUnPopularCourses = useCallback(() => {
		dispatch(getSuperUnPopularCourses());
	}, [dispatch]);

	useEffect(() => {
		fetchPopularCourses();
		fetchUnPopularCourses();
	}, [fetchPopularCourses, fetchUnPopularCourses]);

	const handleCheckBoxClicked = (checked, data) => {
		if (checked) {
			// add to popular course
			let body = {
				course: data.id,
				order_no: Number(lastPopularCourseOrderNumber) + 1,
			};
			dispatch(
				AddSuperPopularCourseAction(body, {
					onSuccess: () => {
						fetchPopularCourses();
						fetchUnPopularCourses();
					},
				})
			);
		} else {
			// remove from popular course
			dispatch(
				removeSuperPopularCourseAction(data.id, {
					onSuccess: () => {
						fetchPopularCourses();
						fetchUnPopularCourses();
					},
				})
			);
		}
	};

	const handleUnPopularPageChange = (value) => {
		let body = {
			page: value,
		};
		dispatch(getSuperUnPopularCourses(body));
	};

    const handleMoveIconClicked =(key,data)=>{
        let body = {
            id: data.id,
            [key] : true
        }
        dispatch(
            updateSuperPopularCourseAction(body, {
                onSuccess: () => {
                    fetchPopularCourses();
                },
            })
        );
    }

	return (
		<div className="dashboard__main">
			<div className="dashboard__content bg-light-4">
				<div className="row pb-50 mb-10">
					<div className="col-auto">
						<h1 className="text-30 lh-12 fw-700">Popular Courses</h1>
						<div className="mt-10">Maximum 8 courses can be popular.</div>
					</div>
				</div>
				<div className="row pb-50 mb-10">
					<div className="col-24">
						<SuperOnlyPopularCourses
							courseObject={popularCourses}
							handleCheckBoxClicked={handleCheckBoxClicked}
                            handleMoveIconClicked={handleMoveIconClicked}
						/>
					</div>
				</div>
				<Divider style={{ margin: "20px 0px" }} />
				<div className="row pb-50 mb-10">
					<div className="col-auto">
						<h1 className="text-30 lh-12 fw-700">All Courses</h1>
						<div className="mt-10">
							Click on Checkbox to make it popular course.
						</div>
					</div>
				</div>
				<div className="row pb-50 mb-10">
					<div className="col-24">
						<SuperAllCourses
							courseObject={unPopularCourses}
							handleCheckBoxClicked={handleCheckBoxClicked}
							handlePageChange={handleUnPopularPageChange}
						/>
					</div>
				</div>
			</div>

			{/*<FooterNine />*/}
		</div>
	);
}
