//--------LOG OUT--------
export const LOG_OUT = "LOG_OUT";

//--------Password_Reset--------
export const PASSWORD_FORGET = "PASSWORD_FORGET";
export const PASSWORD_RESET = "PASSWORD_RESET";

//--------SIGN_UP--------
export const CUSTOMER_SIGN_UP = "CUSTOMER_SIGN_UP";
export const TRAINER_SIGN_UP = "TRAINER_SIGN_UP";

// -------------- SIGN_IN -------------
export const CUSTOMER_SIGN_IN = "CUSTOMER_SIGN_IN";

export const CLEAR_REDUX = "CLEAR_REDUX";

// -------------- HomePage________________________

export const GET_TESTIMONIALS = "GET_TESTIMONIALS";
export const GET_COURSE_CATEGORIES = "GET_COURSE_CATEGORIES";
export const ADD_NEWSLETTER = "ADD_NEWSLETTER"


// ---------------- SUPER ADMIN ---------------------------

export const ADD_SUPER_CATEGORIES = "ADD_SUPER_CATEGORIES";
export const UPDATE_SUPER_CATEGORIES = "UPDATE_SUPER_CATEGORIES";
export const REMOVE_SUPER_CATEGORIES = "REMOVE_SUPER_CATEGORIES";
export const GET_SUPER_CATEGORIES = "GET_SUPER_CATEGORIES";

export const ADD_SUPER_SUB_CATEGORIES = "ADD_SUPER_SUB_CATEGORIES";
export const UPDATE_SUPER_SUB_CATEGORIES = "UPDATE_SUPER_SUB_CATEGORIES";
export const REMOVE_SUPER_SUB_CATEGORIES = "REMOVE_SUPER_SUB_CATEGORIES";
export const GET_SUPER_SUB_CATEGORIES = "GET_SUPER_SUB_CATEGORIES";

export const GET_SUPER_TESTIMONIALS = "GET_SUPER_TESTIMONIALS";
export const ADD_SUPER_TESTIMONIALS = "ADD_SUPER_TESTIMONIALS";
export const UPDATE_SUPER_TESTIMONIALS = "UPDATE_SUPER_TESTIMONIALS";
export const REMOVE_SUPER_TESTIMONIALS = "REMOVE_SUPER_TESTIMONIALS";

export const GET_SUPER_INSTRUCTORS = "GET_SUPER_INSTRUCTORS";
export const ADD_SUPER_INSTRUCTORS = "ADD_SUPER_INSTRUCTORS";
export const UPDATE_SUPER_INSTRUCTORS = "UPDATE_SUPER_INSTRUCTORS";
export const REMOVE_SUPER_INSTRUCTORS = "REMOVE_SUPER_INSTRUCTORS";

export const GET_SUPER_POPULAR_COURSES = "GET_SUPER_POPULAR_COURSES";
export const GET_SUPER_UN_POPULAR_COURSES = "GET_SUPER_UN_POPULAR_COURSES";
export const REMOVE_SUPER_POPULAR_COURSE = "REMOVE_SUPER_POPULAR_COURSE";
export const ADD_SUPER_POPULAR_COURSE = "ADD_SUPER_POPULAR_COURSE";
export const UPDATE_SUPER_POPULAR_COURSE = "UPDATE_SUPER_POPULAR_COURSE";

export const GET_SUPER_STUDENTS = "GET_SUPER_STUDENTS";

export const GET_SUPER_PAYMENTS_DATA = "GET_SUPER_PAYMENTS_DATA"

export const GET_SUPER_COURSE_LANGUAGE_DATA = "GET_SUPER_COURSE_LANGUAGE_DATA"

export const GET_SUPER_NEWSLETTER_DATA = "GET_SUPER_NEWSLETTER_DATA"

export const GET_SUPER_CONTACT_US_LIST_DATA = "GET_SUPER_CONTACT_US_LIST_DATA"

export const GET_AWS_LINK = "GET_AWS_LINK";

// ---------------- INSTRUCTOR ---------------------------

export const ADD_COURSE = "ADD_COURSE";
export const GET_COURSE = "GET_COURSE";
export const UPDATE_COURSE = "UPDATE_COURSE";
export const DELETE_COURSE = "DELETE_COURSE";
export const GET_SELLER_COURSE = "GET_SELLER_COURSE";
export const GET_COURSE_DETAILS = "GET_COURSE_DETAILS";
export const EMPTY_COURSE_DETAILS = "EMPTY_COURSE_DETAILS"
export const GET_COURSE_PREVIEW = "GET_COURSE_PREVIEW";
export const EMPTY_COURSE_PREVIEW = "EMPTY_COURSE_PREVIEW"

export const ADD_CHAPTERS = "ADD_CHAPTERS";
export const GET_CHAPTERS = "GET_CHAPTERS";
export const UPDATE_CHAPTERS = "UPDATE_CHAPTERS";
export const DELETE_CHAPTERS = "DELETE_CHAPTERS";

export const ADD_LESSONS = "ADD_LESSONS";
export const GET_LESSONS = "GET_LESSONS";
export const UPDATE_LESSONS = "UPDATE_LESSONS";
export const DELETE_LESSONS = "DELETE_LESSONS";

// ------------------- TRAINER -----------------------

export const GET_TRAINERS_LIST = "GET_TRAINERS_LIST"
export const GET_TRAINER_DETAIL = "GET_TRAINER_DETAIL"
export const UPDATE_TRAINER_DETAIL = "UPDATE_TRAINER_DETAIL"

// ------------------- TRAINER -----------------------

export const GET_CUSTOMER_COURSE_LIST = "GET_CUSTOMER_COURSE_LIST"
export const GET_CUSTOMER_COURSE_DETAIL = "GET_CUSTOMER_COURSE_DETAIL"
export const GET_CUSTOMER_COURSE_FILTER_LIST = "GET_CUSTOMER_COURSE_FILTER_LIST"

export const GET_TOP_CATEGORIES = "GET_TOP_CATEGORIES"

export const ADD_COURSE_RATING = "ADD_COURSE_RATING"

export const ADD_COURSE_IN_CART = "ADD_COURSE_IN_CART"
export const DELETE_COURSE_IN_CART = "DELETE_COURSE_IN_CART"
export const GET_COURSE_IN_CART = "GET_COURSE_IN_CART"

export const ADD_COURSE_IN_LOCAL_CART = "ADD_COURSE_IN_LOCAL_CART"
export const DELETE_COURSE_IN_LOCAL_CART = "DELETE_COURSE_IN_LOCAL_CART"
export const GET_COURSE_FROM_LOCAL_CART = "GET_COURSE_FROM_LOCAL_CART"


// --------------------------- CUSTOMER ____________________________________

export const GET_CUSTOMER_ENROLLED_COURSES = "GET_CUSTOMER_ENROLLED_COURSES"
export const GET_ENROLLED_COURSE_DETAIL = "GET_ENROLLED_COURSE_DETAIL"

export const GET_MOST_POPULAR_COURSES = "GET_MOST_POPULAR_COURSES"

export const GET_CUSTOMER_DETAIL = "GET_CUSTOMER_DETAIL"
export const UPDATE_CUSTOMER_DETAIL = "UPDATE_CUSTOMER_DETAIL"

export const GET_USER_DASHBOARD = "GET_USER_DASHBOARD"
export const GET_SELLER_DASHBOARD = "GET_SELLER_DASHBOARD"

export const GET_COURSE_CERTIFICATE = "GET_COURSE_CERTIFICATE" 

export const GET_PAYMENT_HISTORY = "GET_PAYMENT_HISTORY"
export const GET_SELLER_COURSE_TRANSACTION_HISTORY = "GET_SELLER_COURSE_TRANSACTION_HISTORY"

export const SIDE_BAR_TOGGLE_CLICKED = "SIDE_BAR_TOGGLE_CLICKED"

export const FORGOT_PASSWORD = "FORGOT_PASSWORD"
export const RESET_PASSWORD = "RESET_PASSWORD"
export const UPDATE_PASSWORD = "UPDATE_PASSWORD"
export const VERIFY_EMAIL = "VERIFY_EMAIL"

export const GET_BOOKMARK_COURSES = "GET_BOOKMARK_COURSES"

export const GET_TRAINER_COURSES_REVIEWS = "GET_TRAINER_COURSES_REVIEWS"

export const SEND_CONTACT_US_MESSAGE = "SEND_CONTACT_US_MESSAGE"