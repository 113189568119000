import React, { useEffect, useState } from "react";
import {Route, Routes} from "react-router-dom";
import { COMPANY_NAME } from "../../../config/config";
import MetaComponent from "../../../components/common/MetaComponent";
import Preloader from "../../../components/common/Preloader";
import PageNotFound from "../../others/PageNotFound";
import LoggedInHeader from "../headers/LoggedInHeader";
import LoggedInSidebar from "./seller/sidebar/LoggedInSidebar";
import MainDashboard from "./pages/seller/mainDashboard/MainDashboard";
import CreateCourse from "./pages/seller/courses/CreateCourses";
import CourseList from "./pages/seller/courses/CourseList";
import CustomerSidebar from "./customer/sidebar/CustomerSidebar";
import SellerSettings from "./pages/seller/sellerSettings/SellerSettings";
import UserDashboard from "./pages/customer/mainDashboard/UserDashboard";
import UserSettings from "./pages/customer/userSettings/UserSettings";
import UserCourses from "./pages/customer/loggedInCourses/UserCourses";
import BillingList from "./pages/seller/billing/BillingList";
import EnrolledCourseDetailPage from "./pages/customer/loggedInCourses/EnrolledCourseDetailPage";
import SellerCoursePaymentHistory from "./pages/seller/billing/SellerCoursePaymentHistory";
import MainReviewPage from "./pages/seller/review/MainReviewPage";
import BookmarkCourseList from "./pages/seller/courses/BookmarkCourseList";

const metadata = {
    title:
        `My Dashboard - ${COMPANY_NAME}`,
    description:
        `Elevate your e-learning content with ${COMPANY_NAME}, the most impressive LMS template for online courses, education and LMS platforms.`,
};

export default function Dashboard(props) {
    const [isSellerLogin, setIsSellerLogin] = useState(false);
    const [isBuyerLogin, setIsBuyerLogin] = useState(false);

    useEffect(()=>{
        setIsBuyerLogin(localStorage.getItem('beauty_buyer') === 'true')
        setIsSellerLogin(localStorage.getItem('beauty_seller') === 'true')
    },[])

    return (
			<div className="barba-container" data-barba="container">
				<MetaComponent meta={metadata} />
				<main className="main-content">
					<Preloader />
					<LoggedInHeader />
					<div className="content-wrapper js-content-wrapper overflow-hidden">
						<div
							id="dashboardOpenClose"
							className="dashboard -home-9 js-dashboard-home-9">
							<div className="dashboard__sidebar scroll-bar-1">
								{isSellerLogin && <LoggedInSidebar />}
								{isBuyerLogin && <CustomerSidebar />}
							</div>
							<Routes>
								{/*Page Not Found*/}
								<Route path="*" element={<PageNotFound />} />
								{isSellerLogin && (
									<>
										<Route index element={<MainDashboard />} />
										<Route path="/create-course" element={<CreateCourse />} />
										<Route
											path="/courses/update-course"
											element={<CreateCourse />}
										/>
										<Route path="/courses" element={<CourseList />} />
										<Route path="/instructor-courses" element={<UserCourses/>} />
										<Route path="/instructor-courses/:courseSlugId" element={<EnrolledCourseDetailPage/>} />
										<Route path="/instructor-settings" element={<SellerSettings/>} />
										<Route path="/instructor-review" element={<MainReviewPage/>} />
										<Route path="/instructor-billing-list" element={<BillingList/>} />
										<Route path="/instructor-course-payment-list" element={<SellerCoursePaymentHistory/>} />
										<Route path="/instructor-bookmark-courses" element={<BookmarkCourseList/>} />
									</>
								)}
								{isBuyerLogin && <>
                                    <Route index element={<UserDashboard/>} />
									<Route path="/user-settings" element={<UserSettings/>} />
									<Route path="/course-dashboard" element={<UserCourses/>} />
									<Route path="/course-dashboard/:courseSlugId" element={<EnrolledCourseDetailPage/>} />
									<Route path="/user-bookmark-courses" element={<BookmarkCourseList/>} />
                                </>}
							</Routes>
						</div>
					</div>
				</main>
			</div>
		);
}
