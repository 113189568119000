import React, {useEffect} from "react";

export default function MySuperDashboard() {
    useEffect(() => {
        if (!localStorage.getItem('beauty_super_key')) {
            window.location.replace('login')
        }
    }, [])
    return (
        <div className="dashboard__main">
            <div className="dashboard__content bg-light-4">
                <div className="row pb-50 mb-10">
                    <div className="col-auto">
                        <h1 className="text-30 lh-12 fw-700">Dashboard</h1>
                        <div className="mt-10">
                            Welcome to the Super Admin Dashboard.
                        </div>
                    </div>
                </div>
            </div>

            {/*<FooterNine />*/}
        </div>
    );
}
