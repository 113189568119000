import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSellerDashboardActions } from "./../../../../../../redux/action/myDashboardActions/sellerDashboardActions";
import { Box, LinearProgress } from "@mui/material";
import LineChartPage from "../../../../../../components/common/charts/LineChartPage";
import moment from "moment";
import { submitWithdrawRequestAction } from "../../../../../../redux/action/myDashboardActions/payPalActions";
import { useSnackbar } from "notistack";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaypal } from '@fortawesome/free-brands-svg-icons';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { LoggedInFooter } from "../../../../../../components/layout/footers/LoggedInFooter";

const LightTooltip = styled(({ className, ...props }) => (
	<Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
	  boxShadow: theme.shadows[1],
	  fontSize: 11,
	  backgroundColor : '#6440FB',
	  color:'white'
	},
  }));

export default function MainDashboard() {
	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();
	const [payPalEmail, setPayPalEmail] = useState("");

	const { data: dashboardDetails, isLoading } = useSelector(
		(state) => state.myDashboard.sellerDashboard
	);

	useEffect(() => {
		dispatch(getSellerDashboardActions());
	}, [dispatch]);

	useEffect(() => {
		if (!localStorage.getItem("beauty_key")) {
			window.location.replace("login");
		}
	}, []);

	const states = [
		{
			id: 1,
			title: "Total Sales",
			value: dashboardDetails?.total_sale ? dashboardDetails.total_sale : 0,
			iconClass: "icon-coupon",
			sales: true
		},
		{
			id: 2,
			title: "Published Courses",
			value: dashboardDetails?.published_courses
				? dashboardDetails.published_courses
				: 0,
			iconClass: "icon-play-button",
			sales: false
		},
		{
			id: 3,
			title: "Enrolled Students",
			value: dashboardDetails?.enrolled_students
				? dashboardDetails.enrolled_students
				: 0,
			iconClass: "icon-graduate-cap",
			sales: false
		},
		{
			id: 4,
			title: "OverAll Ratings",
			value: dashboardDetails?.overall_rating
				? dashboardDetails.overall_rating
				: 0,
			iconClass: "icon-rating-2",
			sales: false
		},
	];

	const earningData = [
		{
			id: 5,
			title: "This Month Sales",
			value: dashboardDetails?.this_month_sale
				? dashboardDetails.this_month_sale
				: 0,
			iconClass: "icon-creative-art",
			sales: true
		},
		{
			id: 6,
			title: "Hold Amount",
			value: dashboardDetails?.hold_amount ? dashboardDetails.hold_amount : 0,
			iconClass: "icon-wall-clock",
			sales: true
		},
		{
			id: 7,
			title: "Withdraw Amount",
			value: dashboardDetails?.withdrawal_amount
				? dashboardDetails.withdrawal_amount
				: 0,
			iconClass: "icon-save-money",
			sales: true
		},
	];

	const handleWithdrawAmount = () => {
		// Email validation regex pattern
		const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

		if (!emailPattern.test(payPalEmail)) {
			enqueueSnackbar("Please enter a valid email", { variant: "info" });
			return;
		}

		dispatch(
			submitWithdrawRequestAction(payPalEmail, {
				onLoading: (loading) => {
					enqueueSnackbar("Loading...", { variant: "info" });
				},
				onError: (error) => {
					let errorMessage = String(error?.message);
					enqueueSnackbar(errorMessage, { variant: "error" });
				},
				onSuccess: (success) => {
					enqueueSnackbar(success.message[0], { variant: "success" });
					dispatch(getSellerDashboardActions());
				},
			})
		);
	};

	return (
		<div className="dashboard__main">
			<div className="dashboard__content bg-light-4">
				<div className="row pb-50 mb-10 mt-50">
					<div className="col-auto">
						<h1 className="text-30 lh-12 fw-700">Dashboard</h1>
						<div className="mt-5">Welcome to the Dashboard Section.</div>
					</div>
				</div>

				{/* Short Analysis Start */}
				<div className="row y-gap-30">
					{states.map((elm, i) => (
						<div key={i} className="col-xl-3 col-md-6">
							{isLoading && (
								<Box sx={{ width: "95%", margin: "0 auto" }}>
									<LinearProgress />
								</Box>
							)}
							<div
								className="d-flex justify-between items-center py-35 px-15 rounded-16 bg-white -dark-bg-dark-1 shadow-4"
								style={{ marginTop: "-4px" }}>
								<div>
									<div className="lh-1 fw-500">{elm.title}</div>
									<div className="text-20 lh-1 fw-700 text-dark-1 mt-20">
										{elm.sales?'$ ':null}{elm.value}
									</div>
								</div>
								<i className={`text-40 ${elm.iconClass} text-purple-1`}></i>
							</div>
						</div>
					))}
				</div>
				{/* Short Analysis Ends */}

				{/* Sales Details Start */}
				<div className="row y-gap-30">
					<div className="col-xl-8 col-md-6">
						<div className="rounded-16 bg-white -dark-bg-dark-1 shadow-4 h-100">
							<div className="d-flex justify-between items-center py-20 px-30 border-bottom-light">
								<h2 className="text-17 lh-1 fw-500">
									Earning Statistics ({moment().year()})
								</h2>
							</div>
							<div className="py-20 px-30">
								<LineChartPage data={dashboardDetails.yearly_graph}/>
							</div>
						</div>
					</div>
					<div className="col-xl-4 col-md-6">
						{earningData.slice(0, 2).map((elm, i) => (
							<div key={elm.id} className="mb-20">
								{isLoading && (
									<Box sx={{ width: "95%", margin: "0 auto" }}>
										<LinearProgress />
									</Box>
								)}
								<div className="d-flex justify-between items-center py-35 px-15 rounded-16 bg-white -dark-bg-dark-1 shadow-4">
									<div>
										<div className="lh-1 fw-500">{elm.title}</div>
										<div className="text-20 lh-1 fw-700 text-dark-1 mt-20">
											{elm.sales?'$ ':null}{elm.value}
										</div>
									</div>
									<i className={`text-40 ${elm.iconClass} text-purple-1`}></i>
								</div>
							</div>
						))}

						{/* Withdraw box starts */}
						<div key={earningData[2].id} className="mb-10">
							{isLoading && (
								<Box sx={{ width: "95%", margin: "0 auto" }}>
									<LinearProgress />
								</Box>
							)}
							<div className="py-25 px-15 rounded-16 bg-white -dark-bg-dark-1 shadow-4">
								<div className="d-flex justify-between items-center ">
									<div>
										<div className="lh-1 fw-500">{earningData[2].title}</div>
										<div className="text-20 lh-1 fw-700 text-dark-1 mt-20">
											{earningData[2].sales?'$ ':null}{earningData[2].value}
										</div>
									</div>
									<i
										className={`text-40 ${earningData[2].iconClass} text-purple-1`}></i>
								</div>

								<label className="text-16 lh-1 fw-500 text-purple-1 mb-20 mt-30">
									PayPal Email address * &emsp;
									<LightTooltip
										style={{
											cursor:'pointer',
											color: '#6440FB'
										}}
										color="#6440FB"
										title={
											"Please provide your PayPal email address. Your funds will be deposited into the PayPal account associated with this email."
										}>
										<span className="paypal-icon-container">
											<FontAwesomeIcon icon={faPaypal} bounce />
										</span>
									</LightTooltip>
								</label>
								<div className="d-flex justify-between">
									<input
										type="email"
										name="email"
										placeholder="Email address *"
										style={{
											border: "1px solid grey",
											width: "calc(100% - 90px)",
											marginRight: "10px",
											borderRadius: "5px",
											padding:'5px'
										}}
										value={payPalEmail}
										onChange={(e) => setPayPalEmail(e.target.value)}
									/>
									<button
										className={payPalEmail.trim().length && "text-purple-1"}
										onClick={() => handleWithdrawAmount()}
										disabled={!payPalEmail.trim().length}>
										Withdraw
									</button>
								</div>
							</div>
						</div>
						{/* Withdraw box ends  */}
					</div>
				</div>

				{/* Sales Details Ends */}
			</div>

			<LoggedInFooter/>
		</div>
	);
}
