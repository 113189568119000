import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { COMPANY_NAME } from "../../../config/config";
import MetaComponent from "../../../components/common/MetaComponent";
import Preloader from "../../../components/common/Preloader";
import HeaderAuth from "../../../components/layout/headers/HeaderAuth";
// import AuthImageMove from "../../../components/others/AuthImageMove";
import TrainerSignUpForm from "../../../components/others/trainers/TrainerSignUpNewForm";
import CustomerSignUpForm from "../../../components/others/CustomerSignUpForm";
import Footer from "../../../components/layout/footers/Footer";

const metadata = {
	title: `Educator Sign Up - ${COMPANY_NAME}`,
	description: `Elevate your e-learning content with ${COMPANY_NAME}, the most impressive LMS template for online courses, education and LMS platforms.`,
};

export const loginTabs = ["Student", "Educator"];

export default function TrainerSignUpNew() {
	const headerRef = useRef(null);
	const [headerHeight, setHeaderHeight] = useState(0);
	const [tab, setTab] = useState(window.location.pathname === "/trainer-sign-up" ? "Educator" : "Student");

	const updateHeaderHeight = () => {
		if (headerRef.current) {
			setHeaderHeight(headerRef.current.clientHeight);
		}
	};

	useLayoutEffect(() => {
		// Set the initial height
		updateHeaderHeight();
	}, []);

	useEffect(() => {
		// Update the height on window resize
		window.addEventListener("resize", updateHeaderHeight);
		// Cleanup the event listener on component unmount
		return () => window.removeEventListener("resize", updateHeaderHeight);
	}, []);

	return (
		<div className="main-content">
			<MetaComponent meta={metadata} />
			<Preloader />

			<HeaderAuth headerRef={headerRef} />
			<div className="content-wrapper js-content-wrapper overflow-hidden" style={{paddingBottom:'100px',paddingTop:'50px'}}>
				<section className="form-page">
					{/* <AuthImageMove /> */}
					<div
						className="centered-form"
						style={{
							width: "100%",
							marginTop: `${headerHeight}px`,
							display: "flex",
							justifyContent: "center",
							overflow: "auto",
							flexDirection:'column',
							alignItems:'center',
						}}>
						<div className="tabs__controls flex-wrap  pt-50 d-flex justify-center js-tabs-controls text-18">
							{loginTabs.map((elm, i) => (
								<div
									onClick={() => {
										setTab(elm);
									}}
									key={i}>
									<button
										className={`tabs__button px-25 py-12 rounded-8 js-tabs-button ${
											tab === elm ? "tabActive" : ""
										} `}
										data-tab-target=".-tab-item-2"
										type="button">
										{elm}
									</button>
								</div>
							))}
						</div>
						{tab === "Educator" ? (
							<TrainerSignUpForm />
						) : (
							<CustomerSignUpForm />
						)}
					</div>
				</section>
			</div>
			<Footer/>
		</div>
	);
}
