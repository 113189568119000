import Axios from "axios";
import {GET_CUSTOMER_DETAIL, UPDATE_CUSTOMER_DETAIL} from "../commonActions/types";
import {BASE_URL} from "../../../config/config";
import {ERROR, LOADING, SUCCESS} from "../../../constants/generalConstant";
import { onResponseError } from "../../../function/Interceptor";

export function getCustomerDetailsAction(actionResponse = {}) {
    const {onSuccess, onError, onLoading} = actionResponse;
    return async (dispatch) => {
        dispatch({type: GET_CUSTOMER_DETAIL, status: LOADING});
        onLoading?.(true)
        try {
            let params = {
                pagination: false,
                fields: ''
            };

            let Config = {
                params,
            }
            if (localStorage.getItem("beauty_key")) {
                Config.headers = {Authorization: "Bearer " + localStorage.getItem("beauty_key")}
            }
            let response = await Axios.get(`${BASE_URL}users/getBuyerUser`, Config);
            onSuccess?.(response?.data)
            dispatch({
                type: GET_CUSTOMER_DETAIL,
                status: SUCCESS,
                payload: response?.data,
            });
        } catch (error) {
            onError?.(error?.response?.data)
            onResponseError(error?.response?.data)
            dispatch({
                type: GET_CUSTOMER_DETAIL,
                status: ERROR,
                payload: error?.response?.data,
            });
        }
    };
}

export function updateCustomerDetailsAction(body, actionResponse = {}) {
    const {onSuccess, onError, onLoading} = actionResponse;
    return async (dispatch) => {
        dispatch({type: UPDATE_CUSTOMER_DETAIL, status: LOADING});
        onLoading?.(true)
        try {
            let config = {
                headers: {} // Initialize headers object
            };

            if (localStorage.getItem("beauty_key")) {
                config.headers.Authorization = "Bearer " + localStorage.getItem("beauty_key");
            }
            let response = await Axios.patch(`${BASE_URL}users/updateBuyerDetails`, body,config);
            onSuccess?.(response?.data)
            dispatch({
                type: UPDATE_CUSTOMER_DETAIL,
                status: SUCCESS,
                payload: response?.data,
            });
        } catch (error) {
            onError?.(error?.response?.data)
            onResponseError(error?.response?.data)
            dispatch({
                type: UPDATE_CUSTOMER_DETAIL,
                status: ERROR,
                payload: error?.response?.data,
            });
        }
    };
}
