import Axios from "axios";
import { BASE_URL } from "../../../config/config";
import { onResponseError } from "../../../function/Interceptor";

export function submitWithdrawRequestAction(emailId,actionResponse = {}) {
    const {onSuccess, onError, onLoading} = actionResponse;
    return async (dispatch) => {
        onLoading?.(true)
        try {
            let params = {
                email : emailId
            };

            let Config = {
                params,
            }
            if (localStorage.getItem("beauty_key")) {
                Config.headers = {Authorization: "Bearer " + localStorage.getItem("beauty_key")}
            }
            let response = await Axios.get(`${BASE_URL}courses/sellerWithdrawCourseAmount`, Config);
            onSuccess?.(response?.data)
        } catch (error) {
            onError?.(error?.response?.data)
            onResponseError(error?.response?.data)
        }
    };
}
