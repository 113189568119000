import React, { useState } from "react";
import { COMPANY_NAME } from "../../config/config";
import MetaComponent from "../../components/common/MetaComponent";
import Preloader from "../../components/common/Preloader";
import Header from "../../components/layout/headers/Header";
import Footer from "../../components/layout/footers/Footer";
import { useDispatch } from "react-redux";
import { sendContactUsMessage } from "../../redux/action/contactUsActions/contactUsActions";
import { useSnackbar } from "notistack";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faMapLocationDot
} from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";

const metadata = {
	title: `Contact Us | ${COMPANY_NAME}`,
	description: `Elevate your e-learning content with ${COMPANY_NAME}, the most impressive LMS template for online courses, education and LMS platforms.`,
};

export default function ContactUs() {
	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();

	const [loading, setLoading] = useState(false);

	const handleSubmit = (e) => {
		e.preventDefault();
		setLoading(true);
		const name = e.target.elements.name.value;
		const email = e.target.elements.email.value;
		const message = e.target.elements.message.value;
		let body = {
			name,
			email,
			message,
		};
		dispatch(
			sendContactUsMessage(body, {
				onLoading: (loading) => {
					setLoading(true);
					enqueueSnackbar("Sending Message...", { variant: "info" });
				},
				onError: (error) => {
					setLoading(false);
					let errorMessage = String(error?.message);
					enqueueSnackbar(errorMessage, { variant: "error" });
				},
				onSuccess: (success) => {
					setLoading(false);
					enqueueSnackbar(success.message[0], { variant: "success" });
					e.target.reset();
				},
			})
		);
	};

	return (
		<div className="main-content  ">
			<MetaComponent meta={metadata} />
			<Preloader />

			<Header />
			<div className="content-wrapper js-content-wrapper overflow-hidden">
				<>
					<section
						// className="page-header -type-4 bg-beige-1"
						className="page-header -type-1">
						<div className="container layout-pt-lg">
							<div className="page-header__content">
								<div
									// className="row"
									className="row justify-center text-center">
									<div className="col-auto">
										<div>
											<h1 className="page-header__title">Contact Us</h1>
										</div>

										{/* <div>
											<p className="page-header__text">
												We’re on a mission to deliver engaging, curated courses
												at a reasonable price.
											</p>
										</div> */}
									</div>
								</div>
							</div>
						</div>
					</section>

					<section className="layout-pt-md layout-pb-lg">
						<div className="container">
							<div className="row y-gap-50 justify-between">
								<div className="col-lg-6">
									<div className="px-40 pb-40">
										<h3 className="text-24 lh-1 fw-500">
											Keep In Touch With Us.
										</h3>
										<p className="mt-25">
											We're here to help! Whether you're a professional beauty
											educator looking to share your expertise or a student
											seeking to elevate your skills, we're happy to answer your
											questions and support you on your learning journey.
										</p>
										<div className="row y-gap-30 pt-60 lg:pt-40">
											<div className="col-sm-12">
												<div
													className="d-flex"
													style={{ alignItems: "center" }}>
													<div className="size-80 d-flex justify-center items-center bg-purple-2 rounded-full text-purple-1">
														<FontAwesomeIcon
															icon={faMapLocationDot}
															size="2xl"
														/>
													</div>
													&emsp;
													<p
														className="text-20 lh-13"
														style={{ color: "grey" }}>
														1635 W.Trade Street #1F Charlotte, NC 28216
													</p>
												</div>
											</div>

											{/* <div className="col-sm-12 pt-30">
												<div
													className="d-flex"
													style={{ alignItems: "center" }}>
													<div className="size-80 d-flex justify-center items-center bg-purple-2 rounded-full text-purple-1">
														<FontAwesomeIcon icon={faPhoneVolume} size="2xl" />
													</div>
													&emsp;
													<p className="text-20 lh-1" style={{ color: "grey" }}>
														+(1) 123 456 7890
													</p>
												</div>
											</div> */}

											<div className="col-sm-12 pt-30">
												<div
													className="d-flex"
													style={{ alignItems: "center" }}>
													<div className="size-80 d-flex justify-center items-center bg-purple-2 rounded-full text-purple-1">
														<FontAwesomeIcon icon={faEnvelope} size="2xl" />
													</div>
													&emsp;
													<p className="text-20 lh-1 sm:text-18" style={{ color: "grey" }}>
														Info@probeautyport.com
													</p>
												</div>
											</div>
										</div>
									</div>
								</div>

								<div className="col-lg-6">
									<div
										// className="px-40 py-40 bg-white border-light shadow-1 rounded-8 contact-form-to-top"
										className="px-40 pb-40 bg-white rounded-8">
										<h3 className="text-24 fw-500">Send a Message</h3>
										<p className="mt-25">
											Fill out the form below and we'll get back to you as soon
											soon as possible.
										</p>

										<form
											className="contact-form row y-gap-30 pt-60 lg:pt-40"
											onSubmit={handleSubmit}>
											<div className="col-12">
												<label className="text-16 lh-1 fw-500 text-dark-1 mb-10">
													Name
												</label>
												<input
													required
													type="text"
													name="name"
													placeholder="Name..."
												/>
											</div>
											<div className="col-12">
												<label className="text-16 lh-1 fw-500 text-dark-1 mb-10">
													Email Address
												</label>
												<input
													required
													type="email"
													name="email"
													placeholder="Email..."
												/>
											</div>
											<div className="col-12">
												<label className="text-16 lh-1 fw-500 text-dark-1 mb-10">
													Message...
												</label>
												<textarea
													name="message"
													placeholder="Message"
													rows="8"
													required></textarea>
											</div>
											<div className="col-12">
												<button
													type="submit"
													name="submit"
													id="submit"
													className="button -md -purple-1 text-white"
													disabled={loading}>
													Send Message
												</button>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
					</section>
				</>
				<Footer />
			</div>
		</div>
	);
}
