import { ERROR, LOADING, SUCCESS } from "../../../constants/generalConstant";
import { GET_ENROLLED_COURSE_DETAIL } from "../../action/commonActions/types";

const initialState = {
	data: {},
	isLoading: false,
	isSuccess: false,
	isError: false,
};

const singleEnrolledCourse = (state = initialState, action) => {
	switch (action.type) {
		case GET_ENROLLED_COURSE_DETAIL: {
			switch (action.status) {
				case LOADING: {
					return {
						...state,
						isLoading: true,
						isSuccess: false,
					};
				}
				case SUCCESS: {
					return {
						...state,
						data: action?.payload?.data ?? {},
						isSuccess: true,
						isLoading: false,
					};
				}
				case ERROR: {
					return {
						...state,
						isError: true,
						isLoading: false,
						isSuccess: false,
					};
				}

				default:
					return state;
			}
		}
		default:
			return state;
	}
};
export default singleEnrolledCourse;
