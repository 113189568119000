/* eslint-disable jsx-a11y/anchor-is-valid */
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import ModalVideoComponent from './../../../../../../components/common/ModalVideoComponent';

export default function CourseContent(props) {
    const { data } = props;
  const [activeItemId, setActiveItemId] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedLesson, setSelectedLesson] = useState({
    id:0
  })
  return (
    <>
      <div id="course-content" className="pt-60 lg:pt-40">
        <h2 className="text-20 fw-500">Course Content</h2>

        {data?.chapters?.length && <div className="d-flex justify-between items-center mt-30">
          <div className="">{data?.chapters?.length ?? 0} chapters • {data?.lesson_count ?? 0} lessons</div>
        </div>}

        <div className="mt-10">
          {data?.chapters?.length ?
          <div className="accordion -block-2 text-left js-accordion">
            {data.chapters.map((elm, i) => (
              <div
                key={i}
                className={`accordion__item ${
                  String(activeItemId) === String(elm.id) ? "is-active" : ""
                } `}
              >
                <div
                  onClick={() =>
                    setActiveItemId((pre) => (pre === elm.id ? 0 : elm.id))
                  }
                  className="accordion__button py-20 px-30 bg-light-4"
                >
                  <div className="d-flex items-center">
                    <div className="accordion__icon">
                      <div className="icon">
                        <FontAwesomeIcon icon={faChevronDown} />
                      </div>
                      <div className="icon">
                        <FontAwesomeIcon icon={faChevronUp} />
                      </div>
                    </div>
                    <span className="text-17 fw-500 text-dark-1">
                      {elm.title}
                    </span>
                  </div>

                  <div>
                    {elm?.lessons?.length ?? 0} lessons • {elm?.lesson_summary?.lessons_duration ?? 0} min
                  </div>
                </div>

                <div
                  className="accordion__content"
                  style={String(activeItemId) === String(elm.id) ? { maxHeight: "700px" } : {}}
                >
                  <div className="accordion__content__inner px-30 py-30">
                    {elm?.lessons?.length ?<div className="y-gap-20">
                      {elm.lessons.map((itm, index) => (
                        <div key={index} className="d-flex justify-between">
                          <div className="d-flex items-center">
                            <div className="d-flex justify-center items-center size-30 rounded-full bg-purple-3 mr-10">
                              <div className="icon-play text-9"></div>
                            </div>
                            <div>{itm.title}</div>
                          </div>

                          <div className="d-flex x-gap-20 items-center">
                            {props?.type === "customer" ? null :
                            <>
                            {itm?.video_obj?.key ? <span
                              onClick={() => {
                                setSelectedLesson(itm)
                                setIsOpen(true)
                            }}
                              className="text-14 lh-1 text-purple-1 underline cursor "
                            >
                              Preview
                            </span> : <span className="text-14 lh-1">Video not available</span>}
                            </>}
                            <a
                              className="text-14 lh-1"
                            >
                             Duration: {itm?.duration ??0}
                            </a>
                          </div>
                        </div>
                      ))}
                    </div>:<div className="text-center">Lessons not available yet.</div>}
                  </div>
                </div>
              </div>
            ))}
          </div> : <div className="text-center">Chapters not available yet.</div>}
        </div>
      </div>
      <ModalVideoComponent
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        videoId={selectedLesson.id}
        url={selectedLesson?.video_obj?.key ?? ''}
      />
    </>
  );
}
