import React, { useEffect, useState } from "react";
import {
	LineChart,
	Tooltip,
	Line,
	CartesianGrid,
	XAxis,
	YAxis,
	ResponsiveContainer,
} from "recharts";

const tempData = [
  { name: "Jan", value: 0 },
  { name: "Feb", value: 0 },
  { name: "Marc", value: 0 },
  { name: "April", value: 0 },
  { name: "May", value: 0 },
  { name: "Jun", value: 0 },
  { name: "July", value: 0 },
  { name: "Agust", value: 0 },
  { name: "Sept", value: 0 },
  { name: "Oct", value: 0 },
  { name: "Now", value: 0 },
  { name: "Dec", value: 0 },
];

const LineChartPage = (props) => {
	const { data = [] } = props;
	const [chartHeight, setChartHeight] = useState(250); // Initial height

	// Dynamically adjust chart height on window resize
	useEffect(() => {
		function handleResize() {
			// Calculate desired height based on window size or any other factor
			const desiredHeight = Math.min(window.innerHeight - 300, 400); // Example calculation
			setChartHeight(desiredHeight);
		}
		window.addEventListener("resize", handleResize);
		handleResize(); // Initial call
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	const chart = (interval) => (
		<ResponsiveContainer height={chartHeight} width="100%">
			<LineChart data={data?.length ? data : tempData}>
				<CartesianGrid strokeDasharray="" />
				<XAxis tick={{ fontSize: 12 }} dataKey="name" interval={interval} />
				<YAxis
					tick={{ fontSize: 12 }}
					domain={[0, 300]}
					tickCount={7}
					interval={interval}
				/>
				<Tooltip />
				<Line
					type="monotone"
					dataKey="value"
					strokeWidth={2}
					stroke="#336CFB"
					fill="#336CFB"
					activeDot={{ r: 8 }}
				/>
				{/* <Line type="monotone" dataKey="uv" stroke="#82ca9d" /> */}
			</LineChart>
		</ResponsiveContainer>
	);

	return (
		<>
			{chart("preserveEnd")}
			{/* {chart('preserveStart')}
      {chart('preserveStartEnd')}
      {chart('equidistantPreserveStart')}
      {chart(1)} */}
		</>
	);
};

export default LineChartPage;
