import React from "react";
import {COMPANY_NAME} from "../../../config/config";
import MetaComponent from "../../../components/common/MetaComponent";
import Preloader from "../../../components/common/Preloader";
import Header from "../../../components/layout/headers/Header";
import Footer from "../../../components/layout/footers/Footer";
import HelpCenter from "./HelpCenter";
// import Faq from "../../../components/common/Faq";

const metadata = {
    title:
        `Help Center || ${COMPANY_NAME}`,
    description:
        `Elevate your e-learning content with ${COMPANY_NAME}, the most impressive LMS template for online courses, education and LMS platforms.`,
};
export default function HelpCenterPage() {
    return (
        <div className="main-content  ">
            <MetaComponent meta={metadata}/>
            <Preloader/>

            <Header/>
            <div className="content-wrapper js-content-wrapper overflow-hidden">
                <HelpCenter/>
                {/* <Faq/> */}
                <Footer/>
            </div>
        </div>
    );
}
