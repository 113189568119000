import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LinearProgress, Pagination, useMediaQuery } from "@mui/material";
import {
	getBoookmarkCoursesAction,
	updateCourseBookmarkAction,
} from "../../../../../../redux/action/courseActions/customerCoursesActions";
import { useSnackbar } from "notistack";
import { Empty, Spin } from "antd";
import CoursesCardDashboard from "../../customer/loggedInCourses/CoursesCardDashboard";
import { LoggedInFooter } from "../../../../../../components/layout/footers/LoggedInFooter";
import { debounce } from "lodash";

export default function BookmarkCourseList() {
	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();
	const [search, setSearch] = useState("");

	const isMobile = useMediaQuery("(max-width:600px)");
	const isTablet = useMediaQuery("(max-width:690px)")

	const {
		data: bookmarkCourses,
		meta_data,
		isLoading: isBookmarkCoursesLoading,
	} = useSelector((state) => state.courses.bookmarkCourses);

	useEffect(() => {
		let body = {
			page: 1,
			search: "",
		};
		dispatch(getBoookmarkCoursesAction(body));
	}, [dispatch]);

	const handlePageChange = (value) => {
		let body = {
			page: value,
			search: search,
		};
		dispatch(getBoookmarkCoursesAction(body));
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const handleSearchDebounced = useCallback(
		debounce((value) => {
			let body = {
				search: value,
				page: 1,
			};
			dispatch(getBoookmarkCoursesAction(body));
		}, 500),
		[debounce]
	);

	const handleChange = (e) => {
		const { value } = e.target;
		setSearch(value);
		handleSearchDebounced(value);
	};

	const updateCourseBookmark = (item) => {
		let body = {
			course: item?.id ?? 0,
		};
		dispatch(
			updateCourseBookmarkAction(body, {
				onLoading: (loading) => {
					enqueueSnackbar("Loading...", { variant: "info" });
				},
				onError: (error) => {
					let errorMessage = String(error?.message);
					enqueueSnackbar(errorMessage, { variant: "error" });
				},
				onSuccess: (success) => {
					enqueueSnackbar(success.message[0], { variant: "success" });
					let body = {
						// search: search,
						page: meta_data?.current ?? 1,
					};
					dispatch(getBoookmarkCoursesAction(body));
				},
			})
		);
	};

	return (
		<div className="dashboard__main">
			<div className="dashboard__content bg-light-4">
				<div className="row pb-50 mb-10 mt-50">
					<div className="col-auto">
						<h1 className="text-30 lh-1 fw-700">Bookmark Courses</h1>
						<div className="mt-10">Welcome to the bookmark section.</div>
					</div>
					<div className={`col-auto ${(isMobile || isTablet) ? 'me-auto' : 'ms-auto'}`}>
						<div
							style={{
								position: "relative",
								display: "inline-block",
								width: "auto",
							}}>
							<div
								className="icon text-dark-1"
								style={{
									position: "absolute",
									left: "5px",
									top: "56%",
									transform: "translateY(-50%)",
								}}>
								<i className="icon-search text-light-1 text-18"></i>
							</div>
							<input
								type="text"
								placeholder="Type to search..."
								style={{
									paddingLeft: "30px",
									paddingTop: "10px",
									paddingBottom: "10px",
									border: "1px solid grey",
									borderRadius: "20px",
									width: "300px",
								}}
								value={search}
								onChange={(e) => handleChange(e)}
							/>
						</div>
					</div>
				</div>

				<div className="row y-gap-30">
					<div className="col-12">
						{isBookmarkCoursesLoading && <LinearProgress />}
						<div className="rounded-16 bg-white -dark-bg-dark-1 shadow-4 p-20">
							<Spin spinning={isBookmarkCoursesLoading}>
								{bookmarkCourses?.length ? (
									<div className="row y-gap-30 pt-30 px-20">
										{bookmarkCourses.map((data, i) => (
											<CoursesCardDashboard
												data={data}
												key={i}
												updateCourseBookmark={updateCourseBookmark}
												isBookmarkPage={true}
											/>
										))}
									</div>
								) : (
									<Empty description={"Courses are not added yet..."} />
								)}
							</Spin>
						</div>
					</div>
					<div className="row justify-center pt-30 pb-20">
						<div className="col-auto">
							<Pagination
								count={meta_data?.total_pages ?? 0}
								page={meta_data?.current ?? 1}
								onChange={(event, value) => handlePageChange(value)}
								size="large"
								sx={{
									ul: {
										"& .Mui-selected": {
											background: "#6440FB",
											color: "white",
										},
									},
								}}
								disabled={isBookmarkCoursesLoading}
							/>
						</div>
					</div>
				</div>
			</div>
			<LoggedInFooter/>
		</div>
	);
}
