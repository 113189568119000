import React, { useCallback, useEffect, useRef, useState } from "react";
import { COMPANY_NAME } from "../../../config/config";
import MetaComponent from "../../../components/common/MetaComponent";
import Header from "../../../components/layout/headers/Header";
import Preloader from "../../../components/common/Preloader";
import Footer from "../../../components/layout/footers/Footer";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Avatar, Pagination, Rating } from "@mui/material";
import { Empty, Spin } from "antd";
import { getTrainerList } from "../../../redux/action/trainerActions/trainerActions";
import { ratingList } from "../../../objectTemplates/objectTemplates";
import { debounce } from "lodash";

const metadata = {
	title: `Educators | ${COMPANY_NAME}`,
	description: `Elevate your e-learning content with ${COMPANY_NAME}, the most impressive LMS template for online courses, education and LMS platforms.`,
};

export default function InstructorsList() {
	const dispatch = useDispatch();
	const imageRefs = useRef([]);
	const [search, setSearch] = useState("");

	const ImageMaxHeight = "330px";

	const [ratingOpen, setRatingOpen] = useState(true);
	const [rating, setRating] = useState("All");

	const {
		data: teamMembers,
		isSuccess,
		isLoading,
		meta_data,
	} = useSelector((state) => state.trainers.trainers);

	useEffect(() => {
		let body = {
			page: 1,
			search:''
		};
		!isSuccess && dispatch(getTrainerList(body));
	}, [dispatch, isSuccess]);

	useEffect(() => {
		function adjustImageHeight() {
			imageRefs?.current?.forEach((ref) => {
				const image = ref;
				if (image) {
					const width = image.offsetWidth;
					const height = (width / 300) * 330;
					image.style.height = `${height}px`;
				}
			});
		}
		if (teamMembers.length > 0) {
            adjustImageHeight();
            const resizeListener = () => adjustImageHeight();
            window.addEventListener("resize", resizeListener);
            return () => {
                window.removeEventListener("resize", resizeListener);
            };
        }
	}, [teamMembers]);

	const getRatingStars = (count) => {
		return (
			<Rating size="small" readOnly precision={0.5} defaultValue={count} />
		);
	};

	const handlePageChange = (value) => {
		let body = {
			page: value,
			search: search
		};
		dispatch(getTrainerList(body));
	};

	const handleRatingChange = (item) => {
		setRating(item);
		let body = {
			page: meta_data?.current ?? 1,
			rating: item === "All" ? "" : item,
			search: search
		};
		dispatch(getTrainerList(body));
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const handleSearchDebounced = useCallback(
		debounce((value) => {
			let body = {
				search: value,
				rating: rating === "All" ? "" : rating,
				page: 1,
			};
			dispatch(getTrainerList(body));
		}, 500),
		[debounce]
	);

	const handleSearch = (e) => {
		const { value } = e.target;
		setSearch(value);
		handleSearchDebounced(value);
	};

	const handleSubmit =(e)=>{
		e.preventDefault();
		handleSearchDebounced(search);
	}

	return (
		<div className="main-content  ">
			<MetaComponent meta={metadata} />
			<Header />
			<Preloader />
			<div className="content-wrapper  js-content-wrapper overflow-hidden">
				{/*Educator start here */}
				<section className="page-header -type-1">
					<div className="container layout-pt-md">
						<div className="page-header__content">
							<div className="row justify-center text-center">
								<div className="col-auto">
									<div>
										<h1 className="page-header__title">Educators</h1>
									</div>

									<div>
										<p className="page-header__text">
											We’re on a mission to deliver engaging, curated courses at
											a reasonable price.
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				{/*Educator title ends here*/}

				<section className="layout-pt-md layout-pb-lg">
					<div className="container">
						<div className="row y-gap-50">
							<div className="col-lg-3 pr-50">
								<div className="sidebar -courses">
									{/*Rating start*/}
									<div className="sidebar__item">
										<div className="accordion js-accordion">
											<div
												className={`accordion__item js-accordion-item-active ${
													ratingOpen ? "is-active" : ""
												} `}>
												<div
													className="accordion__button items-center"
													onClick={() => setRatingOpen((pre) => !pre)}>
													<h5 className="sidebar__title">Ratings</h5>

													<div className="accordion__icon">
														<div className="icon icon-chevron-down"></div>
														<div className="icon icon-chevron-up"></div>
													</div>
												</div>

												<div
													className="accordion__content"
													style={ratingOpen ? { maxHeight: "350px" } : {}}>
													<div className="accordion__content__inner">
														<div className="sidebar-checkbox">
															<div
																onClick={() => handleRatingChange("All")}
																className="sidebar-checkbox__item">
																<div className="form-radio mr-10">
																	<div className="radio">
																		<input
																			type="radio"
																			readOnly
																			checked={rating === "All"}
																		/>
																		<div className="radio__mark">
																			<div className="radio__icon"></div>
																		</div>
																	</div>
																</div>
																<div className="sidebar-checkbox__title d-flex items-center">
																	<div className="d-flex x-gap-5 pr-10">
																		{getRatingStars(5)}
																	</div>
																	All
																</div>
																<div className="sidebar-checkbox__count"></div>
															</div>
															{ratingList.map((elm, i) => (
																<div
																	key={i}
																	onClick={() => handleRatingChange(elm.value)}
																	className="sidebar-checkbox__item cursor">
																	<div className="form-radio mr-10">
																		<div className="radio">
																			<input
																				type="radio"
																				readOnly
																				checked={elm.value === rating}
																			/>
																			<div className="radio__mark">
																				<div className="radio__icon"></div>
																			</div>
																		</div>
																	</div>
																	<div className="sidebar-checkbox__title d-flex items-center">
																		<div className="d-flex x-gap-5 pr-10">
																			{getRatingStars(5)}
																		</div>
																		{elm.text}
																	</div>
																	{/* <div className="sidebar-checkbox__count">
																		(
																		{
																			teamMembers.filter(
																				(itm) =>
																					itm.rating >= elm.range[0] &&
																					itm.rating <= elm.range[1]
																			).length
																		}
																		)
																	</div> */}
																</div>
															))}
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									{/*Rating ends*/}
								</div>
							</div>

							{/*List of Educators starts here */}

							<div className="col-lg-9">
								<div className="row y-gap-20 items-center justify-between pb-30">
									<div className="col-auto">
										<div className="text-14 lh-12">
											Showing{" "}
											<span className="text-dark-1 fw-500">
												{teamMembers.length}
											</span>{" "}
											total results
										</div>
									</div>

									<div className="col-auto">
										<div className="row x-gap-20 y-gap-20 items-center">
											<div className="col-auto">
												<form
													onSubmit={(e)=> handleSubmit(e)}
													className="search-field h-50">
													<input
														className="bg-light-3 pr-50"
														type="text"
														placeholder="Search Educators"
														value={search}
														onChange={(e) => handleSearch(e)}
													/>
													<button className="" type="submit">
														<i className="icon-search text-20"></i>
													</button>
												</form>
											</div>
										</div>
									</div>
								</div>

								<Spin spinning={isLoading}>
									<div className="row y-gap-30">
										{teamMembers.length ? (
											teamMembers.map((elm, i) => (
												<div key={i} className="col-lg-4 col-md-6">
													<div className="teamCard -type-1 px-10 py-10 rounded-8 border-light">
														<div className="teamCard__image">
															<Avatar
																variant="square"
																style={{
																	maxHeight: ImageMaxHeight,
																	width: "100%",
																	objectFit: "cover",
																}}
																src={elm?.image ?? ""}
																alt="team card"
																ref={(el) => (imageRefs.current[i] = el)}
															/>
														</div>
														<div className="teamCard__content mt-10 px-10 pb-5">
															<h4 className="teamCard__title">
																<Link
																	className="linkCustom"
																	to={`/instructors/${elm.slug_name}`}>
																	{elm.name}
																</Link>
															</h4>
															<p className="teamCard__text">{elm.role}</p>
															<div className="d-flex x-gap-10 pt-10">
																<div className="d-flex items-center">
																	<div className="icon-star text-yellow-1 text-14"></div>
																	<div className="text-13 lh-1 ml-8">
																		{elm.rating}
																	</div>
																</div>

																<div className="d-flex items-center">
																	<div className="icon-person-3 text-14"></div>
																	<div className="text-13 lh-1 ml-8">
																		{elm.students} Students
																	</div>
																</div>

																<div className="d-flex items-center">
																	<div className="icon-play text-14"></div>
																	<div className="text-13 lh-1 ml-8">
																		{elm.courses} Course
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											))
										) : (
											<Empty description={"Educator not available yet"} />
										)}
									</div>
								</Spin>
								<div className="row justify-center pt-60 lg:pt-40">
									<div className="col-auto">
										<div className="pagination -buttons d-flex justify-content-center">
											<Pagination
												count={meta_data?.total_pages ?? 0}
												page={meta_data?.current ?? 1}
												onChange={(event, value) => handlePageChange(value)}
												size="large"
												sx={{
													ul: {
														"& .Mui-selected": {
															background: "#6440FB",
															color: "white",
														},
													},
												}}
												disabled={isLoading}
											/>
										</div>
									</div>
								</div>
							</div>

							{/*List of Educators ends here*/}
						</div>
					</div>
				</section>
				<Footer />
			</div>
		</div>
	);
}
