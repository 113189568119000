import { Avatar, LinearProgress, Rating } from "@mui/material";
import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { COMPANY_NAME, IMAGE_FILE_URL } from "../../../../../../config/config";
import { getCustomerCourseDetailsAction } from "../../../../../../redux/action/courseActions/customerCoursesActions";
import MetaComponent from "../../../../../../components/common/MetaComponent";
import PinnedCourseDetail from "../../seller/courses/PinnedCourseDetail";
import CourseOverview from "../../seller/courses/CourseOverview";
import CourseContent from "../../seller/courses/CourseContent";
import StudentReview from "../../seller/courses/StudentReview";
import { Link, useLocation } from "react-router-dom";
import Preloader from "../../../../../../components/common/Preloader";
import Header from "../../../../../../components/layout/headers/Header";
import CommonBreadCrumb from "../../../../../../components/common/UI/CommonBreadCrumb";
import Footer from "../../../../../../components/layout/footers/Footer";

const metadata = {
	title: `Course Detail | ${COMPANY_NAME}`,
	description: `Elevate your e-learning content with ${COMPANY_NAME}, the most impressive LMS template for online courses, education and LMS platforms.`,
};
const menuItems = [
	{ id: 1, href: "#overview", text: "Course Info", isActive: true },
	{ id: 2, href: "#course-content", text: "Course Content", isActive: false },
	{ id: 4, href: "#reviews", text: "Reviews", isActive: false },
];

const CustomerCoursesDetail = (props) => {
	const dispatch = useDispatch();
	const location = useLocation();
	const slug_name = location.pathname.substring(
		location.pathname.lastIndexOf("/") + 1
	);
	const { data, isLoading } = useSelector(
		(state) => state.courses.singleCustomerCourse
	);

	const getData = useCallback(
		(slug) => {
			dispatch(getCustomerCourseDetailsAction(slug));
		},
		[dispatch]
	);

	useEffect(() => {
		getData(slug_name);
	}, [slug_name, getData]);

	const getRatingStars = (count) => {
		return (
			<Rating size="small" readOnly precision={0.5} defaultValue={count} />
		);
	};

	const showCourseDetail = () => (
		<div id="js-pin-container" className="js-pin-container relative">
			<section className="page-header -type-5 bg-light-6">
				<div className="page-header__bg">
					<div
						className="bg-image js-lazy"
						data-bg={`${IMAGE_FILE_URL}/img/event-single/bg.png`}></div>
				</div>

				<div className="container">
					<div className="page-header__content pt-90 pb-90">
						<div className="row y-gap-30">
							<div className="col-xl-7 col-lg-8">
								<div
									className="d-flex x-gap-15 y-gap-10 pb-20">
									{data.is_best_seller_badge && (
										<div>
											<div className="badge px-15 py-8 text-11 bg-green-1 text-dark-1 fw-400">
												BEST SELLER
											</div>
										</div>
									)}
									{data.is_new_badge && (
										<div>
											<div className="badge px-15 py-8 text-11 bg-orange-1 text-white fw-400">
												NEW
											</div>
										</div>
									)}
									{data.is_popular_badge && (
										<div>
											<div className="badge px-15 py-8 text-11 bg-purple-1 text-white fw-400">
												POPULAR
											</div>
										</div>
									)}
								</div>
								{/* title starts */}
								<div>
									<h1 className="text-30 lh-14 pr-60 lg:pr-0">{data.title}</h1>
								</div>
								{/* title ends */}
								{/* <div>
									<h6 className="text-18 pt-10 pr-60 lg:pr-0">{data?.category_obj?.name ? `Category: ${data.category_obj.name}` : ''}</h6>
								</div> */}
								{/* short description starts */}
								<p className="col-xl-9 mt-20">
									{data.short_description
										? data.short_description
										: "Short description is not available for this course."}
								</p>
								{/* short description ends */}

								{/* Additional Info starts */}
								<div className="d-flex x-gap-30 y-gap-10 items-center flex-wrap pt-20">
									<div className="d-flex items-center">
										<div className="text-14 lh-1 text-yellow-1 mr-10">
											{data.rating ?? 0}
										</div>
										<div className="d-flex x-gap-5 items-center">
											{getRatingStars(data.rating ?? 0)}
										</div>
										<div className="text-14 lh-1 text-light-1 ml-10">
											({data.ratingCount ?? 0})
										</div>
									</div>

									<div className="d-flex items-center text-light-1">
										<div className="icon icon-person-3 text-13"></div>
										<div className="text-14 ml-8">
											{data.enrolled_user_count ?? 0} enrolled on this course
										</div>
									</div>

									<div className="d-flex items-center text-light-1">
										<div className="icon icon-wall-clock text-13"></div>
										<div className="text-14 ml-8">
											Last updated on{" "}
											{moment(data.updated_at).format("MMM Do YYYY")}
										</div>
									</div>
								</div>
								{/* Additional info ends */}
								{/* Author Details Start  */}
								<div className="d-flex items-center pt-20">
									<Avatar src={data?.seller_obj?.user_profile_image ?? ""} />
									<Link
										className="linkCustom text-14 lh-1 ml-10"
										to={`/instructors/${data?.seller_obj?.slug_name}`}>
										{data?.seller_obj?.user_first_name +
											" " +
											data?.seller_obj?.user_last_name}
									</Link>
								</div>
								{/* Author Details ends */}
							</div>
						</div>
					</div>
				</div>
			</section>
			<PinnedCourseDetail pageItem={data} type={"customer"} />

			{/* Course Detail Area Starts  */}
			<section className="layout-pt-md layout-pb-md">
				<div className="container">
					<div className="row">
						<div className="col-lg-8">
							<div className="page-nav-menu -line">
								<div className="d-flex x-gap-30">
									{menuItems.map((item, ind) => (
										<div key={ind}>
											<a
												href={item.href}
												className={`pb-12 page-nav-menu__link ${
													item.isActive ? "is-active" : ""
												}`}>
												{item.text}
											</a>
										</div>
									))}
								</div>
							</div>
							{Object.keys(data).length && <CourseOverview data={data} />}
							{Object.keys(data).length && (
								<CourseContent data={data} type="customer" />
							)}
							{Object.keys(data).length && (
								<StudentReview
									item={data?.ratings_obj}
									course={data}
									type="customer"
								/>
							)}
						</div>
					</div>
				</div>
			</section>
			{/* Course Detail Area Ends */}
		</div>
	);

	const breadCrumbObject = [
		{
			label: "Home",
			navigate: "/",
		},
		{
			label: "Courses",
			navigate: "/courses/",
		},
		{
			label: data?.category_obj?.name ? data?.category_obj?.name : "",
			navigate: data?.category_obj?.id
				? `/courses/category/${data?.category_obj?.id}`
				: "",
		},
		{
			label: data?.sub_category_obj ? data?.sub_category_obj?.name : "",
			navigate: data?.sub_category_obj?.id
				? `/courses/category/${data?.category_obj?.id}/subcategory/${data?.sub_category_obj?.id}`
				: "",
		},
	];

	return (
		<>
			<div className="main-content ">
				<MetaComponent meta={metadata} />
				<Preloader />
				<Header />
				<div className="content-wrapper  js-content-wrapper mt-50">
					<section
						className="layout-pt layout-pb-lg"
						style={{ marginTop: "100px" }}>
						{isLoading && <LinearProgress />}
						{!isLoading && (
							<CommonBreadCrumb breadCrumbObject={breadCrumbObject} />
						)}
						{/* <Divider /> */}
						{!isLoading && showCourseDetail()}
					</section>
					<Footer />
				</div>
			</div>
		</>
	);
};

export default CustomerCoursesDetail;
