import {ERROR, LOADING, SUCCESS} from "../../../constants/generalConstant";
import Axios from "axios";
import {BASE_URL} from "../../../config/config";
import { onResponseError } from "../../../function/Interceptor";
import { ADD_SUPER_POPULAR_COURSE, GET_SUPER_POPULAR_COURSES, GET_SUPER_UN_POPULAR_COURSES, REMOVE_SUPER_POPULAR_COURSE, UPDATE_SUPER_POPULAR_COURSE } from "../commonActions/types";

export function getSuperPopularCourses(actionResponse = {}) {
    const {onSuccess, onError, onLoading} = actionResponse;
    return async (dispatch) => {
        dispatch({type: GET_SUPER_POPULAR_COURSES, status: LOADING});
        onLoading?.(true)
        try {
            let params = {
                pagination: false,
                fields: ''
            };
            let Config = {
                headers: {},
                params,
            }
            if (localStorage.getItem("beauty_super_key")) {
                Config.headers.Authorization = "Bearer " + localStorage.getItem("beauty_super_key");
            }
            let response = await Axios.get(`${BASE_URL}courses/getPopularCourseList`, Config);
            const modifiedData = {
                ...response.data,
                data: response?.data?.data.map((item, index) => ({
                    ...item,
                    index
                }))
            }
            onSuccess?.(modifiedData)
            dispatch({
                type: GET_SUPER_POPULAR_COURSES,
                status: SUCCESS,
                payload: modifiedData,
            });
        } catch (error) {
            onError?.(error?.response?.data)
            onResponseError(error?.response?.data,"super_admin")
            dispatch({
                type: GET_SUPER_POPULAR_COURSES,
                status: ERROR,
                payload: error,
            });
        }
    };
}

export function getSuperUnPopularCourses(body={},actionResponse = {}) {
    const {onSuccess, onError, onLoading} = actionResponse;
    return async (dispatch) => {
        dispatch({type: GET_SUPER_UN_POPULAR_COURSES, status: LOADING});
        onLoading?.(true)
        try {
            let params = {
                pagination: true,
                fields: '',
                page: body?.page !== undefined ? body.page : 1,
                popular_course: false
            };

            let Config = {
                headers: {},
                params,
            }
            if (localStorage.getItem("beauty_super_key")) {
                Config.headers.Authorization = "Bearer " + localStorage.getItem("beauty_super_key");
            }
            let response = await Axios.get(`${BASE_URL}courses/listCourse`, Config);
            const modifiedData = {
				meta_data: {
					count: response.data.data[0].count,
					current: response.data.data[0].links.current,
					hasNext: Boolean(response.data.data[0].links.next),
					total_pages: response.data.data[0].links.total_pages,
					hasPrevious: Boolean(response.data.data[0].links.previous),
				},
				data: response?.data?.data[0].results,
			};
            onSuccess?.(modifiedData)
            dispatch({
                type: GET_SUPER_UN_POPULAR_COURSES,
                status: SUCCESS,
                payload: modifiedData,
            });
        } catch (error) {
            onError?.(error?.response?.data)
            onResponseError(error?.response?.data,"super_admin")
            dispatch({
                type: GET_SUPER_UN_POPULAR_COURSES,
                status: ERROR,
                payload: error,
            });
        }
    };
}

export function removeSuperPopularCourseAction(courseId,actionResponse = {}) {
    const {onSuccess, onError, onLoading} = actionResponse;
    return async (dispatch) => {
        dispatch({type: REMOVE_SUPER_POPULAR_COURSE, status: LOADING});
        onLoading?.(true)
        try {

            let Config = {}
            if (localStorage.getItem("beauty_super_key")) {
                Config.headers = {Authorization: "Bearer " + localStorage.getItem("beauty_super_key")}
            }
            let response = await Axios.delete(`${BASE_URL}courses/deletePopularCourse/${courseId}`, Config);
            onSuccess?.(response?.data)
            dispatch({
                type: REMOVE_SUPER_POPULAR_COURSE,
                status: SUCCESS,
                payload: response?.data,
            });
        } catch (error) {
            onError?.(error?.response?.data)
            onResponseError(error?.response?.data,"super_admin")
            dispatch({
                type: REMOVE_SUPER_POPULAR_COURSE,
                status: ERROR,
                payload: error?.response?.data,
            });
        }
    };
}

export function AddSuperPopularCourseAction(courseBody, actionResponse = {}) {
    const {onSuccess, onError, onLoading} = actionResponse;
    return async (dispatch) => {
        dispatch({type: ADD_SUPER_POPULAR_COURSE, status: LOADING});
        onLoading?.(true)
        try {
            let config = {
                headers: {} // Initialize headers object
            };

            if (localStorage.getItem("beauty_super_key")) {
                config.headers.Authorization = "Bearer " + localStorage.getItem("beauty_super_key");
            }
            let body = {
                ...courseBody
            }
            let response = await Axios.post(`${BASE_URL}courses/addPopularCourse`, body, config);
            onSuccess?.(response?.data)
            dispatch({
                type: ADD_SUPER_POPULAR_COURSE,
                status: SUCCESS,
                payload: response?.data,
            });
        } catch (error) {
            onError?.(error?.response?.data)
            onResponseError(error?.response?.data,"super_admin")
            dispatch({
                type: ADD_SUPER_POPULAR_COURSE,
                status: ERROR,
                payload: error?.response?.data,
            });
        }
    };
}

export function updateSuperPopularCourseAction(body, actionResponse = {}) {
    const {onSuccess, onError, onLoading} = actionResponse;
    return async (dispatch) => {
        dispatch({type: UPDATE_SUPER_POPULAR_COURSE, status: LOADING});
        onLoading?.(true)
        try {
            let config = {
                headers: {} // Initialize headers object
            };

            if (localStorage.getItem("beauty_super_key")) {
                config.headers.Authorization = "Bearer " + localStorage.getItem("beauty_super_key");
            }
            let response = await Axios.patch(`${BASE_URL}courses/updatePopularCourse/${body.id}`, body,config);
            onSuccess?.(response?.data)
            dispatch({
                type: UPDATE_SUPER_POPULAR_COURSE,
                status: SUCCESS,
                payload: response?.data,
            });
        } catch (error) {
            onError?.(error?.response?.data)
            onResponseError(error?.response?.data,"super_admin")
            dispatch({
                type: UPDATE_SUPER_POPULAR_COURSE,
                status: ERROR,
                payload: error?.response?.data,
            });
        }
    };
}