import Axios from "axios";
import {BASE_URL} from "../../../config/config";
import {ERROR, LOADING, SUCCESS} from "../../../constants/generalConstant";
import {CUSTOMER_SIGN_IN, CUSTOMER_SIGN_UP, FORGOT_PASSWORD, RESET_PASSWORD, TRAINER_SIGN_UP, UPDATE_PASSWORD, VERIFY_EMAIL} from "../commonActions/types";

export function customerSignUp(body, actionResponse = {}) {
    const {onSuccess, onError, onLoading} = actionResponse;
    return async (dispatch) => {
        dispatch({type: CUSTOMER_SIGN_UP, status: LOADING});
        onLoading?.(true)
        try {

            let response = await Axios.post(`${BASE_URL}users/createBuyerUser`, body);
            onSuccess?.(response?.data)
            dispatch({
                type: CUSTOMER_SIGN_UP,
                status: SUCCESS,
                payload: response?.data,
            });
        } catch (error) {
            onError?.(error?.response?.data)
            dispatch({
                type: CUSTOMER_SIGN_UP,
                status: ERROR,
                payload: error?.response?.data,
            });
        }
    };
}

export function LogIn(body, actionResponse = {}) {
    const {onSuccess, onError, onLoading} = actionResponse;
    return async (dispatch) => {
        dispatch({type: CUSTOMER_SIGN_IN, status: LOADING});
        onLoading?.(true)
        try {

            let response = await Axios.post(`${BASE_URL}users/login`, body);
            onSuccess?.(response?.data)
            dispatch({
                type: CUSTOMER_SIGN_IN,
                status: SUCCESS,
                payload: response?.data,
            });
        } catch (error) {
            onError?.(error?.response?.data)
            dispatch({
                type: CUSTOMER_SIGN_IN,
                status: ERROR,
                payload: error?.response?.data,
            });
        }
    };
}

export function trainerSignUp(body, actionResponse = {}) {
    const {onSuccess, onError, onLoading} = actionResponse;
    return async (dispatch) => {
        dispatch({type: TRAINER_SIGN_UP, status: LOADING});
        onLoading?.(true)
        try {
            let response = await Axios.post(`${BASE_URL}users/createSellerUser`, body);
            onSuccess?.(response?.data)
            dispatch({
                type: TRAINER_SIGN_UP,
                status: SUCCESS,
                payload: response?.data,
            });
        } catch (error) {
            onError?.(error?.response?.data)
            dispatch({
                type: TRAINER_SIGN_UP,
                status: ERROR,
                payload: error?.response?.data,
            });
        }
    };
}

export function userForgotPasswordAction(body, actionResponse = {}) {
    const {onSuccess, onError, onLoading} = actionResponse;
    return async (dispatch) => {
        dispatch({type: FORGOT_PASSWORD, status: LOADING});
        onLoading?.(true)
        try {

            let response = await Axios.post(`${BASE_URL}users/forgetPassword`, body);
            onSuccess?.(response?.data)
            dispatch({
                type: FORGOT_PASSWORD,
                status: SUCCESS,
                payload: response?.data,
            });
        } catch (error) {
            onError?.(error?.response?.data)
            dispatch({
                type: FORGOT_PASSWORD,
                status: ERROR,
                payload: error?.response?.data,
            });
        }
    };
}


export function userResetPasswordAction(body, actionResponse = {}) {
    const {onSuccess, onError, onLoading} = actionResponse;
    return async (dispatch) => {
        dispatch({type: RESET_PASSWORD, status: LOADING});
        onLoading?.(true)
        try {
            let config = {
                headers: {} // Initialize headers object
            };

            if (localStorage.getItem("beauty_key")) {
                config.headers.Authorization = "Bearer " + localStorage.getItem("beauty_key");
            }
            let response = await Axios.patch(`${BASE_URL}users/resetPassword`, body,config);
            onSuccess?.(response?.data)
            dispatch({
                type: RESET_PASSWORD,
                status: SUCCESS,
                payload: response?.data,
            });
        } catch (error) {
            onError?.(error?.response?.data)
            dispatch({
                type: RESET_PASSWORD,
                status: ERROR,
                payload: error?.response?.data,
            });
        }
    };
}


export function userUpdatePasswordAction(body, actionResponse = {}) {
    const {onSuccess, onError, onLoading} = actionResponse;
    return async (dispatch) => {
        dispatch({type: UPDATE_PASSWORD, status: LOADING});
        onLoading?.(true)
        try {
            let config = {
                headers: {} // Initialize headers object
            };

            if (localStorage.getItem("beauty_key")) {
                config.headers.Authorization = "Bearer " + localStorage.getItem("beauty_key");
            }
            let response = await Axios.patch(`${BASE_URL}users/changePassword`, body,config);
            onSuccess?.(response?.data)
            dispatch({
                type: UPDATE_PASSWORD,
                status: SUCCESS,
                payload: response?.data,
            });
        } catch (error) {
            onError?.(error?.response?.data)
            dispatch({
                type: UPDATE_PASSWORD,
                status: ERROR,
                payload: error?.response?.data,
            });
        }
    };
}


export function userVerifyEmailAction(body, actionResponse = {}) {
    const {onSuccess, onError, onLoading} = actionResponse;
    return async (dispatch) => {
        dispatch({type: VERIFY_EMAIL, status: LOADING});
        onLoading?.(true)
        try {
            let config = {
                headers: {} // Initialize headers object
            };

            if (localStorage.getItem("beauty_key")) {
                config.headers.Authorization = "Bearer " + localStorage.getItem("beauty_key");
            }
            let response = await Axios.patch(`${BASE_URL}users/verifyEmail`, body,config);
            onSuccess?.(response?.data)
            dispatch({
                type: VERIFY_EMAIL,
                status: SUCCESS,
                payload: response?.data,
            });
        } catch (error) {
            onError?.(error?.response?.data)
            dispatch({
                type: VERIFY_EMAIL,
                status: ERROR,
                payload: error?.response?.data,
            });
        }
    };
}