import React from "react";
import {useLocation, useNavigate} from "react-router-dom";

const sidebarItems = [
    {
        id: 1,
        key: "dashboard",
        iconClass: "text-20 icon-discovery",
        text: "Dashboard",
    },
    {
        id: 2,
        key: 'categories',
        iconClass: "text-20 icon-play-button",
        text: "Categories",
    },
    {
        id: 3,
        key: 'sub-categories',
        iconClass: "text-20 icon-play-button",
        text: "Sub Categories",
    },
    {
        id: 4,
        key: 'popular-courses',
        iconClass: "text-20 icon-play-button",
        text: "Popular Courses",
    },
    {
        id: 5,
        key: 'testimonials',
        iconClass: "text-20 icon-play-button",
        text: "Testimonials",
    },
    {
        id: 6,
        key: 'students',
        iconClass: "text-20 icon-play-button",
        text: "Students",
    },
    {
        id: 7,
        key: 'instructors',
        iconClass: "text-20 icon-play-button",
        text: "Educators",
    },
    {
        id: 8,
        key: 'payments',
        iconClass: "text-20 icon-play-button",
        text: "Payments",
    },
    {
        id: 9,
        key: 'contact-us-list',
        iconClass: "text-20 icon-play-button",
        text: "Contact Us List",
    },
    {
        id: 10,
        key: 'newsletter',
        iconClass: "text-20 icon-play-button",
        text: "Newsletter",
    },
    {
        id: 11,
        key: 'course-language',
        iconClass: "text-20 icon-play-button",
        text: "Course Language",
    },
    {
        id: 19,
        iconClass: "text-20 icon-power",
        key: "logout",
        text: "Logout",
    },
];

export default function SuperSidebar() {
    const {pathname} = useLocation();
    const navigate = useNavigate();

    let pathSegments = pathname.split("/");
    let lastPathValue = pathSegments[pathSegments.length - 1];

    const handleSuperSideBarClicked = (key) => {
        const routes = {
            "dashboard": "dashboard",
            "categories": "categories",
            "sub-categories": "sub-categories",
            "instructors": "instructors",
            "testimonials": "testimonials",
            "popular-courses": "popular-courses",
            "students": "students",
            "payments": "payments",
            "newsletter": "newsletter",
            "course-language": "course-language",
            "contact-us-list": "contact-us-list"
        };
    
        if (key === "logout") {
            localStorage.removeItem('beauty_super_key');
            window.location.replace('/super_admin/login');
            return;
        }
    
        if (routes[key] !== undefined) {
            navigate(routes[key]);
        }
    };

    return (
        <div className="sidebar -dashboard">
            {sidebarItems.map((elm, i) => (
                <div
                    key={i}
                    className={`sidebar__item   ${
                        lastPathValue === elm.key ? "-is-active" : ""
                    } `}
                    onClick={() => handleSuperSideBarClicked(elm.key)}
                    style={{cursor: 'pointer'}}
                >
                    <i className={`${elm.iconClass} mr-15`}></i>
                    {elm.text}
                </div>
            ))}
        </div>
    );
}
