import {ADD_SUPER_CATEGORIES, GET_SUPER_CATEGORIES, REMOVE_SUPER_CATEGORIES, UPDATE_SUPER_CATEGORIES} from "../commonActions/types";
import {ERROR, LOADING, SUCCESS} from "../../../constants/generalConstant";
import Axios from "axios";
import {BASE_URL} from "../../../config/config";
import { onResponseError } from "../../../function/Interceptor";

export function AddSuperCategoryAction(categoryBody, actionResponse = {}) {
    const {onSuccess, onError, onLoading} = actionResponse;
    return async (dispatch) => {
        dispatch({type: ADD_SUPER_CATEGORIES, status: LOADING});
        onLoading?.(true)
        try {
            let config = {
                headers: {} // Initialize headers object
            };

            if (localStorage.getItem("beauty_super_key")) {
                config.headers.Authorization = "Bearer " + localStorage.getItem("beauty_super_key");
            }
            let body = {
                ...categoryBody
            }
            let response = await Axios.post(`${BASE_URL}common/addCourseCategory`, body, config);
            onSuccess?.(response?.data)
            dispatch({
                type: ADD_SUPER_CATEGORIES,
                status: SUCCESS,
                payload: response?.data,
            });
        } catch (error) {
            onError?.(error?.response?.data)
            onResponseError(error?.response?.data,"super_admin")
            dispatch({
                type: ADD_SUPER_CATEGORIES,
                status: ERROR,
                payload: error?.response?.data,
            });
        }
    };
}

export function getSuperCategories(actionResponse = {}) {
    const {onSuccess, onError, onLoading} = actionResponse;
    return async (dispatch) => {
        dispatch({type: GET_SUPER_CATEGORIES, status: LOADING});
        onLoading?.(true)
        try {
            let params = {
                pagination: false,
                fields: 'id,name,value,category_icon,sub_category,sub_category__id,sub_category__name,sub_category__value,available_course_count'
            };
            let Config = {
                headers: {},
                params,
            }
            if (localStorage.getItem("beauty_super_key")) {
                Config.headers.Authorization = "Bearer " + localStorage.getItem("beauty_super_key");
            }
            let response = await Axios.get(`${BASE_URL}common/getCourseCategoryList`, Config);
            const modifiedData = {
                ...response.data,
                data: response?.data?.data.map((item, index) => ({
                    ...item,
                    index
                }))
            }
            onSuccess?.(modifiedData)
            dispatch({
                type: GET_SUPER_CATEGORIES,
                status: SUCCESS,
                payload: modifiedData,
            });
        } catch (error) {
            onError?.(error)
            onResponseError(error?.response?.data,"super_admin")
            dispatch({
                type: GET_SUPER_CATEGORIES,
                status: ERROR,
                payload: error,
            });
        }
    };
}

export function deleteSuperCourseCategoryAction(superCategoryId,actionResponse = {}) {
    const {onSuccess, onError, onLoading} = actionResponse;
    return async (dispatch) => {
        dispatch({type: REMOVE_SUPER_CATEGORIES, status: LOADING});
        onLoading?.(true)
        try {

            let Config = {}
            if (localStorage.getItem("beauty_super_key")) {
                Config.headers = {Authorization: "Bearer " + localStorage.getItem("beauty_super_key")}
            }
            let response = await Axios.delete(`${BASE_URL}common/deleteCourseCategory/${superCategoryId}`, Config);
            onSuccess?.(response?.data)
            dispatch({
                type: REMOVE_SUPER_CATEGORIES,
                status: SUCCESS,
                payload: response?.data,
            });
        } catch (error) {
            onError?.(error?.response?.data)
            onResponseError(error?.response?.data,"super_admin")
            dispatch({
                type: REMOVE_SUPER_CATEGORIES,
                status: ERROR,
                payload: error?.response?.data,
            });
        }
    };
}

export function updateSuperCourseCategoryAction(body, actionResponse = {}) {
    const {onSuccess, onError, onLoading} = actionResponse;
    return async (dispatch) => {
        dispatch({type: UPDATE_SUPER_CATEGORIES, status: LOADING});
        onLoading?.(true)
        try {
            let config = {
                headers: {} // Initialize headers object
            };

            if (localStorage.getItem("beauty_super_key")) {
                config.headers.Authorization = "Bearer " + localStorage.getItem("beauty_super_key");
            }
            let response = await Axios.patch(`${BASE_URL}common/updateCourseCategory/${body.id}`, body,config);
            onSuccess?.(response?.data)
            dispatch({
                type: UPDATE_SUPER_CATEGORIES,
                status: SUCCESS,
                payload: response?.data,
            });
        } catch (error) {
            onError?.(error?.response?.data)
            onResponseError(error?.response?.data,"super_admin")
            dispatch({
                type: UPDATE_SUPER_CATEGORIES,
                status: ERROR,
                payload: error?.response?.data,
            });
        }
    };
}