export const customerProfileDropdownItems = [
    {
        id: 1,
        href: "/my-dashboard",
        key: 'my-dashboard',
        disabled: false,
        iconClass: "text-20 icon-discovery",
        text: "Dashboard",
    },
    {
        id: 8,
        href: "/",
        key: 'logout',
        disabled: false,
        iconClass: "text-20 icon-power",
        text: "Logout",
    },
];