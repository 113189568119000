// --------LIBRARIES--------
import { combineReducers } from "redux";
import categoriesReducer from "./categories";
import topCategoriesReducer from "./topCategories";
import customerCoursesReducer from "./customerCourses";
import singleCustomerCourses from "./singleCustomerCourses";
import customerCoursesFiltersReducer from "./customerCoursesFilters";
import enrolledMyCoursesReducer from "./enrolledMyCourses";
import popularCoursesReducer from "./popularCourses";
import singleEnrolledCourse from "./singleEnrolledCourse";
import bookmarkCoursesReducer from "./bookmarkCourses";

export default combineReducers({
    categories: categoriesReducer,
    topCategories: topCategoriesReducer,
    popularCourses: popularCoursesReducer,
    customerCourses: customerCoursesReducer,
    singleCustomerCourse: singleCustomerCourses,
    customerCoursesFilters : customerCoursesFiltersReducer,
    enrolledMyCourses: enrolledMyCoursesReducer,
    singleEnrolledCourseDetail : singleEnrolledCourse,
    bookmarkCourses: bookmarkCoursesReducer
});