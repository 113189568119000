import React, {useCallback, useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid } from "@mui/x-data-grid";
import { Avatar, ListItem, ListItemAvatar, ListItemText, Typography } from "@mui/material";
import { getSuperPaymentsData } from './../../../../redux/action/superAdminActions/superPaymentActions';

export default function SuperPayments() {

    const dispatch = useDispatch()

    const fetchPayments = useCallback(() => {
		dispatch(getSuperPaymentsData());
	}, [dispatch]);

	useEffect(() => {
		fetchPayments();
	}, [fetchPayments]);

    useEffect(() => {
        if (!localStorage.getItem('beauty_super_key')) {
            window.location.replace('login')
        }
    }, [])

    const {
		data,
		isLoading,
        meta_data
	} = useSelector((state) => state.superAdmin.superPayments);

    const Payments = [];

    const handlePageChange =()=>{
        console.log("page changed")
    }

    const columns = [
		{
			field: "student",
			width: 200,
			sortable: false,
            flex:1,
			renderHeader: () => <Typography variant="h6">{"Student"}</Typography>,
			renderCell: (params) => {
				return (
                    <ListItem style={{ paddingLeft: "0px" }}>
                    <ListItemAvatar>
                        <Avatar
                            alt={String(params.row.index + 1)}
                            src={params.row.user_profile_image}
                            sx={{ height: 60, width: 60, marginRight: "10px" }}
                        />
                    </ListItemAvatar>
                    <ListItemText
                        primary={<b>{params?.row?.user_first_name+" "+params?.row?.user_last_name ?? "--"}</b>}
                        secondary={params.row.email_verified ? <span style={{color:'green'}}>Verified</span> : <span style={{color:'red'}}>Not Verified</span>}
                    />
                </ListItem>
				);
			},
		},
		{
			field: "email",
			width: 100,
			sortable: false,
			flex: 1,
			renderHeader: () => <Typography variant="h6">{"Email"}</Typography>,
			renderCell: (params) => {
				return (
					<div>
						<b>{params?.row?.user_email ?? '--'}</b>
					</div>
				);
			},
		},
        {
			field: "registration_date",
			width: 100,
			sortable: false,
			flex: 1,
			renderHeader: () => <Typography variant="h6">{"Registration Date"}</Typography>,
			renderCell: (params) => {
				return (
					<div>
						<b>{params?.row?.date_joined ?? '--'}</b>
					</div>
				);
			},
		},
        {
			field: "course_take",
			width: 50,
			sortable: false,
			flex: 1,
			renderHeader: () => <Typography variant="h6">{"Course taken"}</Typography>,
            renderCell: (params) => {
				return (
					<div>
						{params?.row?.course_taken?? 0}
					</div>
				);
			},
		},
		// {
		// 	field: "actions",
		// 	renderHeader: () => <Typography variant="h6">{"Actions"}</Typography>,
		// 	sortable: false,
		// 	renderCell: (params) => {
		// 		return (
		// 			<div className="d-flex justify-content-end">
		// 				<div
		// 					className="icon-edit"
		// 					style={{ cursor: "pointer" }}
		// 					onClick={() => {
		// 						setSelectedCategory(params.row)
		// 						setOpenEditForm(true)
		// 					}}></div>
		// 				&emsp;
		// 				<Popconfirm
		// 					title="Are you sure to delete this category?"
		// 					description={
		// 						<div>
		// 							<p>All the related details will be deleted.</p>
		// 							<span>Sub Categories:</span>&nbsp;<b>{params?.row?.sub_category?.length ?? 0}</b><br/>
		// 							<span>Courses:</span>&nbsp;<b>{params?.row?.available_course_count ?? 0}</b>
		// 						</div>
		// 					}
		// 					okText="Yes"
		// 					cancelText="No"
		// 					onConfirm={() => handleDelete(params.row.id)}>
		// 					<div className="icon-bin" style={{ cursor: "pointer" }}></div>
		// 				</Popconfirm>
		// 			</div>
		// 		);
		// 	},
		// },
	];

    return (
		<div className="dashboard__main">
			<div className="dashboard__content bg-light-4">
				<div className="row pb-30">
					<div className="col-auto">
						<h1 className="text-20 lh-12 fw-500">Payments</h1>
						<div className="mt-1">Welcome to the Payments Section.</div>
					</div>
					<div className="col-auto ms-auto">
						{/* <Button
                            className="button py-10 px-10 -dark-1 text-white -dark-button-white col-12"
							startIcon={<Add />}
							onClick={() => setOpenAddForm(true)}>
							Add Category
						</Button> */}
					</div>
				</div>

				{/*Table Starts here */}
				<div
					style={{
						width: "100%",
						overflowY: "scroll",
						height: "calc(100vh - 260px)",
					}}>
					<DataGrid
						rows={Payments}
						columns={columns}
                        rowHeight={100}
						initialState={{
						    pagination: {
						        paginationModel: {page: meta_data.current, pageSize: 100},
						    },
						}}
                        onPaginationModelChange={()=>handlePageChange()}
						// pageSizeOptions={[5, 10]}
                        showCellVerticalBorder
						disableColumnMenu
						disableRowSelectionOnClick={true}
						disableColumnSelector
						disableFocus
						loading={isLoading}
					/>
				</div>
			</div>
			{/*<FooterNine />*/}
		</div>
	);
}
