/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import { Avatar } from "@mui/material";
import { useDispatch } from "react-redux";
import { getCourseFromLocalCartAction } from "../../../redux/action/cartActions/cartActions";
import CartToggle from "../../../components/common/CartToggle";
import { sideBarToggleAction } from './../../../redux/action/myDashboardActions/dashboardActions';
import logo1 from '../../../images/logos/logo_icon.svg'
import logo2 from '../../../images/logos/logo_full.svg'

const sidebarItems = [
    {
        id: 1,
        href: "/dashboard",
        key: 'dashboard',
        disabled: false,
        iconClass: "text-20 icon-discovery",
        text: "Dashboard",
    },
    {
        id: 8,
        href: "/",
        key: 'logout',
        disabled: false,
        iconClass: "text-20 icon-power",
        text: "Logout",
    },
];

export default function LoggedInHeader() {
    const dispatch = useDispatch()
    
    let loggedInUser = localStorage.getItem('loggedIn_user') ?? '{}'
	let parsedLoggedInData = JSON.parse(loggedInUser)

    // const [isOnNotification, setIsOnNotification] = useState(false);
    const [isOnProfile, setIsOnProfile] = useState(false);
    const [profileImage, setProfileImage] = useState(parsedLoggedInData?.profile_image ?? '')

    useEffect(() => {
        if (window.innerWidth < 990) {
            document
                .getElementById("dashboardOpenClose")
                .classList.add("-is-sidebar-hidden");
        }
        const handleResize = () => {
            if (window.innerWidth < 990) {
                document
                    .getElementById("dashboardOpenClose")
                    .classList.add("-is-sidebar-hidden");
            }
        };

        // Add event listener to window resize event
        window.addEventListener("resize", handleResize);

        // Clean up the event listener when component unmounts
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const handleSideItemsClicked = (key) => {
        switch (key) {
            case "logout": {
                localStorage.removeItem('beauty_key');
                localStorage.removeItem('beauty_cart_items');
                localStorage.removeItem('beauty_seller');
				localStorage.removeItem('beauty_buyer');
				localStorage.removeItem('loggedIn_user');
                dispatch(getCourseFromLocalCartAction())
                window.location.replace('/')
                break;
            }
            default :
                return
        }
    }

    window.addEventListener('storage', function() {
        loggedInUser = localStorage.getItem('loggedIn_user') ?? '{}'
	    parsedLoggedInData = JSON.parse(loggedInUser)
        setProfileImage(parsedLoggedInData?.profile_image ?? '')
      });

    return (
        <>
            <header className="header -dashboard -dark-bg-dark-1 js-header">
                <div className="header__container py-20 px-30">
                    <div className="row justify-between items-center">
                        <div className="col-auto">
                            <div className="d-flex items-center">
                                <div className="header__explore text-dark-1">
                                    <button
                                        onClick={() => {
                                            const sidebarElement = document.getElementById("dashboardOpenClose");
                                            const isSidebarHidden = sidebarElement.classList.contains("-is-sidebar-hidden");
                                            dispatch(sideBarToggleAction(isSidebarHidden))
                                            document
                                                .getElementById("dashboardOpenClose")
                                                .classList.toggle("-is-sidebar-hidden");
                                        }}
                                        className="d-flex items-center js-dashboard-home-9-sidebar-toggle"
                                    >
                                        <i className="icon -dark-text-white icon-explore"></i>
                                    </button>
                                </div>

                                <div className="header__logo ml-30 md:ml-20">
                                    <Link data-barba to="/">
                                        <img
                                            className="-light-d-none"
                                            src={logo1}
                                            alt="logo-1"
                                        />
                                        <img
                                            className="-dark-d-none"
                                            src={logo2}
                                            alt="logo-2"
                                        />
                                    </Link>
                                </div>
                            </div>
                        </div>

                        <div className="col-auto">
                            <div className="d-flex items-center">
                                <div className="text-white d-flex items-center lg:d-none mr-15">
                                    {/*<MyCourses />*/}
                                </div>

                                <div className="d-flex items-center sm:d-none">

                                    {/*<div*/}
                                    {/*    className="relative"*/}
                                    {/*    onClick={() => setMessageOpen(true)}*/}
                                    {/*>*/}
                                    {/*    <a*/}
                                    {/*        href="#"*/}
                                    {/*        className="d-flex items-center text-light-1 justify-center size-50 rounded-16 -hover-dshb-header-light"*/}
                                    {/*        data-el-toggle=".js-msg-toggle"*/}
                                    {/*    >*/}
                                    {/*        <i className="text-24 icon icon-email"></i>*/}
                                    {/*    </a>*/}
                                    {/*</div>*/}

                                    {/* <div
                                        className="relative"
                                        onClick={() => setIsOnNotification((pre) => !pre)}
                                    >
                                        <a
                                            href="#"
                                            className="d-flex items-center text-light-1 justify-center size-50 rounded-16 -hover-dshb-header-light"
                                            data-el-toggle=".js-notif-toggle"
                                        >
                                            <i className="text-24 icon icon-notification"></i>
                                        </a>

                                        <div
                                            className={`toggle-element js-notif-toggle  ${
                                                isOnNotification ? "-is-el-visible" : ""
                                            } -`}
                                        >
                                            <div
                                                className="toggle-bottom -notifications bg-white shadow-4 border-light rounded-8 mt-10">
                                                <div className="py-30 px-30">
                                                    <div className="y-gap-40">
                                                        Working on it...
                                                        {notifications.map((elm, i) => (
                                                            <div
                                                                key={i}
                                                                className={`d-flex items-center  ${
                                                                    i !== 0
                                                                        ? "border-top-light -dark-border-top-light-5"
                                                                        : ""
                                                                } `}
                                                            >
                                                                <div className="shrink-0">
                                                                    <img src={elm.imageSrc} alt="image" />
                                                                </div>
                                                                <div className="ml-12">
                                                                    <h4 className="text-15 lh-1 fw-500 -dark-text-dark-1">
                                                                        {elm.heading}
                                                                    </h4>
                                                                    <div className="text-13 lh-1 mt-10">
                                                                        {elm.time} Hours Ago
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                    <CartToggle
										    parentClassess={"relative mr-30 xl:ml-20"}
										    allClasses={"d-flex items-center"}
										/>
                                </div>

                                <div
                                    className="relative d-flex items-center ml-10 sm:d-none"
                                    onClick={() => setIsOnProfile((pre) => !pre)}
                                >
                                    <a data-el-toggle=".js-profile-toggle">
                                        <Avatar
                                            // src={parsedLoggedInData?.profile_image ?? ''}
                                            src={profileImage}
                                            // alt="profile"
                                            alt={parsedLoggedInData?.first_name ?? "profile"}
                                            sx={{ width: 56, height: 56, cursor:'pointer' }}
                                        />
                                    </a>

                                    <div
                                        className={`toggle-element js-profile-toggle ${
                                            isOnProfile ? "-is-el-visible" : ""
                                        } -`}
                                    >
                                        <div
                                            className="toggle-bottom -profile bg-white shadow-4 border-light rounded-8 mt-10">
                                            <div className="px-30 py-30">
                                                <div className="sidebar -dashboard">
                                                    {sidebarItems.map((elm, i) => (
                                                        <div
                                                            key={i}
                                                            className={`sidebar__item ${
                                                                elm.id === 1 ? "-is-active -dark-bg-dark-2" : ""
                                                            }`}
                                                        >
                                                            <Link
                                                                to={''}
                                                                onClick={() => handleSideItemsClicked(elm.key)}
                                                                className="d-flex items-center text-17 lh-1 fw-500 "
                                                            >
                                                                <i className={elm.iconClass}></i>&nbsp;
                                                                {elm.text}
                                                            </Link>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*<Messages setMessageOpen={setMessageOpen} messageOpen={messageOpen} />*/}
            </header>
        </>
    );
}
