import React from "react";
import ModalVideo from "react-modal-video";
export default function ModalVideoComponent({
	isOpen,
	setIsOpen,
	url,
	videoId,
}) {
	return (
		<ModalVideo
			channel="custom"
			isOpen={isOpen}
			videoId={videoId}
			url={url}
			onClose={() => setIsOpen(false)}
		/>
	);
}
